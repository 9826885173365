import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  ListItemIcon,
  Tooltip,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import React, { useState } from 'react';
import appStore from '../../../../../../AppStore';
import { evaluationsService, examsService } from '../../../../../services';

function DeleteEvaluationButton({ title, id, isExam = false }: DeleteEvaluationProps) {
  const [open, setOpen] = useState(false);

  const handleCallback = async () => {
    appStore.beginLoading();
    try {
      if (isExam) {
        await examsService.delete(id);
      } else {
        await evaluationsService.deleteEvaluation(id);
      }
    } catch (err) {
      appStore.error(err);
    }
    appStore.endLoading();
    setOpen(false);
  };

  return (
    <>
      <ListItemIcon onClick={() => setOpen(true)}>
        <Tooltip title="Delete Evaluation">
          <IconButton aria-label="delete" size="large">
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </ListItemIcon>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to permanently delete this {isExam ? 'exam' : 'evaluation'}?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{title}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleCallback()} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

interface DeleteEvaluationProps {
  isExam?: boolean;
  id: string;
  title: string;
}

export default React.memo(DeleteEvaluationButton);
