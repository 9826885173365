import { useEffect, useState } from 'react';
import { Chip, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FrameworkItemWithScores } from '../../../../services/interfaces';
import { useAssessmentContext } from '../../components/AssessmentContext';
import CapabilityMaturityTable from './components/table/table';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    paddingLeft: 1,
    paddingRight: 1,
    flexDirection: 'row',
    height: 'calc(100vh - 534px)',
    width: '100%',
    overflow: 'scroll',
  },
}));

export const CAPABILITY_MATURITY_TABS = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'Target',
    value: 'target',
  },
  {
    label: 'Advanced',
    value: 'advanced',
  },
];

interface CapabilityMaturityProps {
  onScroll: (e: any) => void;
  onClick: (control: FrameworkItemWithScores, columnName?: string) => void;
}

export default function CapabilityMaturity({ onScroll, onClick }: CapabilityMaturityProps) {
  const [currentItem, setCurrentItem] = useState<FrameworkItemWithScores | null>(null);
  const { assessment, assessmentFrameworkId, currentOverallMaturityLevel, setCurrentOverallMaturityLevel } =
    useAssessmentContext();

  const classes = useStyles();

  const currentItemId = currentItem?.identifier;

  useEffect(() => {
    if (currentItemId) {
      const found = assessment.frameworks[0].groups
        .flatMap(g => g.items)
        .find(item => item.identifier === currentItemId);
      if (found) {
        setCurrentItem(found as FrameworkItemWithScores);
      }
    }
  }, [assessment, currentItemId]);

  const handleSetCurrentItem = (currentItem: FrameworkItemWithScores) => {
    setCurrentItem(currentItem);
    onClick(currentItem);
  };

  // uses the first one. Could cause issues if more than 1 framework per assessment
  const assessmentFramework = assessment.frameworks.find(fw => fw.id === assessmentFrameworkId)!;

  // show entire framework if no item is selected
  return (
    <>
      <div style={{ float: 'right', padding: 12 }}>
        <Grid container gap={2}>
          <Grid item alignContent="center">
            <Typography variant="textStyle1">Overall Maturity Level:</Typography>
          </Grid>
          {CAPABILITY_MATURITY_TABS.map(tab => {
            return (
              <Grid item key={`capability-maturity-tab-${tab.value}`}>
                <Chip
                  variant={currentOverallMaturityLevel === tab.value ? 'filled' : 'outlined'}
                  label={tab.label}
                  onClick={() => setCurrentOverallMaturityLevel(tab.value)}
                />
              </Grid>
            );
          })}
        </Grid>
      </div>
      <div className={classes.wrapper} onScroll={onScroll}>
        <CapabilityMaturityTable framework={assessmentFramework} setCurrentItem={handleSetCurrentItem} />
      </div>
    </>
  );
}
