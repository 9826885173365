import { Box, IconButton } from '@mui/material';
import { ConfigValidationItem } from '../../interfaces';
import { makeStyles } from '@mui/styles';
import Cell from './Cell';
import EastRoundedIcon from '@mui/icons-material/EastRounded';

const useStyles = makeStyles(() => ({
  column: {
    display: 'grid',
    gridTemplateColumns: '1fr 24px',
    gridTemplateRows: '6rem',
    marginBottom: '0.0625rem',
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 0.25rem',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '& svg': {
      width: '1rem',
    },
  },
}));

interface ColumnProps {
  item: ConfigValidationItem;
  onClick: (control: ConfigValidationItem, columnName?: string) => void;
}

export default function Column({ item, onClick }: ColumnProps) {
  const classes = useStyles();

  return (
    <Box className={classes.column}>
      <Cell item={item} onClick={onClick} />
      <IconButton className={classes.button} onClick={() => alert('In development')}>
        <EastRoundedIcon />
      </IconButton>
    </Box>
  );
}
