import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    gap: 24,
    justifyContent: 'flex-end',
    flexDirection: 'row',
    padding: 24,
  },
}));

interface ActionsProps {
  selectedTab: string;
  onIgnore: () => void;
  onResolve: () => void;
  onRestore: () => void;
  disabled: boolean;
}

export default function Actions({ selectedTab, onIgnore, onResolve, onRestore, disabled }: ActionsProps) {
  const classes = useStyles();
  return (
    <div className={classes.container} data-testid="recommendations-actions">
      {selectedTab === 'priority' && (
        <Button
          variant="outlined"
          startIcon={<FontAwesomeIcon icon={['fal', 'times-circle']} />}
          onClick={onIgnore}
          disabled={disabled}
        >
          Ignore
        </Button>
      )}
      {['priority', 'ignored'].includes(selectedTab) && (
        <Button
          variant="contained"
          color="primary"
          startIcon={<FontAwesomeIcon icon={['fal', 'check-circle']} />}
          onClick={onResolve}
          disabled={disabled}
        >
          Resolve
        </Button>
      )}
      {['ignored', 'resolved'].includes(selectedTab) && (
        <Button
          variant="contained"
          color="primary"
          startIcon={<FontAwesomeIcon icon={['fal', 'undo']} />}
          onClick={onRestore}
          disabled={disabled}
        >
          Restore
        </Button>
      )}
    </div>
  );
}
