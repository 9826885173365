import { Typography, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ConfigValidation, ConfigValidationItem } from '../../interfaces';
import Column from './Column';

const useStyles = makeStyles(theme => ({
  heading: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  title: {
    ...theme.typography.body1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '5.5625rem',
    textAlign: 'center',
    fontWeight: '600',
    textTransform: 'capitalize',
  },
  columns: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.darkBlue4.main,
    // TODO: Ugly solution, remake
    '& .MuiBox-root:first-child .MuiBox-root': {
      borderRadius: '0.1875rem 0.1875rem 0 0',
    },
  },
}));

interface HeadingProps {
  config: ConfigValidation;
  onClick: (control: ConfigValidationItem, columnName?: string) => void;
}

export default function Heading({ config, onClick }: HeadingProps) {
  const classes = useStyles();

  return (
    <Box className={classes.heading}>
      <Typography className={classes.title}>{config.name}</Typography>
      <Box className={classes.columns}>
        {config.items.map(item => (
          <Column key={item.id} item={item} onClick={onClick} />
        ))}
      </Box>
    </Box>
  );
}
