import { ReactNode, useState } from 'react';
import { useAbort } from '../../../../effects';
import { settingsService } from '../../../services';
import Wrapper from './Wrapper';

type UpdateFn<T> = (value: T) => void;

interface SettingWrapperProps<T> {
  children: (value: T, onUpdate: UpdateFn<T>) => ReactNode;
  title: string;
  setting: string;
  json?: boolean;
  defaultValue: T;
}

export default function SettingWrapper<T>({ title, setting, defaultValue, json, children }: SettingWrapperProps<T>) {
  const [value, setValue] = useState<T>(defaultValue);
  const [clean, setClean] = useState(true);

  useAbort(
    () => settingsService.find(setting, '{ name value }', 'network-only'),
    result => {
      if (!result) {
        return;
      }

      setValue(json ? JSON.parse(result.value) : result.value);
    },
    [setting, json],
  );

  const handleOnSave = async () => {
    await settingsService.save({ name: setting, value: json ? JSON.stringify(value) : (value as string) });
    setClean(true);
  };

  const handleOnReset = async () => {
    setValue(defaultValue);
    setClean(false);
  };

  const handleOnUpdate = (updated: T) => {
    setValue(updated);
    setClean(false);
  };

  return (
    <Wrapper title={title} onSave={handleOnSave} onReset={handleOnReset} clean={clean}>
      {children(value, handleOnUpdate)}
    </Wrapper>
  );
}
