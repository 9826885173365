import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface PassFailProps {
  pass?: boolean | null;
}

export default function PassFail({ pass = null }: PassFailProps) {
  if (pass === null) {
    return <FontAwesomeIcon icon={['fal', 'question-circle']} color="DodgerBlue" size="lg" />;
  }

  return (
    <FontAwesomeIcon
      icon={['fal', pass ? 'check-circle' : 'times-circle']}
      color={pass ? '#00ffb1' : 'coral'}
      size="lg"
    />
  );
}
