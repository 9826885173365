import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppBar, Tab, Tabs, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { PageTab } from './interfaces';

interface FrameworkTabsProps {
  tabs: PageTab[];
  activeTab: PageTab;
  onTabClick: (tab: PageTab) => void;
}

interface LabelProps {
  tab: PageTab;
  activeTab: PageTab;
  tooltipClass: string;
}

const TOOLTIP_TEXT =
  'An orange border indicates not all assessment questions have been answered and the overall score is not accurate.';

const useStyles = makeStyles({
  bar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#161926',
  },
  tooltipContent: {
    display: 'flex',
    gap: 4,
    alignItems: 'center',
  },
});

/**
 * Displays the tab text or adds an info icon and tooltip
 * when the tab is the capability maturity tab and it is active
 */
function Label({ tab, activeTab, tooltipClass }: LabelProps) {
  if (tab === PageTab.CAPABILITY_MATURITY && activeTab === PageTab.CAPABILITY_MATURITY) {
    return (
      <Tooltip title={TOOLTIP_TEXT} placement="bottom">
        <div className={tooltipClass}>
          {tab} <FontAwesomeIcon icon={['fas', 'info-circle']} />
        </div>
      </Tooltip>
    );
  }

  return <>{tab}</>;
}

/**
 * Displays the tab bar for the framework score page
 */
export default function FrameworkTabs({ tabs, activeTab, onTabClick }: FrameworkTabsProps) {
  const classes = useStyles();

  return (
    <AppBar className={classes.bar} position="static" color="default">
      <Tabs
        value={tabs.indexOf(activeTab)}
        indicatorColor="secondary"
        textColor="primary"
        onChange={(e, i) => onTabClick(tabs[i])}
      >
        {tabs.map((tab, i) => (
          <Tab
            id={`tab-${i}`}
            key={tab}
            label={<Label tab={tab} activeTab={activeTab} tooltipClass={classes.tooltipContent} />}
          />
        ))}
      </Tabs>
    </AppBar>
  );
}
