import { useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { escape } from '@sightgain/core/strings';
import { useAbort } from '../../../../effects';
import CsvDownload from '../../../reusables/CsvDownload';
import RecommendationsService from '../../../services/RecommendationsService';
import { Recommendation, RecommendationSummary } from './interfaces';
import { summarize } from './summarize';
import Summary from './Summary';
import RecommendationTabs from './Tabs';
import { FilterProvider } from '../../../reusables/Filter';

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: 30,
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'column',
    },
  },
}));

const types = ['people', 'process', 'technology', 'threat'];

export default function Recommendations() {
  const classes = useStyles();
  const [selected, setSelected] = useState('all');
  const [recommendations, setRecommendations] = useState<Recommendation[] | undefined>(undefined);
  const [summaries, setSummaries] = useState<RecommendationSummary[]>([]);
  const [refresh, setRefresh] = useState(0);

  useAbort(
    () => RecommendationsService.list(),
    results => {
      const summarized = summarize(results, types);
      setSummaries(summarized);
      const sorted = results.sort((a, b) => {
        const diff = b.date.getTime() - a.date.getTime();
        if (diff === 0) {
          return b.id.localeCompare(a.id);
        }
        return diff;
      });
      setRecommendations(sorted);
    },
    [refresh],
  );

  const handleSelect = (type: string) => {
    setSelected(type);
  };

  const handleRefresh = () => {
    setRefresh(refresh + 1);
  };

  const datas = () => {
    return (
      recommendations?.map(r => ({
        type: r.type,
        riskLevel: r.riskLevel,
        title: escape(r.title),
        content: escape(r.content),
        detail: escape(r.detail),
        status: r.status,
        date: r.date,
      })) ?? []
    );
  };

  return (
    <div data-testid="recommendations-page">
      <div style={{ display: 'flex', gap: 12, alignItems: 'center' }}>
        <Typography variant="h2">Recommendations</Typography>
        <CsvDownload filename="Recommendations.csv" datas={datas ?? []} />
      </div>
      <Grid container className={classes.container} spacing={3}>
        <Grid item xs={12} lg={3} style={{ height: '85vh', minHeight: 400, overflowY: 'scroll' }}>
          <Box style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
            {summaries.map(s => (
              <Summary summary={s} key={s.type} selected={selected === s.type} onSelect={handleSelect} />
            ))}
          </Box>
        </Grid>
        <Grid item xs={12} lg={9} style={{ height: '85vh', minHeight: 400 }}>
          <FilterProvider>
            <RecommendationTabs type={selected} recommendations={recommendations} onRefresh={handleRefresh} />
          </FilterProvider>
        </Grid>
      </Grid>
    </div>
  );
}
