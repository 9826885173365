import axios, { AxiosError } from 'axios';
import { DocumentNode, OperationDefinitionNode } from 'graphql';
import { FetchPolicy } from '@apollo/client';
import appStore from '../../AppStore';
import keycloak from '../../keycloak';
import apolloClient from './apollo';

const { protocol, host } = window.location;
const baseUrl = `${protocol}//${host}/api`;

export default class ServiceBase {
  middleware: Array<{ event: string; func: (data: any) => void }> = [];

  refreshQueue: Array<() => Promise<void>> = [];

  addMiddleWare(event: string, func: (data: any) => undefined) {
    this.middleware.push({ event, func });
  }

  /**
   * Alias for call using GET method
   * @param {string} url URL to call
   */
  get(url: string) {
    return this.call({
      url,
    });
  }

  /**
   * Send GraphQL request
   * @param {DocumentNode} query
   * @param {any} variables
   */
  async graphql(
    query: DocumentNode,
    variables = {},
    fetchPolicy: FetchPolicy = 'cache-first',
    refetchQueries: any = [],
  ): Promise<any> {
    try {
      let resp;

      const cleaned = gqlVariablesCleanup(variables);

      if ((query.definitions[0] as OperationDefinitionNode).operation === 'query') {
        resp = await apolloClient.query({ query, variables: cleaned, fetchPolicy });
      } else {
        resp = await apolloClient.mutate({
          mutation: query,
          variables: cleaned,
          refetchQueries,
          awaitRefetchQueries: true,
        });
      }

      return this.applyMiddleware('after', resp.data);
    } catch (err) {
      if ((err as Error).message === '401') {
        return this.refresh(() => this.graphql(query, variables));
      }

      throw err;
    }
  }

  /**
   * Appends an item to the results of a query
   * TODO: apollo client v3 has updateQuery which would be better suited for this
   * @param query query that needs to be updated (usually a list or find)
   * @param variables variables used in query
   * @param typeName typename of result object
   * @param item new item to append
   */
  async appendGQLCache<T>(
    query: DocumentNode,
    variables: any,
    typeName: string,
    items: T | T[],
    sort?: (a: T, b: T) => number,
  ) {
    const existing = apolloClient.readQuery({ query, variables });

    // only update if the cache returns results
    if (!(existing && existing[typeName])) {
      return;
    }

    const updated = [...existing[typeName], ...([] as T[]).concat(items)];

    if (sort) {
      updated.sort(sort);
    }

    apolloClient.writeQuery({
      query,
      variables,
      data: {
        [typeName]: updated,
      },
    });
  }

  /***
   * @param query GQL query that the cache should be updated for (usually a list or find)
   * @param variables variables used in the query from the query parameter
   * @param typeName the type name of the object being removed
   * @param filterFn filter used to remove the object and return only the objects that should remain in cache
   */
  async removeGQLCache(query: DocumentNode, variables: any, typeName: string, filterFn: (v: any) => boolean) {
    const existing = apolloClient.readQuery({ query, variables });

    // only update if the cache returns results
    if (!(existing && existing[typeName])) {
      return;
    }

    apolloClient.writeQuery({
      query,
      variables,
      data: {
        [typeName]: existing[typeName].filter(filterFn),
      },
    });
  }

  /**
   * Adds a call to a queue to be retried after a refresh
   * @param retry process to retry after token refresh
   */
  refresh(retry: () => Promise<void>) {
    const { user } = appStore;

    // can't refresh if not logged in
    if (!user) {
      this.refreshFail();
      return;
    }

    return new Promise((resolve, reject) => {
      const callback = async () => {
        try {
          const result = await retry();
          resolve(result);
        } catch (err) {
          reject(err);
        }
      };

      this.refreshQueue.push(callback);

      // if there wasn't already a queue
      if (this.refreshQueue.length === 1) {
        // add the runQueue to the listeners and check the index
        if (
          appStore.beginRefresh(
            () => this.runQueue(),
            () => this.refreshFail(),
          ) === 1
        ) {
          // if first in refresh queue, call for new token
          setTimeout(() => this.refreshCall());
        }
      }
    });
  }

  /**
   * Retries all calls in the queue
   */
  runQueue() {
    const contents = this.refreshQueue.splice(0);
    return Promise.all(contents.map(callback => callback()));
  }

  /**
   * Refreshes the access token
   */
  async refreshCall() {
    try {
      await keycloak.updateToken(300);
      // TODO: maybe user object should be updated too?
      appStore.setTokens(keycloak.refreshToken ?? '', keycloak.token ?? '');
      appStore.endRefresh();
    } catch (err) {
      appStore.error(err);
      this.refreshFail();
      appStore.endRefresh(true);
    }
  }

  /**
   * Basically says we 401 back to login
   */
  refreshFail() {
    // empty the queue
    this.refreshQueue = [];
  }

  /**
   * Makes an HTTP call
   * @param {object} options
   */
  async call(options: any): Promise<any> {
    const { access, clientId } = appStore;

    const opts = this.applyMiddleware('before', options);

    const defaults: any = {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        'client-id': clientId,
      },
      method: 'GET',
    };

    if (access) {
      defaults.headers.authorization = `Bearer ${access}`;
    }

    // merge parameters
    const params = { ...defaults, ...opts };

    // fix the url
    params.url = this.parseUrl(opts.url);

    try {
      const resp = await axios(params);

      return this.applyMiddleware('after', resp.data);
    } catch (axiosError) {
      const err = axiosError as AxiosError;

      if (err.response) {
        if (err.response.status === 401) {
          return this.refresh(() => this.call(options));
        }

        if (err.response.data && err.response.data.error) {
          throw new Error(err.response.data.error);
        }
      }

      throw err;
    }
  }

  parseUrl(url: string) {
    // create the base url regex
    const bex = new RegExp(`^${baseUrl}/?`);

    // fix the url
    return `${baseUrl}/${url.replace(bex, '')}`;
  }

  applyMiddleware(event: string, data: any) {
    return this.middleware.filter(m => m.event === event).reduce((a, b) => b.func(a), data);
  }
}

function gqlVariablesCleanup(input: any): any {
  if (
    !input ||
    typeof input !== 'object' ||
    input instanceof File ||
    input instanceof FileList ||
    (input as any).getTime
  ) {
    return input;
  }

  if (Array.isArray(input)) {
    return input.map(i => gqlVariablesCleanup(i));
  }

  return Object.keys(input).reduce((a, b) => {
    if (b === '__typename') {
      return a;
    }

    return { ...a, [b]: gqlVariablesCleanup(input[b]) };
  }, {} as any);
}
