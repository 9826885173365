import { memo } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import appStore from '../../../AppStore';
import ErrorPage from '../../pages/ErrorPage';
import ModuleSelector from '../../pages/ModuleSelector';
import Profile from '../../pages/Profile';
import Settings from '../../pages/Settings/Settings';
import Signup from '../../pages/Signup';
import Support from '../../pages/Support';
import { Role } from '../../services/interfaces';
import modules from '../Navigation/modules';
import { defaultAccessWith } from '../Navigation/utils';
import DefaultPage from './DefaultPage';
import PrivateRoute from './PrivateRoute';

function Routing() {
  const routes = modules.flatMap(module =>
    module.pages
      // only show pages that the SIP can handle
      .filter(page => !page.exclude || !page.exclude?.includes(appStore.SIP))
      .flatMap(page => page.routes)
      // only add routes that the license allows
      .filter(route => !appStore.isLimitedLicense || route.limitedAccess),
  );

  routes.push({
    path: '/settings/:setting/:id?',
    key: 'settings',
    roles: [Role.ADMIN],
    component: Settings,
  });

  routes.push({
    path: '/select-module',
    key: 'moduleselector',
    roles: defaultAccessWith(Role.FRAMEWORK_ANALYST),
    component: ModuleSelector,
  });

  return (
    <Switch>
      <Route exact path="/signup/:token" component={Signup} />
      <PrivateRoute exact path="/profile" key="profile" roles={defaultAccessWith(Role.FRAMEWORK_ANALYST)}>
        <Profile />
      </PrivateRoute>
      ,
      <PrivateRoute exact path="/support" key="support" roles={defaultAccessWith(Role.FRAMEWORK_ANALYST)}>
        <Support />
      </PrivateRoute>
      {routes.map(({ key, component: Component, ...props }) => (
        <PrivateRoute {...props} exact key={key}>
          <Component />
        </PrivateRoute>
      ))}
      <PrivateRoute exact path="/">
        <DefaultPage />
      </PrivateRoute>
      <Route exact path="/settings" key="settings-redirect">
        <Redirect to="/settings/security-instrumentation" />
      </Route>
      <Route path="*" render={() => <ErrorPage title="Not Found" message="404: Sorry but that page is not found" />} />
    </Switch>
  );
}

export default withRouter(memo(Routing));
