import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import CloseIcon from '@mui/icons-material/Close';
import { observer } from 'mobx-react';
import { Snackbar } from '@mui/material';
import { AlertColor } from '@mui/material';

import appStore from '../../../AppStore';

export default observer(() => {
  // get the next message in the queue
  const msg = appStore.nextMessage;

  if (!msg) {
    return null;
  }

  // deconstruct the property
  const { key, message, severity } = msg;

  if (!message) {
    return null;
  }

  // return some snacks
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      open
      onClose={() => appStore.removeMessage(key)}
      key={key}
      autoHideDuration={3000}
      TransitionComponent={props => <Slide {...props} direction="up" />}
    >
      <Alert severity={severity as AlertColor}>
        {message}
        <IconButton size="small" aria-label="close" color="inherit" onClick={() => appStore.removeMessage(key)}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </Alert>
    </Snackbar>
  );
});
