import { Button, Grid } from '@mui/material';
import assessmentsService from '../../../../../services/AssessmentsService';
import { useAssessmentContext } from '../../../components/AssessmentContext';
import { Objective, Question } from '../interface';
import Divider from './Divider';
import YesNoQuestion from './YesNoQuestion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { AnswerInput } from '../../../../../services/interfaces';
import appStore from '../../../../../../AppStore';

interface ObjectiveQuestionsProps {
  objective: Objective;
  isReadonly?: boolean;
  isDirty: boolean;
  setIsDirty: (val: boolean) => void;
}

export default function ObjectiveQuestions({ objective, isReadonly, isDirty, setIsDirty }: ObjectiveQuestionsProps) {
  const { assessment } = useAssessmentContext();
  const [answers, setAnswers] = useState<AnswerInput[]>([]);

  async function saveAnswers() {
    appStore.beginLoading();
    try {
      await assessmentsService.addAnswerArtifact(assessment.id, objective.id, answers);
      setIsDirty(false);
      appStore.success('Answers saved');
    } catch (err) {
      appStore.error(err);
    }
    appStore.endLoading();
  }

  function saveAnswer(question: Question, value: string) {
    const updatedAnswers = answers.filter(x => x.questionId !== question.id);
    updatedAnswers.push({ questionId: question.id, value });
    setAnswers(updatedAnswers);
    if (!isDirty) {
      setIsDirty(true);
    }
  }

  if (objective.questions.length === 0) {
    return <Divider />;
  }

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'flex-end', flexShrink: 0 }}>
        <Button disabled={!isDirty} onClick={saveAnswers} startIcon={<FontAwesomeIcon icon={['fal', 'save']} />}>
          Save Answers
        </Button>
      </div>
      <Grid container direction="column" spacing={3} marginTop={1}>
        {objective.questions.map(question => {
          const answer = objective.answers.find(ans => ans.question.id === question.id);
          return (
            <YesNoQuestion
              key={question.id}
              question={question}
              save={saveAnswer}
              answer={answer}
              isReadonly={isReadonly}
            />
          );
        })}
      </Grid>
    </>
  );
}
