import { Chip, FormControlLabel, Switch, Typography } from '@mui/material';
import { useFilterContext, FilterButton } from '../../../../../reusables/Filter';
import { FilterSource } from '../../../../../reusables/Filter/interfaces';

export default function TileDetailControls({
  sources,
  itemCount,
  hideLatest = false,
}: {
  sources: FilterSource[];
  itemCount: number;
  hideLatest?: boolean;
}) {
  const { filters, setFilters, sortById, setSortById, latest, setLatest } = useFilterContext();

  const handleDelete = (idx: number) => {
    setFilters(filters.filter((x: any, y: number) => y !== idx));
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div>
        <Typography variant="h4" style={{ color: '#8797AB' }}>
          Filters
        </Typography>
        <div style={{ marginTop: 8, display: 'flex', gap: 12 }}>
          <FilterButton sources={sources} />
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: 12,
              height: 50,
              overflowY: 'auto',
            }}
          >
            {filters.map((f: any, idx: number) => (
              <Chip
                key={`${f.column}-${f.value}`}
                variant="outlined"
                style={{ borderRadius: 2, height: 40 }}
                label={
                  <div>
                    {f.title} {f.value}
                  </div>
                }
                onDelete={() => handleDelete(idx)}
              />
            ))}
          </div>
        </div>
      </div>
      <div>
        <FormControlLabel
          value="start"
          control={<Switch value={sortById} onChange={() => setSortById(!sortById)} color="primary" />}
          label="Sort by ID"
          labelPlacement="start"
        />
        {!hideLatest && (
          <FormControlLabel
            value="start"
            control={<Switch value={latest} onChange={() => setLatest(!latest)} color="primary" />}
            label="Latest"
            labelPlacement="start"
          />
        )}
        <Typography textAlign="right">{itemCount} Items</Typography>
      </div>
    </div>
  );
}
