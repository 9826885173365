import { round } from '@sightgain/core/calculations';
import { FrameworkItem, Job, LiveFireTraining, LiveFireTrainingStatus } from '../../../../services/interfaces';

export type LFT = Pick<
  LiveFireTraining,
  'id' | 'isReleased' | 'isExam' | 'status' | 'analyst' | 'createdAt' | 'instructorScore' | 'score'
>;

export type RRJob = Pick<
  Job,
  'id' | 'status' | 'createdAt' | 'evaluationId' | 'name' | 'isExcluded' | 'frameworkControls'
> & {
  trainings: LFT[];
};

export interface DataRow {
  id: string;
  evaluationId: string;
  type: 'lft' | 'job';
  jobId: string;
  name: string;
  status: LiveFireTrainingStatus;
  vendorId: string;
  createdAt: Date;
  analyst?: string;
  score?: number;
  isReleased?: boolean;
  isExam?: boolean;
  isExcluded?: boolean;
  instructorScore?: number;
  frameworkControls?: FrameworkItem[];
}

export function toRow(row: RRJob): DataRow | DataRow[] {
  const jobRow: DataRow = {
    id: row.id,
    jobId: row.id,
    type: 'job',
    evaluationId: row.evaluationId,
    name: row.name,
    status: row.status as LiveFireTrainingStatus,
    vendorId: row.evaluationId,
    createdAt: row.createdAt,
    isExcluded: row.isExcluded,
    frameworkControls: row.frameworkControls,
  };

  if (!row.trainings.length) {
    return jobRow;
  }

  return row.trainings.map(training => ({
    ...jobRow,
    id: training.id,
    type: 'lft',
    status: training.status,
    analyst: training.analyst.email,
    score: round(training.score * 100, 0),
    instructorScore: training.instructorScore,
    isReleased: training.isReleased,
    isExam: training.isExam,
  }));
}
