import { Box, Chip, IconButton, Stack, Typography } from '@mui/material';
import { FilterButton, useFilterContext } from '../../../../../reusables/Filter';
import { Filter } from '../../../../../reusables/Filter/interfaces';
import { makeStyles, useTheme } from '@mui/styles';
import { ConfigValidationItem, ConfigValidationItemStatus } from '../../configValidation/interfaces';
import { format } from 'date-fns';
import { useState } from 'react';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import PassedIcon from '../../../../../images/cloudServicesProviders/icons/passed.svg';
import NoDataIcon from '../../../../../images/cloudServicesProviders/icons/no-data.svg';
import FailedIcon from '../../../../../images/cloudServicesProviders/icons/failed.svg';

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.75rem',
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
  },
  heading: {
    display: 'grid',
    gridTemplateColumns: '7.9375rem 1fr',
  },
  date: {
    ...theme.typography.textStyle1,
    padding: '1.5rem',
    textAlign: 'center',
    color: theme.palette.blue3.main,
    backgroundColor: theme.palette.darkBlue3.main,
  },
  items: {
    padding: '1.5rem 2rem',
    backgroundColor: theme.palette.darkBlue2.main,
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1.5rem',
    alignItems: 'center',
  },
  content: {
    padding: '1.125rem 2rem 2.875rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '3.8125rem',
    backgroundColor: theme.palette.darkBlue2.main,
  },
  testResults: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
  },
  testResultsLabel: {
    fontSize: '0.75rem',
    lineHeight: '0.9375rem',
    color: theme.palette.grey2.main,
  },
  explanations: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  explanationsWrapper: {
    display: 'flex',
    gap: '1.875rem',
  },
  explanationsLabel: {
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
    color: theme.palette.grey2.main,
  },
  explanationsItems: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
  },
  explanationsItemsImage: {
    width: '1.25rem',
    height: '1.25rem',
  },
  explanationsItemsLabel: {
    fontSize: '0.75rem',
    lineHeight: '0.9375rem',
    color: theme.palette.grey2.main,
  },
  tests: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.25rem',
  },
  testsWrapper: {
    display: 'flex',
    gap: '3.75rem',
    padding: '0.625rem 1rem',
    backgroundColor: theme.palette.darkBlue3.main,
  },
  testsItems: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.75rem',
  },
  testsItemsLabel: {
    fontSize: '0.75rem',
    lineHeight: '1.25rem',
    color: theme.palette.common.white,
    textDecoration: `underline solid ${theme.palette.grey1.main}`,
  },
  testsItemsDescription: {
    fontSize: '0.75rem',
    lineHeight: '1.25rem',
    color: theme.palette.grey2.main,
    textDecoration: `underline solid ${theme.palette.grey2.main}`,
  },
  configName: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
  },
  configLabel: {
    fontSize: '0.75rem',
    lineHeight: '1.25rem',
    color: theme.palette.grey2.main,
  },
  configValue: {
    fontSize: '0.8125rem',
    lineHeight: '1.125rem',
    color: theme.palette.common.white,
  },
  testInfoWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '1.6875rem',
  },
  testInfo: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.625rem',
  },
  testInfoLabel: {
    fontSize: '0.75rem',
    lineHeight: '0.9375rem',
    color: theme.palette.grey2.main,
  },
  testInfoBadgeResult: {
    padding: '0.25rem 0.75rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '0.75rem',
    lineHeight: '1.25rem',
    color: theme.palette.common.white,
    backgroundColor: 'rgba(9, 181, 129, 0.3)',
    border: '0.0625rem solid rgba(9, 181, 129, 1)',
    borderRadius: '0.875rem',
    fontWeight: '600',
    textTransform: 'uppercase',
  },
  testInfoBadgeStatus: {
    padding: '0.25rem 0.75rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '0.75rem',
    lineHeight: '1.25rem',
    color: theme.palette.common.white,
    backgroundColor: 'rgba(135, 151, 171, 0.3)',
    border: '0.0625rem solid rgba(135, 151, 171, 1)',
    borderRadius: '0.875rem',
    fontWeight: '600',
    textTransform: 'uppercase',
  },
}));

interface ConfigValidationProps {
  data?: ConfigValidationItem;
}

export default function ConfigValidation({ data }: ConfigValidationProps) {
  const [showContent, setShowContent] = useState(false);
  const { filters, setFilters } = useFilterContext();

  const classes = useStyles();
  const theme = useTheme();

  const handleDelete = (idx: number) => {
    setFilters(prev => prev.filter((_, index: number) => index !== idx));
  };

  const getIcon = (status: ConfigValidationItemStatus) => {
    const icons: Record<ConfigValidationItemStatus, string> = {
      passed: PassedIcon,
      'no-data': NoDataIcon,
      failed: FailedIcon,
    };

    return icons[status] || '';
  };

  const explanations = [
    { id: 1, image: PassedIcon, title: 'Configuration Passed Test' },
    { id: 2, image: NoDataIcon, title: 'No Data for Configuration Test' },
    { id: 3, image: FailedIcon, title: 'Configuration Failed Test' },
  ];

  const transformedData = {
    ...data,
    tabName: 'Asset Management (ID.AM): Assets',
    date: new Date(),
    testStatus: 'disabled',
  };

  return (
    <Stack spacing={3}>
      <div>
        <Typography variant="h4" style={{ color: '#8797AB' }}>
          Filters
        </Typography>
        <div style={{ marginTop: 8, marginLeft: 2, display: 'flex', gap: 12 }}>
          <FilterButton sources={[]} />
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: 12,
              height: 50,
              overflowY: 'auto',
            }}
          >
            {filters.map((f: Filter, idx: number) => (
              <Chip
                key={`${f.column}-${f.value}`}
                variant="outlined"
                style={{ borderRadius: 2, height: 40 }}
                label={
                  <div>
                    {f.title} {f.value}
                  </div>
                }
                onDelete={() => handleDelete(idx)}
              />
            ))}
          </div>
        </div>
      </div>
      <Box className={classes.wrapper}>
        <Box className={classes.item}>
          <Box
            className={classes.heading}
            sx={{ borderBottom: showContent ? `0.0625rem solid ${theme.palette.darkBlue4.main}` : 'none' }}
          >
            <Typography className={classes.date}>{format(transformedData.date, 'MM-dd-yyyy hh:mm:ssa')}</Typography>
            <Box className={classes.items}>
              <Box className={classes.configName}>
                <Typography className={classes.configLabel}>Name</Typography>
                <Typography className={classes.configValue}>{transformedData.tabName}</Typography>
              </Box>
              <Box className={classes.testInfoWrapper}>
                <Box className={classes.testInfo}>
                  <Typography className={classes.testInfoLabel}>Test Results:</Typography>
                  <Typography className={classes.testInfoBadgeResult}>{transformedData.status}</Typography>
                </Box>
                <Box className={classes.testInfo}>
                  <Typography className={classes.testInfoLabel}>Test Status:</Typography>
                  <Typography className={classes.testInfoBadgeStatus}>{transformedData.testStatus}</Typography>
                </Box>
              </Box>
              <IconButton onClick={() => setShowContent(prev => !prev)} size="large">
                {showContent ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            </Box>
          </Box>
          {showContent && (
            <Box className={classes.content}>
              <Box className={classes.testResults}>
                <Typography className={classes.testResultsLabel}>Automated Configuration Test Results</Typography>
                <Box className={classes.tests}>
                  {transformedData.tests?.map(test => (
                    <Box className={classes.testsWrapper}>
                      <Box className={classes.testsItems}>
                        <img
                          src={getIcon(test.status as ConfigValidationItemStatus)}
                          alt="test result icon"
                          className={classes.explanationsItemsImage}
                        />
                        <Typography className={classes.testsItemsLabel}>{test.name}</Typography>
                      </Box>
                      <Typography className={classes.testsItemsDescription}>{test.description}</Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
              <Box className={classes.explanations}>
                <Box className={classes.explanationsWrapper}>
                  {explanations.map(e => (
                    <Box key={e.id} className={classes.explanationsItems}>
                      <img src={e.image} alt="test status icon" className={classes.explanationsItemsImage} />
                      <Typography className={classes.explanationsItemsLabel}>{e.title}</Typography>
                    </Box>
                  ))}
                </Box>
                <Typography className={classes.explanationsLabel}>
                  A baseline of network operations and expected data flows for users and systems is established and
                  managed
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Stack>
  );
}
