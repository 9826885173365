import { Button, Grid } from '@mui/material';
import { useState } from 'react';
import Modal from '../../../reusables/Modal';
import AssessmentViews from './AssessmentViews/AssessmentViews';
import { AssessmentView } from './AssessmentViews/interfaces';

export default function AssessmentsButton() {
  const [open, setOpen] = useState(false);
  const [view, setView] = useState<AssessmentView>();

  return (
    <Grid item>
      <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
        Assessments
      </Button>
      {view === AssessmentView.ARCHIVE ? (
        <AssessmentViews onExit={() => setView(AssessmentView.LIST)} view={AssessmentView.ARCHIVE} />
      ) : (
        <Modal open={open} onClose={() => setOpen(false)} maxWidth="lg" fullWidth>
          <AssessmentViews onExit={() => setOpen(false)} onView={setView} view={AssessmentView.LIST} />
        </Modal>
      )}
    </Grid>
  );
}
