import { FetchPolicy, gql } from '@apollo/client';
import { toDates } from '@sightgain/core/dates';
import {
  BakeOffInput,
  Job,
  JobActors,
  JobsFilter,
  JobsResult,
  JobSummary,
  PaginationInput,
  ProductBakeOffResult,
  RunJobInput,
  RunOption,
  TestResult,
  TestResultFilter,
  TestResultsSince,
  TestResultTrend,
} from './interfaces';
import ServiceBase from './ServiceBase';

export const typeTestResult = `
  {
    id
    vendorId
    vendor
    jobId
    testId
    evaluationId
    name
    description
    tags
    status
    created
    createdAt
    alerted
    prevented
    detected
    products {
      vendorId
      name
      type
      prevented
      detected
    }
    srcZoneId
    srcZoneName
    dstZoneId
    dstZoneName
  }
`;

const typeTestResultTrend = `
  {
    from
    to
    scores {
      testId
      vendor
      score
    }
  }
`;

const typeJobStage = `
  {
    id
    vendor
    vendorId
    orderNo
    name
    testResults ${typeTestResult}
  }
`;

export const typeJob = `
  {
    id
    vendor
    vendorId
    name
    description
    evaluationId
    status
    isExcluded
    frameworkControls {
      id
      identifier
      name
    }
    stages ${typeJobStage}
  }
`;

export class JobsService extends ServiceBase {
  constructor() {
    super();
    this.addMiddleWare('after', data =>
      toDates(data, ['from', 'to', 'created', 'createdAt', 'updatedAt', 'started', 'ended']),
    );
  }

  async actors(step: string[]): Promise<JobActors> {
    const query = gql`
      query JobActors($step: [String!]!) {
        jobActors(step: $step) {
          actors {
            id
            name
            os
            ip
          }
          sourceActorIds
          destinationActorIds
        }
      }
    `;

    const { jobActors } = await this.graphql(query, { step });
    return jobActors;
  }

  async bakeOff(input: BakeOffInput): Promise<ProductBakeOffResult[]> {
    // we don't ask for fields because it will have no affect on loading
    const query = gql`
      query BakeOff($input: BakeOffInput!) {
        bakeOff(input: $input) {
          vendorJobId
          vendorTestId
          testName
          productName
          productType
          prevented
          detected
          createdAt
        }
      }
    `;

    const { bakeOff } = await this.graphql(query, { input });

    return bakeOff;
  }

  async findById(id: string, fields = typeJob, fetchPolicy: FetchPolicy = 'cache-first'): Promise<Job> {
    const query = gql`query Job($id: ID!) { job(id: $id) ${fields} }`;
    const { job } = await this.graphql(query, { id }, fetchPolicy);
    return job;
  }

  async list(
    testIds: string[],
    filter?: TestResultFilter,
    pagination: PaginationInput = {},
    fields = typeTestResult,
  ): Promise<TestResult[]> {
    const query = gql`
      query TestResults($testIds: [String!]!, $filter: TestResultFilter, $pagination: PaginationInput) {
        testResults(testIds: $testIds, filter: $filter, pagination: $pagination) ${fields}
      }
    `;

    const chunkSize = 1000;
    const items = testIds?.length || 0;
    const chunks = Math.ceil(items / chunkSize);

    const allTestResults = [...Array(chunks)].reduce(async (acc, _, i) => {
      const results: TestResult[] = await acc;
      const ids = testIds.slice(i * chunkSize, i * chunkSize + chunkSize);
      const { testResults } = await this.graphql(query, { testIds: ids, filter, pagination });
      results.push(...testResults);
      return results;
    }, Promise.resolve([]));

    return allTestResults;
  }

  async listJobs(
    filter: JobsFilter = {},
    pagination: PaginationInput = {},
    fields = typeJob,
    fetchPolicy: FetchPolicy = 'cache-first',
  ): Promise<JobsResult> {
    const query = gql`
      query Jobs($filter: JobsFilter, $pagination: PaginationInput) {
        jobs(filter: $filter, pagination: $pagination) {
          jobs ${fields}
          count
        }
      }
    `;

    const { jobs } = await this.graphql(query, { filter, pagination }, fetchPolicy);
    return jobs;
  }

  async products(): Promise<Array<{ name: string; type: string }>> {
    const query = gql`
      query JobProducts {
        jobProducts {
          name
          type
        }
      }
    `;
    const { jobProducts } = await this.graphql(query);
    return jobProducts;
  }

  async runJob(input: RunJobInput, fields = typeJob): Promise<Job> {
    const query = gql`
      mutation RunJob($input: RunJobInput!) {
        runJob(input: $input) ${fields}
      }
    `;

    const { runJob } = await this.graphql(query, { input });
    return runJob;
  }

  /**
   * Returns the custom options for the evaluation
   * @param stages an array of arrays of test ids; each primary array
   * represents a stage
   */
  async runOptions(stages: string[][]): Promise<RunOption[][]> {
    const query = gql`
      query RunOptions($stages: [[String!]!]!) {
        runOptions(stages: $stages) {
          key
          label
          default
          isRequired
          options {
            value
            label
          }
        }
      }
    `;
    const { runOptions } = await this.graphql(query, { stages });
    return runOptions;
  }

  async summaries(startDate: Date, endDate: Date): Promise<JobSummary[]> {
    const query = gql`
      query JobsSummary($startDate: Date!, $endDate: Date!) {
        jobsSummary(startDate: $startDate, endDate: $endDate) {
          name
          description
          evaluationId
          runCount
        }
      }
    `;
    const { jobsSummary } = await this.graphql(query, { startDate, endDate });

    return jobsSummary;
  }

  async excludeJob(id: string, isExcluded: boolean, fields = typeJob): Promise<Job> {
    const query = gql`mutation ExcludeJob($id: ID!, $isExcluded: Boolean!) { excludeJob(id: $id, isExcluded: $isExcluded) ${fields} }`;
    const { excludeJob } = await this.graphql(query, { id, isExcluded }, 'network-only');
    return excludeJob;
  }

  async excludeJobs(evaluationId: string, isExcluded: boolean, fields = '{ id }'): Promise<Job> {
    const query = gql`mutation ExcludeJobs($evaluationId: String!, $isExcluded: Boolean!) {
      excludeJobs(evaluationId: $evaluationId, isExcluded: $isExcluded) ${fields}
    }`;

    const { excludeJobs } = await this.graphql(query, { evaluationId, isExcluded }, 'network-only');
    return excludeJobs;
  }

  async setFrameworkControls(id: string, controls: string[], fields = typeJob): Promise<Job> {
    const query = gql`mutation setFrameworkControls($id: ID!, $controls: [ID!]!) { setFrameworkControls(id: $id, controls: $controls) ${fields} }`;
    const { setFrameworkControls } = await this.graphql(query, { id, controls }, 'network-only');
    return setFrameworkControls;
  }

  /**
   * Returns the number of test results add since the requested data
   * and limited to the requested testIds (omiting testIds assumes all test results)
   */
  async testResultsSince(input: TestResultsSince): Promise<number> {
    const query = gql`
      query TestResultsSince($input: TestResultsSinceInput!) {
        testResultsSince(input: $input)
      }
    `;
    const { testResultsSince } = await this.graphql(query, { input }, 'network-only');
    return testResultsSince;
  }

  async trends(testIds: string[], fields = typeTestResultTrend): Promise<TestResultTrend[]> {
    const query = gql`
      query TestResultTrends($ids: [String!]!) {
        testResultTrends(testIds: $ids) ${fields}
      }
    `;

    const { testResultTrends } = await this.graphql(query, { ids: testIds });

    return testResultTrends;
  }
}

const jobsService = new JobsService();
export default jobsService;
