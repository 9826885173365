import clsx from 'clsx';
import { ReactNode, useMemo } from 'react';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { truncate } from '@sightgain/core/strings';
import { FrameworkItemWithScores } from '../../../../../../services/interfaces';

const useStyles = makeStyles(theme => ({
  cell: {
    minWidth: 140,
    minHeight: 110,
    marginLeft: -1,
    marginTop: -1,
    borderRadius: 0,
    display: 'flex',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  head: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'transparent',
    border: 'none',
    color: theme.palette.text.primary,
  },
  headerText: {
    margin: 'auto',
    width: '100%',
    textAlign: 'center',
    textTransform: 'capitalize',
    fontSize: '100%',
    fontWeight: 'bold',
    padding: '0px 20px 0px 0px',
  },
}));

function createFriendly(key: string) {
  return key
    .toLowerCase()
    .replace(/[^a-z0-9\-_]/g, '-')
    .replace(/-+/g, '-');
}

export default function Column({ name, items, cellComponent }: ColumnProps<FrameworkItemWithScores>) {
  const classes = useStyles();

  const columnKey = useMemo(() => createFriendly(name), [name]);

  return (
    <div className={classes.column} data-testid={`framework-group-${columnKey}`}>
      <div className={clsx(classes.cell, classes.head)}>
        <Typography component="div" className={classes.headerText}>
          {truncate(name, 50)}
        </Typography>
      </div>
      {items.map(item => cellComponent(item, columnKey))}
    </div>
  );
}

type ColumnProps<T> = {
  name: string;
  items: T[];
  cellComponent: (cell: T, key: string) => ReactNode;
};
