import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { Objective } from '../interface';
import useInput from '../../../../../reusables/UseInput';
import assessmentsService from '../../../../../services/AssessmentsService';
import { useModal } from '../../../../../reusables/BetterModal';
import { Assessment, FrameworkWithScores } from '../../../../../services/interfaces';

const useStyles = makeStyles(() => ({
  input: {
    marginTop: 20,
    width: '100%',
    borderRadius: '3px',
    border: 'solid 1px #61788e',
    padding: '11px 18px',
    '& > div': {
      '& textarea': {
        fontSize: 14,
        fontFamily: 'Lexend !important',
      },
    },
  },
}));

export default function ThresholdForm({ assessment, framework, data }: ThresholdFormProps) {
  const classes = useStyles();
  const { closeModal } = useModal('itemThresholdModal');
  const { value: thresholdValue, bind: thresholdBind } = useInput(
    data?.scores.subItemAutomatedMaturityScore.threshold || 0,
  );

  async function handleSave() {
    await assessmentsService.updateObjective(assessment.id, framework.id, data?.id || '', {
      minThreshold: +thresholdValue,
    });

    closeModal();
  }

  return (
    <Box bgcolor="darkBlue1.main" padding={4} border="solid 1px #5d6e80">
      <Grid container spacing={2} direction="column">
        <Grid item>
          <Grid container direction="column">
            <Grid item>
              <Typography variant="textStyle1" color="grey2.main">
                {data?.identifier} {data?.name}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="textStyle10">Set Minimum Threshold</Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Grid container gap={2}>
            <Grid item>
              <Grid container direction="column">
                <Grid item>
                  <TextField
                    className={classes.input}
                    variant="standard"
                    type="text"
                    margin="normal"
                    inputProps={{ 'aria-label': 'Minimum' }}
                    fullWidth
                    {...thresholdBind}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item display="flex" justifyContent="end">
          <Button variant="contained" onClick={handleSave}>
            Save
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

type ThresholdFormProps = {
  assessment: Assessment<FrameworkWithScores>;
  framework: FrameworkWithScores;
  data?: Objective;
};
