import { useCallback, useMemo, useState } from 'react';
import appStore from '../../../../../AppStore';
import useSocketListener from '../../../../reusables/useSocketListener';
import { jobsService } from '../../../../services';
import { JobsResult, PaginationInput } from '../../../../services/interfaces';
import { toRow } from './utilities';

interface UseJobsWatcherProps {
  fields: string;
  type?: string;
  status?: string;
  pagination: PaginationInput;
}

export default function useJobsWatcher({ fields, type, status, pagination }: UseJobsWatcherProps) {
  // a loading indicator for use in the data grid
  const [loading, setLoading] = useState(false);

  // need to memoize the filter object to prevent unnecessary api calls
  const filter = useMemo(
    () => ({
      type: type || undefined,
      status: status === 'all statuses' ? undefined : status,
    }),
    [type, status],
  );

  // the callback function that will be used to fetch the data
  const callback = useCallback(async () => {
    setLoading(true);
    try {
      // fetch the jobs
      const results = await jobsService.listJobs(filter, pagination, fields, 'no-cache');
      // stop the loading indicator
      setLoading(false);
      return results;
    } catch (err) {
      // stop the loading indicator
      setLoading(false);
      // log the error
      appStore.error(err);
      // return an empty object
      return { jobs: [], count: 0 };
    }
  }, [filter, fields, pagination]);

  // use the socket listener to listen for updates to the jobs
  const result = useSocketListener<JobsResult>('jobs', 'update', callback);

  // destructure the results
  const { jobs = [], count = 0 } = result || {};

  // return the jobs, count, and loading indicator
  return { jobs: jobs.flatMap(toRow), count, loading };
}
