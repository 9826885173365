import { useEffect, useMemo, useState } from 'react';
import Tabs from '../../../../reusables/Tabs';
import { makeStyles } from '@mui/styles';
import Card from './Card';
import Actions from './Actions';
import { Recommendation } from '../interfaces';
import RecommendationsService from '../../../../services/RecommendationsService';
import appStore from '../../../../../AppStore';
import None from './None';
import { FilterButton, useFilterContext } from '../../../../reusables/Filter';
import { Filter, FilterSource } from '../../../../reusables/Filter/interfaces';
import { Chip, Typography } from '@mui/material';

const useStyles = makeStyles(() => ({
  container: {
    borderRadius: 6,
    background: '#192637',
  },
  cards: {
    display: 'flex',
    gap: 24,
    flexDirection: 'column',
    padding: 24,
    flex: 1,
    overflowY: 'auto',
    maxHeight: '70vh', // TODO: temporary fix for overflow issue
    minHeight: '70vh',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    flex: 1,
    height: '100%',
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    flex: 0,
  },
}));

const tabs = [
  { status: 'priority', label: 'Priorities' },
  { status: 'ignored', label: 'Ignored' },
  { status: 'resolved', label: 'Resolved' },
];

const riskItems = [
  { text: 'HIGH', value: 'high' },
  { text: 'MEDIUM', value: 'medium' },
  { text: 'LOW', value: 'low' },
];

const sources: FilterSource[] = [
  { name: 'riskLevel', title: 'Risk Level', filterType: 'select', menuItems: riskItems },
];

interface RecommendationTabsProps {
  type: string;
  recommendations: Recommendation[] | undefined;
  onRefresh: () => void;
}

export default function RecommendationTabs({ type, recommendations, onRefresh }: RecommendationTabsProps) {
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedCards, setSelectedCards] = useState<string[]>([]);
  const classes = useStyles();

  const { filters, setFilters } = useFilterContext();

  // clear cards when type changes
  useEffect(() => setSelectedCards([]), [type]);

  const isCardSelected = (id: string) => {
    return selectedCards.some(x => x === id);
  };

  const handleCardSelect = (id: string) => {
    if (isCardSelected(id)) {
      setSelectedCards(selectedCards.filter(x => x !== id));
    } else {
      setSelectedCards([...selectedCards, id]);
    }
  };

  const handleTabSelect = (n: number) => {
    setSelectedCards([]);
    setSelectedTab(n);
  };

  const handleIgnore = async () => {
    appStore.beginLoading();
    try {
      const result = await RecommendationsService.ignore(selectedCards);
      appStore.success(result);
    } catch (err) {
      appStore.error(err);
    }
    appStore.endLoading();
    onRefresh();
  };

  const handleResolve = async () => {
    appStore.beginLoading();
    try {
      const result = await RecommendationsService.resolve(selectedCards);
      appStore.success(result);
    } catch (err) {
      appStore.error(err);
    }
    appStore.endLoading();
    onRefresh();
  };

  const handleRestore = async () => {
    appStore.beginLoading();
    try {
      const result = await RecommendationsService.restore(selectedCards);
      appStore.success(result);
    } catch (err) {
      appStore.error(err);
    }
    appStore.endLoading();
    onRefresh();
  };

  const handleClick = async (id: string) => {
    appStore.beginLoading();
    try {
      await RecommendationsService.restore([id]);
    } catch (err) {
      appStore.error(err);
    }
    appStore.endLoading();
    onRefresh();
  };

  const handleDelete = (idx: number) => {
    setFilters(filters.filter((x: any, y: number) => y !== idx));
  };

  const filteredRecommendationsByStatus = recommendations?.filter(
    r =>
      ((r.status === 'new' && tabs[selectedTab].status === 'priority') || r.status === tabs[selectedTab].status) &&
      (type === 'all' || r.type === type),
  );

  const filteredRecommendations = useMemo(() => {
    const filtered = filteredRecommendationsByStatus?.filter(
      (x: Recommendation) => !filters.some((y: Filter) => !y.filter(x)),
    );

    return Object.values(filtered || []);
  }, [filteredRecommendationsByStatus, filters]);

  return (
    <div className={classes.container} data-testid="recommendations-tabs">
      <Tabs
        selected={selectedTab}
        onSelect={handleTabSelect}
        labels={tabs.map(t => t.label)}
        scrollButtons="auto"
        variant="fullWidth"
      />
      <div className={classes.content}>
        <div className={classes.cards}>
          <div>
            <Typography variant="h4" style={{ color: '#8797AB' }}>
              Filters
            </Typography>
            <div style={{ marginTop: 8, display: 'flex', gap: 12 }}>
              <FilterButton sources={sources} />
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: 12,
                  height: 50,
                  overflowY: 'auto',
                }}
              >
                {filters.map((f: Filter, idx: number) => (
                  <Chip
                    key={`${f.column}-${f.value}`}
                    variant="outlined"
                    style={{ borderRadius: 2, height: 40 }}
                    label={
                      <div>
                        {f.title} {f.value}
                      </div>
                    }
                    onDelete={() => handleDelete(idx)}
                  />
                ))}
              </div>
            </div>
          </div>
          {/* card area, TODO: react window future enhancement? */}
          {filteredRecommendations?.map(r => (
            <Card
              recommendation={r}
              key={r.id}
              selected={isCardSelected(r.id)}
              onSelect={handleCardSelect}
              onClick={handleClick}
              selectable
            />
          ))}
          {filteredRecommendations?.length === 0 && <None />}
        </div>

        {/* action area */}
        <div className={classes.actions}>
          <Actions
            selectedTab={tabs[selectedTab].status}
            onIgnore={handleIgnore}
            onResolve={handleResolve}
            onRestore={handleRestore}
            disabled={!selectedCards.length}
          />
        </div>
      </div>
    </div>
  );
}
