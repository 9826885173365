import { Assessment } from '../../../../services/interfaces';

export enum AssessmentView {
  LIST,
  NEW,
  DETAILS,
  LICENSES,
  ARCHIVE,
}

export interface AssessmentViewProps {
  onChangeView: (view: AssessmentView) => unknown;
  onExit: () => unknown;
  view: AssessmentView;
  activeView: AssessmentView;
  assessment: Assessment;
  assessments: Assessment[];
  setAssessment: React.Dispatch<React.SetStateAction<Assessment>>;
}
