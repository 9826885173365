import Accounts from './AccountManagement';
// import CloudServiceProvider from './CloudServiceProvider';
import Content from './Content';
import CustomCalculations from './CustomCalculations';
import ExploitTesting from './ExploitTesting';
import ExportData from './Export/ExportData';
import Licensing from './Licensing/Licensing';
import Logs from './Logs';
import Miscellaneous from './Miscellaneous';
import Siem from './Siem';
import SipSettings from './SipSettings';
import Ticketing from './Ticketing';

export interface SettingsRoutes {
  [key: string]: (() => JSX.Element) | React.MemoExoticComponent<() => JSX.Element>;
}

export const SETTINGS_ROUTES: SettingsRoutes = {
  accounts: Accounts,
  'security-instrumentation': SipSettings,
  logs: Logs,
  licensing: Licensing,
};

export const LIMITED_SETTINGS_ROUTES: SettingsRoutes = {
  ...SETTINGS_ROUTES,
  'content-management': Content,
  // hidden for now
  // 'cloud-service-provider': CloudServiceProvider,
};

export const FULL_SETTINGS_ROUTES: SettingsRoutes = {
  ...LIMITED_SETTINGS_ROUTES,
  'custom-calculations': CustomCalculations,
  'atomic-red-team': ExploitTesting,
  siem: Siem,
  'ticketing-system': Ticketing,
  miscellaneous: Miscellaneous,
  export: ExportData,
};
