import { FormEvent, useMemo, useState } from 'react';
import { FixedSizeList } from 'react-window';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SearchIcon from '@mui/icons-material/Search';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { partition } from '@sightgain/core';
import appStore from '../../../../../../AppStore';
import { useAbort } from '../../../../../../effects';
import Modal from '../../../../../reusables/Modal';
import useInput from '../../../../../reusables/UseInput';
import { evaluationsService, examsService, testsService } from '../../../../../services';
import { MgrListItem } from '../../Manager/interfaces';
import { TestListItem } from './interfaces';

const useStyles = makeStyles(theme => ({
  errorWrapper: {
    marginBottom: '10px',
  },
  input: {
    margin: 0,
    width: '100%',
    borderRadius: '3px',
    border: 'solid 1px #61788e',
    '& > div': {
      '& textarea': {
        fontSize: 14,
        fontFamily: 'Lexend !important',
      },
    },
  },
  listWrapper: {
    height: '300px',
  },
  removeIcon: {
    color: theme.palette.orange1.main,
  },
  testItem: {
    padding: '10px 20px',
    '&:hover': {
      background: theme.palette.darkBlue4.main,
      cursor: 'pointer',
    },
  },
}));

enum EvaluationType {
  EVALUATION = 'evaluation',
  EXAM = 'exam',
}

export default function EvaluationModal({ evaluation, open = false, vendor, onClose }: EvaluationModalProps) {
  const classes = useStyles();
  const { value: name, setValue: setName, bind: bindName } = useInput(evaluation?.name);
  const { value: description, setValue: setDescription, bind: bindDescription } = useInput(evaluation?.description);
  const { value: filterAvailable, setValue: setFilterAvailable, bind: bindFilterAvailable } = useInput('');
  const { value: filterSelected, setValue: setFilterSelected, bind: bindFilerSelected } = useInput('');
  const [nameError, setNameError] = useState('');
  const [descriptionError, setDescriptionError] = useState('');
  const [requestError, setRequestError] = useState('');
  const [selected, setSelected] = useState<boolean[]>([]);
  const [tests, setTests] = useState<TestListItem[]>([]);
  const [evaluationType, setEvaluationType] = useState<EvaluationType>(EvaluationType.EVALUATION);

  // get the tests for the vendor
  useAbort(
    () => {
      // if the vendor has not changed or the modal is not open, return
      if (!open || tests.some(test => test.vendor === vendor)) {
        return;
      }

      return testsService.list(vendor, '{ id description, name, type, vendor, vendorId }');
    },
    tests => {
      if (!tests) {
        return;
      }

      // set the tests
      setTests(tests);

      // create a map of equal size for selections
      setSelected(new Array(tests.length).fill(false));
    },
    [open, vendor],
  );

  // save the evaluation
  const handleSaveClick = async (event: FormEvent) => {
    event.preventDefault();

    if (!validate()) {
      return;
    }

    appStore.beginLoading();
    try {
      const input = {
        name: `SightGain: ${name}`,
        description,
        vendor,
        testIds: tests.filter((_item, idx) => selected[idx]).map(test => test.vendorId),
      };

      if (evaluationType === EvaluationType.EXAM) {
        await examsService.create(input, '{ id }');
      } else {
        await evaluationsService.createEvaluation(input, '{ id }');
      }

      appStore.success(`Created ${input.name}`);

      reset();
      onClose();
    } catch (err) {
      setRequestError((err as Error).message);
      appStore.error(err);
    }

    appStore.endLoading();
  };

  // validate input before submitting
  const validate = () => {
    // clear or set the request error
    const reqErr = selected.filter(Boolean).length ? '' : 'A minimum of one test is required';
    const nmErr = name ? '' : 'Name is required';
    const descErr = description ? '' : 'Description is required';

    setRequestError(reqErr);
    setNameError(nmErr);
    setDescriptionError(descErr);

    return !(reqErr || nmErr || descErr);
  };

  const reset = () => {
    setName('');
    setDescription('');
    setFilterAvailable('');
    setFilterSelected('');
    setEvaluationType(EvaluationType.EVALUATION);
    setSelected(new Array(tests.length).fill(false));
  };

  // filter available tests by name or description matching search input
  const filterAvailableTests = (item: TestListItem, idx: number) => {
    return `${item.vendorId}${item.name}`.toLowerCase().includes(filterAvailable.toLowerCase());
  };

  // filter selected tests by name or descripition maching search input
  const filterSelectedTests = (item: TestListItem) => {
    return `${item.vendorId}${item.name}`.toLowerCase().includes(filterSelected.toLowerCase());
  };

  const selectAll = () => {
    setSelected(new Array(tests.length).fill(true));
  };

  const deselectAll = () => {
    setSelected(new Array(tests.length).fill(false));
  };

  // move an item from availiable => selected or selected => available
  const moveItem = (id: string) => {
    // find the test by id
    const index = tests.findIndex(t => t.id === id);
    setSelected(prev => {
      prev[index] = !prev[index];
      return [...prev];
    });
  };

  // each render, partition into two buckets (available / selected)
  const [availableTests = [], selectedTests = []] = useMemo(
    () =>
      partition(tests, (_item, idx) => {
        return selected[idx] ? 1 : 0;
      }),
    [tests, selected],
  );

  // filter availableTests
  const availableTestsList = availableTests.filter(filterAvailableTests);

  // filter selected Tests
  const selectedTestsList = selectedTests.filter(filterSelectedTests);

  // component for each row in the available column
  const AvailableRow = ({ style, index }: { style: object; index: number }) => (
    <Box
      key={availableTestsList[index].id}
      style={style}
      className={classes.testItem}
      onClick={() => moveItem(availableTestsList[index].id)}
    >
      <Typography>{availableTestsList[index].name || 'Name not found'}</Typography>
      <Typography>({availableTestsList[index].vendorId})</Typography>
    </Box>
  );

  // component for each row in the selected column
  const SelectedRow = ({ style, index }: { style: object; index: number }) => (
    <Box
      key={selectedTestsList[index].id}
      style={style}
      className={classes.testItem}
      onClick={() => moveItem(selectedTestsList[index].id)}
    >
      <Grid container justifyContent="space-between" gap={5} alignItems="center" height="100%">
        <Grid item flex={1}>
          <Typography>{selectedTestsList[index].name || 'Name not found'}</Typography>
          <Typography>({selectedTestsList[index].vendorId})</Typography>
        </Grid>
        <Grid item>
          <FontAwesomeIcon icon={['far', 'times-circle']} size="2x" className={classes.removeIcon} />
        </Grid>
      </Grid>
    </Box>
  );

  return (
    <Modal
      open={open}
      onClose={onClose}
      onSubmit={e => handleSaveClick(e)}
      actions={[
        { text: 'Create', submit: true, color: 'primary' },
        { text: 'Cancel', color: 'secondary', variant: 'text', onClick: onClose },
      ]}
      maxWidth="lg"
      fullWidth
    >
      {requestError && (
        <Alert severity="error" variant="outlined" className={classes.errorWrapper}>
          <AlertTitle>{requestError}</AlertTitle>
        </Alert>
      )}
      <Grid container spacing={2} justifyContent="space-around" flexDirection="column">
        <Grid item>
          <Typography variant="textStyle7">Create Evaluation</Typography>
        </Grid>
        <Grid item>
          <Typography variant="textStyle1">New Name</Typography>
          <TextField
            error={!!nameError}
            helperText={nameError}
            autoFocus
            fullWidth
            variant="standard"
            id="test_set_name"
            inputProps={{ 'aria-label': 'Name' }}
            type="text"
            className={classes.input}
            {...bindName}
          />
        </Grid>
        <Grid item>
          <Typography variant="textStyle1">Description</Typography>
          <TextField
            className={classes.input}
            error={!!descriptionError}
            helperText={descriptionError}
            variant="standard"
            id="test_set_description"
            type="text"
            margin="normal"
            inputProps={{ 'aria-label': 'Description' }}
            multiline
            fullWidth
            {...bindDescription}
          />
        </Grid>
        <Grid item>
          <FormControl>
            <RadioGroup
              name="evaluation-import-type"
              row
              value={evaluationType}
              onChange={e => setEvaluationType(e.target.value as EvaluationType)}
            >
              <FormControlLabel value={EvaluationType.EVALUATION} control={<Radio />} label="Evaluation" />
              <FormControlLabel value={EvaluationType.EXAM} control={<Radio />} label="Exam" />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item>
          <Grid container justifyContent="space-between" gap={4}>
            <Grid item flex={1}>
              <Typography variant="textStyle1">Available Tests</Typography>
              <Box border={1} borderColor="darkBlue4.main">
                <TextField
                  fullWidth
                  margin="normal"
                  variant="standard"
                  id="filter_available"
                  inputProps={{ 'aria-label': 'Find' }}
                  type="text"
                  className={classes.input}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon fontSize="small" />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Find"
                  {...bindFilterAvailable}
                />
                <Box className={classes.listWrapper}>
                  <FixedSizeList itemCount={availableTestsList.length} itemSize={100} height={300} width="100%">
                    {AvailableRow}
                  </FixedSizeList>
                </Box>
                <Box
                  borderTop={1}
                  borderColor="darkBlue4.main"
                  bgcolor="darkBlue1.main"
                  display="flex"
                  justifyContent="center"
                  padding={2}
                >
                  <Button onClick={selectAll} fullWidth>
                    <Typography variant="textStyle3" color="blue3.main" justifySelf="center">
                      Select All
                    </Typography>
                  </Button>
                </Box>
              </Box>
            </Grid>
            <Grid item flex={1}>
              <Typography variant="textStyle1">Selected Tests</Typography>
              <TextField
                fullWidth
                margin="normal"
                variant="standard"
                id="filter_selected"
                inputProps={{ 'aria-label': 'Find' }}
                type="text"
                className={classes.input}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon fontSize="small" />
                    </InputAdornment>
                  ),
                }}
                placeholder="Find"
                {...bindFilerSelected}
              />
              <Box className={classes.listWrapper}>
                <FixedSizeList itemCount={selectedTestsList.length} itemSize={100} height={300} width="100%">
                  {SelectedRow}
                </FixedSizeList>
              </Box>
              <Box border={1} borderColor="darkBlue4.main">
                <Box
                  borderTop={1}
                  borderColor="darkBlue4.main"
                  bgcolor="darkBlue1.main"
                  display="flex"
                  justifyContent="center"
                  padding={2}
                >
                  <Button onClick={deselectAll}>
                    <Typography variant="textStyle3" color="orange1.main">
                      Clear All
                    </Typography>
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
}

interface EvaluationModalProps {
  open?: boolean;
  vendor: string;
  evaluation?: MgrListItem;
  onClose: () => unknown;
}
