import { Link, useLocation } from 'react-router-dom';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { SRPPage } from './interfaces';

const useStyles = makeStyles(() => ({
  List: {
    borderTop: '1px solid #2c324b',
    flexGrow: 1,
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  ListItem: {
    flexGrow: 1,
    overflow: 'hidden',
    padding: '20px 30px',
    borderBottom: '1px solid #2c324b',
    '&:hover': {
      backgroundColor: '#161926',
      '& > div:first-child': {
        color: '#ffffff',
      },
    },
  },
  ListItemSelected: {
    backgroundColor: '#0a0c10',
    '&:hover': {
      backgroundColor: '#0a0c10',
    },
    '& > div:first-child': {
      color: '#ffffff',
    },
  },
  ListItemText: {
    textAlign: 'left',
    textTransform: 'uppercase',
    '& span': {
      fontSize: 12,
      fontWeight: 'bold',
      letterSpacing: '0.6px',
    },
  },
  ListItemIcon: {
    color: '#4a5169',
    '& svg': {
      fontSize: 21,
    },
  },
}));

export default function Menu({ open, pages }: MenuProps) {
  const classes = useStyles();
  const { pathname } = useLocation();

  return (
    <List role="navigation" className={classes.List}>
      {pages.map(({ name, icon, to }) => (
        <ListItem
          className={`${classes.ListItem} ${pathname.startsWith(to) && classes.ListItemSelected}`}
          // https://github.com/mui/material-ui/issues/14971
          button={(pathname !== to) as any}
          component={pathname !== to ? Link : 'div'}
          to={to}
          key={name}
        >
          <ListItemIcon className={classes.ListItemIcon}>
            <FontAwesomeIcon fixedWidth icon={['fal', icon as IconName]} />
          </ListItemIcon>
          {open && <ListItemText className={classes.ListItemText} primary={name} />}
        </ListItem>
      ))}
    </List>
  );
}

interface MenuProps {
  open: boolean;
  pages: SRPPage[];
}
