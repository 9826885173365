import { clusterBy } from '@sightgain/core';
import { TestAnalysisScore, TestAnalysisTest } from './interfaces';

function score(values: boolean[]): number {
  return Math.round((values.filter(v => v).length / values.length) * 10000) / 100 || 0;
}

function countErrored(values: string[]): number {
  return Math.round((values.filter(v => v === 'errored').length / values.length) * 10000) / 100 || 0;
}

export default function testScores(testResults: TestAnalysisTest[]): TestAnalysisScore[] {
  const flattend = testResults.map(tr => ({
    testId: tr.testId,
    name: tr.name,
    status: tr.status,
    products: tr.products,
  }));

  // Group all runs of an action into a single entry
  const clustered = clusterBy('testId', flattend);

  return clustered.map((clusteredValue, id) => ({
    id,
    testId: clusteredValue.testId,
    name: clusteredValue.name,
    numberOfRuns: clusteredValue.status.length,
    detected: score(clusteredValue.products.flatMap(a => a.flatMap(p => p.detected))),
    prevented: score(clusteredValue.products.flatMap(a => a.flatMap(p => p.prevented))),
    status: countErrored(clusteredValue.status),
  }));
}
