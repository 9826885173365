import { Box, Stack, Typography } from '@mui/material';
import { capitalize } from '@sightgain/core/strings';
import TextField from '../../../reusables/TextField';
import SettingWrapper from './SettingWrapper';

export default function FrameworkScoringWeights() {
  return (
    <SettingWrapper
      title="Framework Scoring Weights"
      setting="frameworkScoringWeights"
      defaultValue={{ prevented: 0.8, detected: 0.2, alerted: 0.4 }}
      json
    >
      {(weights, onUpdate) => (
        <Stack spacing={4}>
          <Typography variant="body2">
            The points given to a test result based on prevention, detection, and alerting. Maximum score per result is
            1.0 no matter the sum of the given weights.
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
            {['prevented', 'detected', 'alerted'].map(key => (
              <TextField
                key={`framework-scoring-weight-${key}`}
                label={capitalize(key)}
                value={`${weights[key as keyof typeof weights]}`}
                type="number"
                inputProps={{ max: 1, min: 0, step: 0.1 }}
                onChange={e =>
                  onUpdate({
                    ...weights,
                    [key]: +e.target.value,
                  })
                }
              />
            ))}
          </Box>
        </Stack>
      )}
    </SettingWrapper>
  );
}
