import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import { Replay } from '@mui/icons-material';
import { Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { TestResult } from '../../../../../services/interfaces';
import SecurityZones from './SecurityZones';
import TileDetailCard from './TileDetailCard';

const useStyles = makeStyles(() => ({
  row: {
    display: 'flex',
    gap: 24,
  },
  title: {
    color: '#8797AB',
    fontSize: 13,
  },
}));

export default function TileHistory({ item }: { item: Required<TestResult> }) {
  const classes = useStyles();
  const zones =
    item.srcZoneName && item.dstZoneName
      ? [
          { name: item.srcZoneName, color: 'green' },
          { name: item.dstZoneName, color: 'blue' },
        ]
      : [];

  const header = (
    <div className={classes.row}>
      <div style={{ flex: 3, marginLeft: 5 }}>
        <Typography className={classes.title}>Recent Test</Typography>
        <Typography>{item.name}</Typography>
      </div>
      <div style={{ flex: 1 }}>
        <Typography className={classes.title}>Prevented</Typography>
        <Typography>{item.prevented ? 'TRUE' : 'FALSE'}</Typography>
      </div>
      <div style={{ flex: 1 }}>
        <Typography className={classes.title}>Detected</Typography>
        <Typography>{item.detected ? 'TRUE' : 'FALSE'}</Typography>
      </div>
      <div style={{ flex: 1 }}>
        <Typography className={classes.title}>Alerted</Typography>
        <Typography>{item.alerted ? 'TRUE' : 'FALSE'}</Typography>
      </div>
      <div style={{ flex: 2 }}>
        <Typography className={classes.title}>Date</Typography>
        <Typography>{format(item.started, 'yyyy-MM-dd, HH:mm a')}</Typography>
      </div>
      {/* disabled for now */}
      {false && (
        <Button component={Link} to={''} color="primary" endIcon={<Replay style={{ marginLeft: -14, fontSize: 24 }} />}>
          Run Again
        </Button>
      )}
    </div>
  );

  const content = (
    <div>
      <div className={classes.row}>
        <div style={{ flex: 2 }}>
          <Typography className={classes.title}>Action</Typography>
          <Typography>{item.testId}</Typography>
        </div>
        <div style={{ flex: 1 }}>
          <Typography className={classes.title}>Source</Typography>
          <Typography style={{ textTransform: 'uppercase' }}>{item.vendor}</Typography>
        </div>
      </div>
      <div style={{ marginTop: 28 }}>
        <Typography className={classes.title}>Description</Typography>
        <Typography>{item.description ?? ''}</Typography>
      </div>
      {!!zones.length && (
        <div style={{ marginTop: 28 }}>
          <Typography className={classes.title} style={{ marginBottom: 12 }}>
            Tested Security Zones
          </Typography>
          <SecurityZones zones={zones} />
        </div>
      )}
    </div>
  );

  return <TileDetailCard id={item.testId} header={header} content={content} />;
}
