import { formatDistanceToNow } from 'date-fns';
import { MouseEvent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import appStore from '../../../../../AppStore';
import { StatusBadge } from '../../../../reusables/scl';
import WaitingButton from '../../../../reusables/WaitingButton';
import { jobsService } from '../../../../services';

type OnExpandRowFn = (id: string) => (e: MouseEvent<HTMLButtonElement>) => unknown;

async function handleExcludeJob(id: string, isExcluded: boolean) {
  try {
    await jobsService.excludeJob(id, isExcluded);
  } catch (err) {
    appStore.error(err);
  }
}

export default function useRecentlyRunColumns(onExpandRow: OnExpandRowFn, expandedRows: string[]): GridColDef[] {
  return [
    { headerName: 'ID', field: 'evaluationId', width: 100 },
    { headerName: 'Name', field: 'name', flex: 1 },
    {
      headerName: 'Status',
      field: 'status',
      renderCell: ({ value, row }) => <StatusBadge status={row.isReleased ? 'released' : value} />,
      width: 125,
    },
    { headerName: 'Analyst', field: 'analyst', renderCell: ({ value }) => value ?? 'Live Test', flex: 1 },
    { headerName: 'Date', field: 'createdAt', renderCell: ({ value }) => formatDistanceToNow(value), width: 100 },
    {
      headerName: 'Include in Scoring',
      field: 'isExcluded',
      renderCell: ({ value, row }) => (
        <WaitingButton onClick={() => handleExcludeJob(row.jobId, !value)} value={value ? 'EXCLUDED' : 'INCLUDED'}>
          <FontAwesomeIcon
            style={{ color: value ? '#f85c5c' : 'rgb(0, 255, 177)' }}
            icon={['fas', value ? 'times-circle' : 'check-circle']}
          />
        </WaitingButton>
      ),
      align: 'center',
      width: 150,
    },
    {
      headerName: '',
      field: 'id',
      sortable: false,
      disableColumnMenu: true,
      width: 75,
      renderCell: ({ value }) => (
        <Button onClick={onExpandRow(value)}>
          <FontAwesomeIcon icon={['fas', expandedRows.includes(value) ? 'chevron-down' : 'chevron-up']} />
        </Button>
      ),
    },
  ];
}
