import { addCommas } from '@sightgain/core/strings';
import { Ratio } from '../../services/interfaces';

export function formatterPercent(v: number | Ratio) {
  return typeof v === 'number' ? Math.round(v * 10000) / 100 : `${Math.round(+v * 10000) / 100}%`;
}
export function formatterCurrency(v: number | Ratio) {
  return `$${addCommas(Math.round(+v))}`;
}

export const allColors = ['#2ecff5', '#f46043', '#1592ff', '#00ffb1', '#feba06', '#f67339'];
