import { Box, Typography } from '@mui/material';
import { ConfigValidationItem, ConfigValidationItemStatus } from '../../interfaces';
import { makeStyles, useTheme } from '@mui/styles';
import Progress from '../../../Progress';

const useStyles = makeStyles(theme => ({
  cell: {
    padding: '0.5rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '0.75rem',
    cursor: 'pointer',
  },
  statistics: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  // TODO: Ugly solution, remake
  progress: {
    '& svg': {
      width: '1rem',
      height: '1rem',
    },
    '& svg circle:last-child': {
      stroke: theme.palette.common.white,
    },
    '& div div:last-child': {
      right: '0.1875rem',
      paddingTop: '0 !important',
      paddingLeft: '0 !important',
    },
  },
  tests: {
    fontSize: '0.625rem',
  },
  title: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '0.625rem',
    textTransform: 'uppercase',
  },
}));

interface CellProps {
  item: ConfigValidationItem;
  onClick: (control: ConfigValidationItem, columnName?: string) => void;
}

export default function Cell({ item, onClick }: CellProps) {
  const classes = useStyles();
  const theme = useTheme();

  const getBackground = () => {
    const backgrounds: Record<ConfigValidationItemStatus, string> = {
      passed: '#539B83',
      'no-data': '#979D52',
      failed: 'radial-gradient(135.05% 124.72% at 50% 50%, rgba(241, 16, 16, 0) 23.2%, #F11010 88.39%)',
    };

    return backgrounds[item.status as ConfigValidationItemStatus] || theme.palette.darkBlue3.main;
  };

  const tests = item.tests.length;
  const activeTests = item.tests.filter(test => test.isActive).length;
  const notActiveTests = item.tests.filter(test => !test.isActive).length;

  return (
    <Box sx={{ background: getBackground }} className={classes.cell} onClick={() => onClick(item)}>
      <Box className={classes.statistics}>
        <Box className={classes.progress}>
          <Progress completed={notActiveTests} value={activeTests} total={tests} />
        </Box>
        <Typography className={classes.tests}>{tests} tests</Typography>
      </Box>
      <Typography className={classes.title}>{item.name}</Typography>
    </Box>
  );
}
