import { useCallback, useEffect, useState } from 'react';
import { TextField, MenuItem } from '@mui/material';
import { FilterTypeProps } from '../interfaces';

export default function SelectFilter({ onChange, source, menuItems }: FilterTypeProps) {
  const { name, title } = source;
  const [value, setValue] = useState(menuItems?.length ? menuItems[0].value : undefined);

  const filterFunction = useCallback(
    item => {
      if (!item || item[name] === undefined) {
        return false;
      }

      return value === item[name];
    },
    [value, name],
  );

  useEffect(() => {
    onChange(filterFunction, `${value}`, name, title);
  }, [filterFunction, onChange, name, value, title]);

  return (
    <div>
      <TextField select fullWidth value={value} onChange={e => setValue(e.target.value)}>
        {menuItems?.map(m => (
          <MenuItem value={m.value} key={m.text}>
            {m.text}
          </MenuItem>
        ))}
      </TextField>
    </div>
  );
}
