import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Grid, Link, Typography } from '@mui/material';
import { formatDateWithTime } from '@sightgain/core/dates';
import { ModalStore, useModal, useModalStore } from '../../../../../reusables/BetterModal';
import DownloadLink from '../../../../../reusables/DownloadLink';
import { assessmentsService } from '../../../../../services';
import { FileInfo } from '../../../../../services/interfaces';
import EvidenceUpload from '../components/Upload';
import { Objective } from '../interface';

interface EvidencesProps {
  objective: Objective;
  isReadonly?: boolean;
}

const downloadFn = (file: FileInfo) => async () => (await assessmentsService.evidence(file.id)).contents;

export default function Evidences({ objective, isReadonly }: EvidencesProps) {
  const createModal = useModalStore((s: ModalStore) => s.createModal);
  const UploadEvidenceModal = createModal('uploadEvidence');
  const { openModal } = useModal('uploadEvidence');

  return (
    <>
      <Grid container direction="column" spacing={2}>
        <Grid item container justifyContent="end">
          <Button
            disabled={isReadonly}
            component={Link}
            onClick={openModal}
            color="primary"
            startIcon={<FontAwesomeIcon icon={['fal', 'upload']} />}
          >
            Upload Evidence
          </Button>
        </Grid>
        <Grid item>
          <Grid container direction="column">
            {!objective.evidence.length && (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Typography variant="textStyle5">No evidence provided</Typography>
              </div>
            )}
            {objective.evidence.map(evidence => (
              <Grid item key={evidence.id} bgcolor="darkBlue3.main" padding={2} margin={1}>
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <DownloadLink
                      fileName={evidence.fileName}
                      mimeType={evidence.mimeType}
                      download={downloadFn(evidence)}
                    >
                      <span style={{ marginRight: '20px' }}>
                        <FontAwesomeIcon icon={['fal', 'file']} size="lg" />
                      </span>
                      {evidence.fileName}
                    </DownloadLink>
                  </Grid>
                  <Grid item>
                    <Typography color="grey2.main">{formatDateWithTime(evidence.createdAt)}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      <UploadEvidenceModal>
        <EvidenceUpload objective={objective} />
      </UploadEvidenceModal>
    </>
  );
}
