import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Chip, Grid, Link, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FrameworkItemWithScores } from '../../../../services/interfaces';
import { useAssessmentContext } from '../../components/AssessmentContext';
import { CAPABILITY_MATURITY_TABS } from '../capabilityMaturity';
import CapabilityDetails from '../capabilityMaturity/details';
import { MaturityLevel } from '../capabilityMaturity/interface';

const useStyles = makeStyles(() => ({
  container: {
    padding: '10px 20px',
    position: 'relative',
    width: '100%',
  },
  wrapper: {
    display: 'flex',
    paddingLeft: 1,
    paddingRight: 1,
    flexDirection: 'row',
    height: '100dvh',
    width: '100%',
  },
}));

interface CapabilityMaturityModalProps {
  item: FrameworkItemWithScores | null;
  onClose: () => void;
}

export default function CapabilityMaturityModal({ item, onClose }: CapabilityMaturityModalProps) {
  const { assessment, assessmentFrameworkId, currentOverallMaturityLevel } = useAssessmentContext();

  const [currentMaturityLevel, setCurrentMaturityLevel] = useState<MaturityLevel>(
    currentOverallMaturityLevel as MaturityLevel,
  );

  const classes = useStyles();

  const assessmentFramework = assessment.frameworks.find(fw => fw.id === assessmentFrameworkId)!;

  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <Grid container direction="column" gap={2}>
          <Grid item>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Button
                  component={Link}
                  onClick={onClose}
                  color="primary"
                  startIcon={<FontAwesomeIcon icon={['fal', 'long-arrow-left']} />}
                >
                  Back to Assessments
                </Button>
              </Grid>
              <Grid item alignContent="center">
                <Grid container gap={2}>
                  <Grid item alignContent="center">
                    <Typography variant="textStyle1">Maturity Level:</Typography>
                  </Grid>
                  {CAPABILITY_MATURITY_TABS.map(tab => {
                    return (
                      <Grid item key={`capability-maturity-tab-${tab.value}`}>
                        <Chip
                          variant={currentMaturityLevel === tab.value ? 'filled' : 'outlined'}
                          label={tab.label}
                          onClick={() => setCurrentMaturityLevel(tab.value as MaturityLevel)}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <CapabilityDetails
              framework={assessmentFramework}
              item={item as FrameworkItemWithScores}
              maturityLevel={[currentMaturityLevel, setCurrentMaturityLevel]}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
