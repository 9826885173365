import clsx from 'clsx';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { round } from '@sightgain/core/calculations';
import appStore from '../../../../AppStore';
import { useAbort } from '../../../../effects';
import { settingsService } from '../../../services';
import { WeightsAndThresholds } from '../../../services/interfaces';
import CustomCalculation from './CustomCalculation';
import { CustomValue } from './interfaces';

const useStyles = makeStyles(() => ({
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: 20,
    marginTop: 20,
    marginBottom: 20,
  },
  subTitle: {
    marginTop: 20,
    marginBottom: 20,
  },
  actions: {
    justifyContent: 'flex-end',
  },
  btn: {
    border: 'solid 0.5px #ffff',
    backgroundColor: '#21bee3',
    color: '#fff',
    fontSize: 14,
    borderRadius: 2,
  },
}));

const defaultWeights: WeightsAndThresholds = {
  likelihood: { technologyWeight: 0.7, noResponseWeight: 0.2, threatScoreWeight: 0.1 },
  riskPosture: { attackScoreWeight: 0.005, passFailScoreThreshold: 0.25 },
  ipWeight: 0.75,
  piiWeight: 0.25,
  severityThreshold: 0.75,
  testResultScore: { eventScoreWeight: 0.7, noiseScoreWeight: 0.2, responseScoreWeight: 0.1 },
  threatPreparedness: {
    unknownTechniques: 0.9,
    preventionProbability: 0.85,
    detectionProbability: 0.4,
    alertProbability: 0.2,
    responseProbability: 0.4,
  },
};

export default function CustomCalculations() {
  const classes = useStyles();
  const [weights, setWeights] = useState<WeightsAndThresholds>(defaultWeights);

  useAbort(() => settingsService.weightsAndThresholds(), setWeights);

  const handleSave = async () => {
    appStore.beginLoading();
    try {
      await settingsService.saveWeightsAndThresholds(weights);
      appStore.success('weights and thresholds updated successfully');
    } catch (err) {
      console.log(err);
      appStore.error(err);
    }
    appStore.endLoading();
  };

  const createValues = (weightsName: keyof WeightsAndThresholds): CustomValue[] => {
    const value = weights[weightsName];
    const defaultValue = defaultWeights[weightsName];
    type T = typeof value;

    if (typeof value === 'number') {
      return [
        {
          name: weightsName,
          value: round(value * 100, 2),
          default: round((defaultValue as number) * 100, 2),
        },
      ];
    }

    return Object.keys(value).map(key => ({
      name: key,
      value: round(value[key as keyof T] * 100, 2),
      default: round(defaultValue[key as keyof T] * 100, 2),
    }));
  };

  const handleOnChange = (name: keyof WeightsAndThresholds) => (value: any) =>
    setWeights({ ...weights, [name]: value } as WeightsAndThresholds);

  const handleOnReset = (name: keyof WeightsAndThresholds) => () =>
    setWeights({ ...weights, [name]: defaultWeights[name] });

  return (
    <div>
      <Typography variant="h3">Custom Calculations</Typography>
      <Typography className={classes.subTitle} variant="h4">
        Risk Scoring
      </Typography>
      <div className={classes.row}>
        <CustomCalculation
          title="Likelihood Weight"
          onChange={handleOnChange('likelihood')}
          onReset={handleOnReset('likelihood')}
          values={createValues('likelihood')}
          infoUrl="https://docs.sightgain.live/pages/440533040/Custom+Calculations#Likelihood-Weight"
        />
        <CustomCalculation
          title="Risk Posture"
          onChange={handleOnChange('riskPosture')}
          onReset={handleOnReset('riskPosture')}
          values={createValues('riskPosture')}
          infoUrl="https://docs.sightgain.live/pages/440533040/Custom+Calculations#Risk-Posture"
        />
      </div>
      <div className={classes.row}>
        <CustomCalculation
          title="IP Exposure Weight"
          onChange={handleOnChange('ipWeight')}
          onReset={handleOnReset('ipWeight')}
          values={createValues('ipWeight')}
          infoUrl="https://docs.sightgain.live/pages/440533040/Custom+Calculations#IP-Exposure-Weight"
        />
        <CustomCalculation
          title="PII Exposure Weight"
          onChange={handleOnChange('piiWeight')}
          onReset={handleOnReset('piiWeight')}
          values={createValues('piiWeight')}
          infoUrl="https://docs.sightgain.live/pages/440533040/Custom+Calculations#PII-Exposure-Weight"
        />
      </div>
      <div className={classes.row}>
        <CustomCalculation
          title="High Severity"
          onChange={handleOnChange('severityThreshold')}
          onReset={handleOnReset('severityThreshold')}
          values={createValues('severityThreshold')}
          infoUrl="https://docs.sightgain.live/pages/440533040/Custom+Calculations#High-Severity"
        />
        <CustomCalculation
          title="Test Result Score Weights"
          onChange={handleOnChange('testResultScore')}
          onReset={handleOnReset('testResultScore')}
          values={createValues('testResultScore')}
          infoUrl="https://docs.sightgain.live/pages/440533040/Custom+Calculations#Test-Result-Score-Weights"
        />
      </div>
      <div className={classes.row}>
        <CustomCalculation
          title="Threat Preparedness"
          onChange={handleOnChange('threatPreparedness')}
          onReset={handleOnReset('threatPreparedness')}
          values={createValues('threatPreparedness')}
          infoUrl="https://docs.sightgain.live/pages/440533040/Custom+Calculations#Threat-Preparedness"
          fullWidth
        />
      </div>
      <div className={clsx(classes.row, classes.actions)}>
        <Button
          className={clsx(classes.btn)}
          onClick={handleSave}
          variant="contained"
          endIcon={<FontAwesomeIcon size="sm" icon={['fal', 'clock']} />}
        >
          Save Changes
        </Button>
      </div>
    </div>
  );
}
