import { useState } from 'react';
import { Typography } from '@mui/material';
import { useAbort } from '../../../../effects';
import { TemperatureComponent, TemperatureSlider } from '../../../reusables/TemperatureSlider';
import { settingsService } from '../../../services';
import Wrapper from './Wrapper';

const settings = ['frameworkScoringThresholds', 'assessorThreshold', 'compoundWeights'];

export default function FrameworkScoringThresholds() {
  const [frameworkScoringThresholds, setFrameworkScoringThresholds] = useState<number[]>([25, 50, 75]);
  const [assessorThreshold, setAssessorThreshold] = useState<[number]>([80]);
  const [compoundWeights, setCompoundWeights] = useState<[number]>([50]);
  const [clean, setClean] = useState(true);

  useAbort(
    async () => settingsService.list(settings),
    result => {
      if (!result) {
        return;
      }

      const fwThresholds = result.find(setting => setting.name === 'frameworkScoringThresholds');
      if (fwThresholds) {
        setFrameworkScoringThresholds(JSON.parse(fwThresholds.value));
      }

      const assessor = result.find(setting => setting.name === 'assessorThreshold');
      if (assessor) {
        setAssessorThreshold(JSON.parse(assessor.value));
      }

      const compound = result.find(setting => setting.name === 'compoundWeights');
      if (compound) {
        setCompoundWeights(JSON.parse(compound.value));
      }
    },
    [],
  );

  const handleUpdate = (name: string, value: number[]) => {
    switch (name) {
      case 'frameworkScoringThresholds':
        setFrameworkScoringThresholds(value);
        break;
      case 'assessorThreshold':
        setAssessorThreshold(value as [number]);
        break;
      case 'compoundWeights':
        setCompoundWeights(value as [number]);
        break;
    }

    setClean(false);
  };

  const handleOnSave = async () => {
    await Promise.all([
      settingsService.save({
        name: 'frameworkScoringThresholds',
        value: JSON.stringify(frameworkScoringThresholds),
      }),
      settingsService.save({ name: 'assessorThreshold', value: JSON.stringify(assessorThreshold) }),
      settingsService.save({ name: 'compoundWeights', value: JSON.stringify(compoundWeights) }),
    ]);
    setClean(true);
  };

  const handleOnReset = () => {
    setFrameworkScoringThresholds([25, 50, 75]);
    setAssessorThreshold([80]);
    setCompoundWeights([50]);
    setClean(false);
  };

  return (
    <Wrapper title="Framework Scoring Threshold Settings" onSave={handleOnSave} onReset={handleOnReset} clean={clean}>
      <div>
        <Typography variant="body1">Framework Scoring Levels</Typography>
        <TemperatureSlider
          key="framework-slider"
          valueLabelDisplay="on"
          component={TemperatureComponent}
          value={frameworkScoringThresholds}
          onChange={(_: any, value: number[]) => handleUpdate('frameworkScoringThresholds', value)}
        />
      </div>
      <div>
        <Typography variant="body1">Assessor Pass / Fail Value</Typography>
        <TemperatureSlider
          key="assessor-slider"
          valueLabelDisplay="on"
          component={TemperatureComponent}
          value={assessorThreshold}
          onChange={(_: any, value: number[]) => handleUpdate('assessorThreshold', value)}
        />
      </div>
      <div>
        <Typography variant="body1">Compound Scoring - Assessor Weight</Typography>
        <TemperatureSlider
          key="assessor-slider"
          valueLabelDisplay="on"
          component={TemperatureComponent}
          value={compoundWeights}
          onChange={(_: any, value: number[]) => handleUpdate('compoundWeights', value)}
        />
      </div>
    </Wrapper>
  );
}
