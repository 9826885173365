import { gql } from '@apollo/client';
import { toDates } from '@sightgain/core/dates';
import { Assessor, AssessorImport, AssessorInput } from './interfaces';
import ServiceBase from './ServiceBase';

const typeAssessor = `
  {
    id
    createdAt
    comments
    assessor {
      id
      email
      name
    }
    score
    value
    tacticId
  }
`;

const CACHE_UPDATE_QUERY = gql`
query Assessors($tacticId: String!) {
  assessors(tacticId: $tacticId) ${typeAssessor}
}
`;

class AssessorsService extends ServiceBase {
  constructor() {
    super();
    this.addMiddleWare('after', data => toDates(data, ['createdAt']));
  }

  /**
   * Creates a new assessor input
   */
  async addInput(input: AssessorInput, fields = typeAssessor): Promise<Assessor> {
    const query = gql`
      mutation AddAssessorInput($input: AssessorInput!) {
        addAssessorInput(input: $input) ${fields}
      }
    `;

    const { addAssessorInput } = await this.graphql(query, { input });

    this.appendGQLCache(CACHE_UPDATE_QUERY, { tacticId: input.tacticId }, 'assessors', {
      ...addAssessorInput,
      createdAt: addAssessorInput.createdAt.getTime(),
    });

    return addAssessorInput;
  }

  async import(input: AssessorImport, fields = typeAssessor): Promise<Assessor[]> {
    const query = gql`
      mutation ImportAssessorInput($input: AssessorImport!) {
        importAssessorInput(input: $input) ${fields}
      }
    `;

    const { importAssessorInput } = await this.graphql(query, { input });

    importAssessorInput.forEach((assessor: Assessor) => {
      this.appendGQLCache(CACHE_UPDATE_QUERY, { tacticId: assessor.tacticId }, 'assessors', {
        ...assessor,
        createdAt: assessor.createdAt.getTime(),
      });
    });

    return importAssessorInput;
  }

  /**
   * Returns all assessor input for a specific tactic id
   */
  async find(tacticId: string, fields = typeAssessor): Promise<Assessor[]> {
    const query = gql`
      query Assessors($tacticId: String!) {
        assessors(tacticId: $tacticId) ${fields}
      }
    `;

    const { assessors } = await this.graphql(query, { tacticId });
    return assessors;
  }

  /**
   * Returns the most recent assessor input for each tactic id given
   */
  async list(tacticIds: string[], fields = typeAssessor, refresh = false): Promise<Assessor[]> {
    const query = gql`
      query AssessorResults($tacticIds: [String!]!) {
        assessorResults(tacticIds: $tacticIds) ${fields}
      }
    `;

    // a forced refresh option for framework scoring
    const fetchPolicy = refresh ? 'no-cache' : undefined;

    const { assessorResults } = await this.graphql(query, { tacticIds }, fetchPolicy);
    return assessorResults;
  }
}

const assessorsService = new AssessorsService();
export default assessorsService;
