import { format } from 'date-fns';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { unique } from '@sightgain/core';
import appStore from '../../../../AppStore';
import { useAbort } from '../../../../effects';
import { ModalStore, useModalStore } from '../../../reusables/BetterModal';
import { useSetting, useThresholdColors } from '../../../reusables/useSetting';
import { assessmentsService, frameworkScoringService, frameworksService, threatGroupsService } from '../../../services';
import {
  CreateThreatIntelInput,
  FrameworkItemWithScores,
  ScoredFramework,
  ScoredFrameworkItem,
  SecurityZone,
  TacticScore,
  ThreatGroup,
} from '../../../services/interfaces';
import userPreferencesService, { useUserPreference } from '../../../services/UserPreferencesService';
import { useAssessmentContext } from '../components/AssessmentContext';
import Assessments from '../components/Assessments';
import FiltersAndGraphs from '../components/FiltersAndGraphs';
import Title from '../components/Title';
import CompareControls from './CompareControls';
import { FrameworkContext } from './FrameworkContext';
import FrameworkControls from './FrameworkControls';
import FrameworkSummary from './FrameworkSummary';
import FrameworkWrapper from './FrameworkWrapper';
import {
  AggregationMethod,
  FrameworkContextInitialState,
  FrameworkListItem,
  FrameworkScoringSettings,
  FrameworkType,
  PageTab,
  TacticCompareModalParital,
  TileDetailProps,
} from './interfaces';
import CapabilityMaturityModal from './modals/CapabilityMaturityModal';
import CompareModal from './modals/CompareModal';
import TacticCompareModal from './modals/TacticCompareModal';
import ThreatGroupModal from './modals/ThreatGroupModal';
import ThreatScoreModal, { ThreatScoreModalProperties } from './modals/ThreatScoreModal';
import EvaluationTests from './modals/TileDetail/EvaluationTests';
import TileDetail from './modals/TileDetail/TileDetail';
import { ConfigValidationItem } from './configValidation/interfaces';

const defaultSecurityZones: SecurityZone[] = [{ id: -1, name: 'All' }];

const defaultFramework: FrameworkListItem = {
  id: '',
  name: '',
  title: '',
  version: '',
};

function sortFrameworks(a: FrameworkListItem, b: FrameworkListItem) {
  if (a.name === b.name) {
    return isNewerVersion(a.version, b.version) ? -1 : 1;
  }

  // mitre is always first
  if (a.name === 'mitre') {
    return -1;
  }

  if (b.name === 'mitre') {
    return 1;
  }

  return a.name < b.name ? -1 : 1;
}

function isNewerVersion(newVer: string, oldVer: string) {
  const oldParts = oldVer.split('.');
  const newParts = newVer.split('.');
  for (let i = 0; i < newParts.length; i++) {
    const a = ~~newParts[i];
    const b = ~~oldParts[i];
    if (a > b) return true;
    if (a < b) return false;
  }
  return false;
}

// what the heck should this be named? Previous was 'RawScoring'
export default function FrameworkScoring() {
  // application settings
  const thresholds = useSetting<[number, number, number]>('frameworkScoringThresholds', [25, 50, 75]);
  const assessorThreshold = useSetting<[number]>('assessorThreshold', [80])[0];
  const compoundWeights = useSetting<[number]>('compoundWeights', [50])[0];
  const ignoredEvaluations = useSetting<string[]>('ignoredEvaluations', []);
  const thresholdColors = useThresholdColors();

  // get assessment
  const { assessment, setAssessmentFrameworkId, isReadonly } = useAssessmentContext();

  // layout settings
  const [activeTab, setActiveTab] = useState(PageTab.RISK_POSTURE);

  // user preferences
  const [currFrameworkId, setCurrentFrameworkId, setInitialCurrentFrameworkId] = useUserPreference(
    'appFrameworkScoringCurrentFramework',
    'UNDEFINED',
    false,
  );
  const [settings, setSettings, setInitialSettings] = useUserPreference<FrameworkScoringSettings>(
    'appFrameworkScoringSettings',
    {
      aggregationMethod: AggregationMethod.AVERAGE,
      threatGroup: '',
      addButton: true,
    },
    false,
  );

  // drops downs
  const [frameworks, setFrameworks] = useState<FrameworkListItem[]>([defaultFramework]);
  const [threatGroups, setThreatGroups] = useState<ThreatGroup[]>([]);
  const [securityZones, setSecurityZones] = useState([-1]);
  const [observedZones, setObservedZones] = useState<SecurityZone[]>(defaultSecurityZones);

  // scored frameworks
  const [scoredFramework, setScoredFramework] = useState<ScoredFramework | null>(null);
  const [scoredFrameworkDay1, setScoredFrameworkDay1] = useState<ScoredFramework | null>(null);
  const [scoredFrameworkDay2, setScoredFrameworkDay2] = useState<ScoredFramework | null>(null);
  const [scoredFrameworkDays, setScoredFrameworkDays] = useState<{ day1: string; day2: string }[]>([]);

  // modal properties
  const [modalTileDetailProperties, setModalTileDetailProperties] = useState<TileDetailProps>({ open: false });
  const [modalThreatScoreProperties, setModalThreatScoreProperties] = useState({
    open: false,
  } as ThreatScoreModalProperties);
  const [modalThreatGroupProperties, setModalThreatGroupProperties] = useState({ open: false });
  const [modalCompareProperties, setModalCompareProperties] = useState({ open: false, day1: '', day2: '' });
  const [modalTacticCompareProperties, setModalTacticCompareProperties] = useState<TacticCompareModalParital>({
    open: false,
  });
  const [modalCapabilityMaturityProperties, setModalCapabilityMaturityProperties] = useState<{
    open: boolean;
    item: FrameworkItemWithScores | null;
  }>({ open: false, item: null });

  // update current framework when currFrameworkId changes (preference or dropdown toggle)
  const currentFramework = useMemo(() => {
    const sortedAssessmentFrameworks = assessment.frameworks.sort(sortFrameworks);
    return frameworks.find(f => f.id === currFrameworkId) ?? sortedAssessmentFrameworks[0];
  }, [assessment, frameworks, currFrameworkId]);

  // update current framework when assessment changes
  useEffect(() => {
    const sortedAssessmentFrameworks = assessment.frameworks.sort(sortFrameworks);
    setCurrentFrameworkId(sortedAssessmentFrameworks[0].id);
    setAssessmentFrameworkId(sortedAssessmentFrameworks[0].id);
  }, [assessment, setCurrentFrameworkId, setAssessmentFrameworkId]);

  // update capability maturity modal (scores) if there is a change to assessment
  useEffect(() => {
    const { open, item } = modalCapabilityMaturityProperties;
    if (open && !!item) {
      const newItem = assessment.frameworks[0].groups.flatMap(x => x.items).find(y => y.id === item.id);
      if (newItem) {
        setModalCapabilityMaturityProperties({ open: true, item: newItem as FrameworkItemWithScores });
      }
    }
  }, [assessment]);

  /**
   * downloads the initial data
   */
  useAbort(
    async () => {
      return await Promise.all([
        frameworksService.list('{ id name title version }') as Promise<FrameworkListItem[]>,
        threatGroupsService.list('{ id name }'),
        userPreferencesService.find('appFrameworkScoringCurrentFramework'),
        userPreferencesService.find('appFrameworkScoringSettings'),
      ]);
    },
    ([frameworks, threatGroups, currFrameworkPref, currSettingsPref]) => {
      setFrameworks(frameworks.sort(sortFrameworks));

      setInitialCurrentFrameworkId(currFrameworkPref);
      setInitialSettings(currSettingsPref);

      if (!currFrameworkPref.value) {
        setCurrentFrameworkId(currentFramework.id);
      }

      // ensure a valid threat group is selected
      if (!currSettingsPref.value) {
        // set the initial value if one doesn't exist
        const validSettings = {
          ...settings,
          threatGroup: threatGroups[0].id,
        };
        setSettings(validSettings);
      } else {
        // validate the existing threat group value
        const retrievedSettings: FrameworkScoringSettings = JSON.parse(currSettingsPref.value);
        if (!(retrievedSettings.threatGroup && threatGroups.find(tg => tg.id === retrievedSettings.threatGroup))) {
          setSettings({ ...retrievedSettings, threatGroup: threatGroups[0].id });
        }
      }

      setThreatGroups(threatGroups);
    },
    [],
  );

  const zones = securityZones.join(',');
  const isComparison = scoredFrameworkDay1 && scoredFrameworkDay2;

  const refreshScoredItem = useCallback(
    async (identifier: string) => {
      appStore.beginLoading();
      try {
        const updated = await frameworkScoringService.refreshItem(
          {
            framework: currentFramework.name,
            threatGroupId: settings.threatGroup,
            version: currentFramework.version,
            aggregationMethod: settings.aggregationMethod,
            zones: zones
              .split(',')
              .filter(x => x !== '-1')
              .map(x => +x),
            assessmentId: assessment.id,
            dateRange: {
              from: assessment.startDate,
              to: assessment.endDate,
            },
          },
          identifier,
        );

        setScoredFramework(prev => {
          if (!prev) {
            return null;
          }

          return {
            ...prev,
            groups: prev.groups.map(group => {
              const itemIndex = group.items.findIndex(item => item.identifier === identifier);
              if (itemIndex >= 0) {
                group.items.splice(itemIndex, 1, updated);
              }

              return group;
            }),
          };
        });
      } catch (err) {
        appStore.error(err);
      }

      appStore.endLoading();
    },
    [currentFramework, settings.threatGroup, settings.aggregationMethod, zones, assessment],
  );

  const refreshScoring = useCallback(async () => {
    appStore.beginLoading();
    try {
      const frameworkScoring = frameworkScoringService.refreshScoring({
        framework: currentFramework.name,
        threatGroupId: settings.threatGroup,
        version: currentFramework.version,
        aggregationMethod: settings.aggregationMethod,
        zones: zones
          .split(',')
          .filter(x => x !== '-1')
          .map(x => +x),
        assessmentId: assessment.id,
        dateRange: {
          from: assessment.startDate,
          to: assessment.endDate,
        },
      });

      const assessmentScoring = assessmentsService.refreshScoring(assessment.id);

      const [scored] = await Promise.all([frameworkScoring, assessmentScoring]);
      setScoredFramework(scored);
      setObservedZones(defaultSecurityZones.concat(scored.observedZones));
    } catch (err) {
      appStore.error(err);
    }
    appStore.endLoading();
  }, [
    currentFramework.name,
    currentFramework.version,
    settings.aggregationMethod,
    settings.threatGroup,
    zones,
    assessment,
  ]);

  // rescore the framework
  useAbort(
    async () => {
      if (!(currentFramework && settings.threatGroup)) {
        return;
      }

      const input = {
        framework: currentFramework.name,
        threatGroupId: settings.threatGroup,
        version: currentFramework.version,
        aggregationMethod: settings.aggregationMethod,
        zones: zones
          .split(',')
          .filter(x => x !== '-1')
          .map(x => +x),
        assessmentId: assessment.id,
        dateRange: {
          from: assessment.startDate,
          to: assessment.endDate,
        },
      };

      if (isComparison) {
        const day1 = await frameworkScoringService.frameworkScoring({
          ...input,
          dateRange: {
            from: assessment.startDate,
            to: (scoredFrameworkDay1.toDate && new Date(scoredFrameworkDay1.toDate)) || assessment.endDate,
          },
        });
        const day2 = await frameworkScoringService.frameworkScoring({
          ...input,
          dateRange: {
            from: assessment.startDate,
            to: (scoredFrameworkDay2.toDate && new Date(scoredFrameworkDay2.toDate)) || assessment.endDate,
          },
        });

        return [day1, day2];
      }

      return Promise.all([frameworkScoringService.frameworkScoring(input)]);
    },
    scored => {
      if (scored?.length === 1) {
        setScoredFramework(scored[0]);
        scored[0].toDate = format(new Date(), 'yyyy-MM-dd');
        setObservedZones(defaultSecurityZones.concat(scored[0].observedZones));
      } else if (scored?.length === 2) {
        setScoredFrameworkDay1(scored[0]);
        setScoredFrameworkDay2(scored[1]);
        const zones = [
          ...new Set(
            [...scored[0].observedZones, ...scored[1].observedZones].map(obj =>
              JSON.stringify({ id: obj.id, name: obj.name }),
            ),
          ),
        ].map(strObj => JSON.parse(strObj));
        setObservedZones(defaultSecurityZones.concat(zones));
      }
    },
    [currentFramework, settings.threatGroup, settings.aggregationMethod, zones],
  );

  const tabs = useMemo(() => {
    const activeFrameworkType = scoredFrameworkDay1?.type ?? scoredFramework?.type;
    const activeFrameworkName = scoredFrameworkDay1?.name ?? scoredFramework?.name;

    const result: PageTab[] = [];
    result.push(PageTab.CONTROL_EFFICACY);
    // TODO: enable when config validation is complete
    // result.push(PageTab.CONFIG_VALIDATION);

    if (activeFrameworkName === 'zerotrust') {
      result.push(PageTab.CAPABILITY_MATURITY);
    }

    result.push(PageTab.ASSESSOR_SCORE, PageTab.COMPOUND);

    if (activeFrameworkType === FrameworkType.ATTACK) {
      // add risk as the first tab
      result.unshift(PageTab.RISK_POSTURE);
      // add threat intelligence as the last tab
      result.push(PageTab.THREAT_INTELLIGENCE);
    }

    // if you switch frameworks and the tab is unavailable, go to the first one
    if (!result.includes(activeTab)) {
      setActiveTab(result[0]);
    }
    return result;
  }, [scoredFramework, scoredFrameworkDay1, activeTab]);

  const handleControlOnClick = (
    control: ScoredFrameworkItem | FrameworkItemWithScores | ConfigValidationItem,
    columnName?: string,
  ) => {
    if (activeTab === PageTab.RISK_POSTURE && scoredFrameworkDay1 && scoredFrameworkDay2) {
      setModalTacticCompareProperties({ tactic: control as ScoredFrameworkItem, columnName, open: true });
      return;
    }

    if (activeTab === PageTab.THREAT_INTELLIGENCE) {
      const frameworkControl = control as ScoredFrameworkItem | FrameworkItemWithScores;

      setModalThreatScoreProperties({
        groupId: settings.threatGroup,
        title: control.name,
        identifier: frameworkControl.identifier,
        open: true,
      });
      return;
    }

    if (activeTab === PageTab.CAPABILITY_MATURITY) {
      setModalCapabilityMaturityProperties({ open: true, item: control as FrameworkItemWithScores });
      return;
    }

    if (activeTab === PageTab.CONFIG_VALIDATION) {
      const configControl = control as ConfigValidationItem;

      const modalProps = {
        startTab: 4,
        control: configControl,
        open: true,
        isTestTab: true,
        onClose: () => setModalTileDetailProperties({ open: false }),
      };

      setModalTileDetailProperties(modalProps);
      return;
    }

    try {
      appStore.beginLoading();
      const { identifier, name, testIds } = control as ScoredFrameworkItem;
      const modalProps = {
        isReadonly,
        startTab: activeTab === PageTab.ASSESSOR_SCORE ? 2 : 0,
        tacticId: identifier,
        open: true,
        title: name,
        testIds: testIds.filter(x => x.identifier === identifier).map(y => y.test),
        onClose: async (rescore = false) => {
          if (rescore) {
            await refreshScoredItem(identifier);
          }
          setModalTileDetailProperties(props => ({ ...props, open: false }));
        },
      };

      setModalTileDetailProperties(modalProps);
    } catch (err) {
      appStore.error(err);
    }

    appStore.endLoading();
  };

  /**
   * creates a new threat intel
   */
  const createThreatIntel = async (input: CreateThreatIntelInput) => {
    appStore.beginLoading();

    try {
      await threatGroupsService.createIntel(input);
      await refreshScoredItem(input.identifier);
    } catch (err) {
      appStore.error(err);
    }

    appStore.endLoading();
  };

  const backgroundColor = useCallback(
    ({ score, isValid }: TacticScore = { score: 0, isValid: false }, reversed = false) => {
      const [low, med, high] = thresholds;
      const { none, red, orange, yellow, green } = thresholdColors;

      if (!isValid) {
        return none;
      }

      const colors = [red, orange, yellow, green];
      if (reversed) {
        colors.reverse();
      }

      if ((score ?? 0) < low) {
        return colors[0];
      }

      if ((score ?? 0) < med) {
        return colors[1];
      }

      if ((score ?? 0) < high) {
        return colors[2];
      }

      return colors[3];
    },
    [thresholds, thresholdColors],
  );

  const initialState: FrameworkContextInitialState = {
    scoredFramework,
    scoredFrameworkDay1,
    scoredFrameworkDay2,
    scoredFrameworkDays,
    threatGroups,
    settings,
    thresholdColors,
    assessorThreshold,
    compoundWeights,
    createThreatIntel,
    currentFramework,
    backgroundColor,
    handleControlOnClick,
    setSettings,
    setThreatGroups,
    setModalThreatGroupProperties,
    setModalThreatScoreProperties,
    setModalCompareProperties,
    setScoredFrameworkDay1,
    setScoredFrameworkDay2,
    setScoredFrameworkDays,
    setSecurityZones,
    securityZones,
    observedZones,
    setObservedZones,
  };

  const handleCompareOpen = () => {
    setModalCompareProperties({ ...modalCompareProperties, open: true });
  };

  const handleCompare = async (day1: string, day2: string) => {
    appStore.beginLoading();
    try {
      const config = {
        framework: currentFramework.name,
        threatGroupId: settings.threatGroup,
        version: currentFramework.version,
        aggregationMethod: settings.aggregationMethod,
        zones: [],
        assessmentId: assessment.id,
      };

      const [newScoredFrameworkDay1, newScoredFrameworkDay2] = await Promise.all([
        frameworkScoringService.frameworkScoring({
          ...config,
          dateRange: {
            from: assessment.startDate,
            to: new Date(day1),
          },
        }),
        frameworkScoringService.frameworkScoring({
          ...config,
          dateRange: {
            from: assessment.startDate,
            to: new Date(day2),
          },
        }),
      ]);

      // Make it easier to get comparison date in various functions
      newScoredFrameworkDay1.toDate = day1;
      newScoredFrameworkDay2.toDate = day2;

      setScoredFrameworkDay1(newScoredFrameworkDay1);
      setScoredFrameworkDay2(newScoredFrameworkDay2);

      const newZones = [
        ...new Set(
          [newScoredFrameworkDay1.observedZones, newScoredFrameworkDay2.observedZones]
            // remove possible null values
            .filter(z => z)
            // concatenate the arrays
            .flat()
            .map(obj => JSON.stringify({ id: obj.id, name: obj.name })),
        ),
      ].map(strObj => JSON.parse(strObj));
      setObservedZones(defaultSecurityZones.concat(newZones));
      setSecurityZones([-1]);
    } catch (err) {
      appStore.error(err);
    }
    appStore.endLoading();
  };

  const createModal = useModalStore((s: ModalStore) => s.createModal);
  const EvaluationTestResultsModal = createModal('evaluationTestResults');

  // get the list of testIds to look for
  const testIds = useMemo(() => {
    if (scoredFramework) {
      return unique(
        scoredFramework.groups.flatMap(group => group.items.flatMap(item => item.testIds.map(x => x.test))),
      );
    }
  }, [scoredFramework]);

  if (!currentFramework) {
    return <></>;
  }

  const hideOtherContent = modalTileDetailProperties.open || modalCapabilityMaturityProperties.open;

  return (
    <FrameworkContext initialState={initialState}>
      {modalTileDetailProperties.open && (
        <TileDetail ignoredEvaluations={ignoredEvaluations} {...modalTileDetailProperties} />
      )}
      {modalCapabilityMaturityProperties.open && (
        <CapabilityMaturityModal
          item={modalCapabilityMaturityProperties.item}
          onClose={() => setModalCapabilityMaturityProperties({ open: false, item: null })}
        />
      )}
      <div style={hideOtherContent ? { height: 0, overflow: 'hidden' } : {}}>
        <Title
          title="Assessment Scoring"
          scoredDate={!isComparison ? scoredFramework?.updatedAt : undefined}
          refresh={refreshScoring}
          testIds={testIds}
        />
        <Assessments />
        <FiltersAndGraphs>
          <FrameworkControls
            activeTab={activeTab}
            frameworks={frameworks}
            currentFrameworkValue={currentFramework}
            setCurrentFrameworkId={setCurrentFrameworkId}
          />
          {scoredFramework && <FrameworkSummary scoredFramework={scoredFramework} activeTab={activeTab} />}
        </FiltersAndGraphs>
        {scoredFramework && <CompareControls onCompareOpen={handleCompareOpen} onCompare={handleCompare} />}
        <FrameworkWrapper
          {...{
            refreshScoring,
            activeTab,
            tabs,
            backgroundColor,
            thresholdColors,
            assessorThreshold,
            compoundWeights,
            onControlClick: handleControlOnClick,
            onTabClick: setActiveTab,
            scoredFramework,
            scoredFrameworkDay1,
            scoredFrameworkDay2,
          }}
        />
        {modalThreatGroupProperties.open && <ThreatGroupModal {...modalThreatGroupProperties} />}
        {modalThreatScoreProperties.open && <ThreatScoreModal {...modalThreatScoreProperties} />}
        {modalCompareProperties.open && (
          <CompareModal {...modalCompareProperties} onCompare={handleCompare} minDate={assessment.startDate} />
        )}
        <TacticCompareModal
          {...modalTacticCompareProperties}
          onClose={() => setModalTacticCompareProperties({ open: false })}
          day1={modalCompareProperties.day1}
          day2={modalCompareProperties.day2}
        />
        <EvaluationTestResultsModal>
          <EvaluationTests />
        </EvaluationTestResultsModal>
      </div>
    </FrameworkContext>
  );
}
