import { Chip, Stack, Typography } from '@mui/material';
import { Recommendation } from '../../../Recommendations/interfaces';
import { makeStyles } from '@mui/styles';
import { FilterButton, useFilterContext } from '../../../../../reusables/Filter';
import { Filter, FilterSource } from '../../../../../reusables/Filter/interfaces';
import { useMemo } from 'react';
import { default as RecommnedationCard } from '../../../Recommendations/Tabs/Card';

const useStyles = makeStyles(() => ({
  wrapper: {
    height: 'calc(100dvh - 425px)',
    overflowY: 'scroll',
  },
  empty: {
    height: 'calc(100dvh - 500px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const typeItems = [
  { text: 'ALL', value: 'all' },
  { text: 'PEOPLE', value: 'people' },
  { text: 'PROCESS TECHNOLOGY', value: 'technology' },
  { text: 'THREAT', value: 'threat' },
];

const statusItems = [
  { text: 'OPEN', value: 'priority' },
  { text: 'RESOLVED', value: 'resolved' },
];

const sources: FilterSource[] = [
  { name: 'type', title: 'Type', filterType: 'select', menuItems: typeItems },
  { name: 'status', title: 'Status', filterType: 'select', menuItems: statusItems },
  { name: 'date', title: 'Date', filterType: 'date' },
];

interface RecommendationsProps {
  data: Recommendation[];
  onResolve?: (id: string) => void;
  onIgnore?: (id: string) => void;
}

export default function Recommendations({ data, onResolve, onIgnore }: RecommendationsProps) {
  const { filters, setFilters } = useFilterContext();

  const classes = useStyles();

  const filteredRecommendations = useMemo(() => {
    const filtered = data.filter((x: Recommendation) => !filters.some((y: Filter) => !y.filter(x)));

    return Object.values(filtered);
  }, [data, filters]);

  const handleDelete = (idx: number) => {
    setFilters(prev => prev.filter((_, index: number) => index !== idx));
  };

  const isRecommendations = !!filteredRecommendations.length;

  return (
    <Stack spacing={2} justifyContent="center">
      <div>
        <Typography variant="h4" style={{ color: '#8797AB' }}>
          Filters
        </Typography>
        <div style={{ marginTop: 8, marginLeft: 2, display: 'flex', gap: 12 }}>
          <FilterButton sources={sources} />
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: 12,
              height: 50,
              overflowY: 'auto',
            }}
          >
            {filters.map((f: Filter, idx: number) => (
              <Chip
                key={`${f.column}-${f.value}`}
                variant="outlined"
                style={{ borderRadius: 2, height: 40 }}
                label={
                  <div>
                    {f.title} {f.value}
                  </div>
                }
                onDelete={() => handleDelete(idx)}
              />
            ))}
          </div>
        </div>
      </div>
      {isRecommendations ? (
        <Stack spacing={2} className={classes.wrapper}>
          {filteredRecommendations.map(recommendation => (
            <RecommnedationCard
              key={`recommendation-card-${recommendation.date}`}
              recommendation={recommendation}
              selectable={false}
              withActions
              withStatus
              onResolve={onResolve}
              onIgnore={onIgnore}
            />
          ))}
        </Stack>
      ) : (
        <Typography variant="h3" className={classes.empty}>
          No Recommendations
        </Typography>
      )}
    </Stack>
  );
}
