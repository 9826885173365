import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ConfigTable } from './components';
import { CONFIG_VALIDATION_COLUMNS } from './mocks';
import { ConfigValidationItem } from './interfaces';

const useStyles = makeStyles(() => ({
  wrapper: {
    height: 'calc(100dvh - 30rem)',
    padding: '1rem 1.5rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    overflow: 'auto',
  },
}));

interface ConfigValidationProps {
  onScroll: (e: any) => void;
  onClick: (control: ConfigValidationItem, columnName?: string) => void;
}

export default function ConfigValidation({ onScroll, onClick }: ConfigValidationProps) {
  const classes = useStyles();

  return (
    <Box className={classes.wrapper} onScroll={onScroll}>
      <ConfigTable data={CONFIG_VALIDATION_COLUMNS} onClick={onClick} />
    </Box>
  );
}
