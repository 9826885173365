import { Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import CustomizeColors from './CustomizeColors';
import FrameworkScoringThresholds from './FrameworkScoringThresholds';
import FrameworkScoringWeights from './FrameworkScoringWeights';
import ProductBakeoff from './ProductBakeoff';
import RoiThreshold from './RoiThreshold';
import ScheduledTaskTimeout from './ScheduledTaskTimeout';
import ScoringFrequency from './ScoringFrequency';
import ThreatPreparednessThreshold from './ThreatPreparednessThreshold';

const useStyles = makeStyles(() => ({
  title: {
    marginBottom: 16,
  },
  control: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    alignItems: 'flex-start',
  },
  label: {},
  submitButton: {
    fontSize: 14,
    backgroundColor: '#21bee3',
    color: '#fff',
    borderRadius: 2,
  },
  retakes: {
    width: 160,
  },
}));

export default function Miscellaneous() {
  const classes = useStyles();
  return (
    <>
      <Typography variant="h3" className={classes.title}>
        Miscellaneous
      </Typography>

      <Stack spacing={2}>
        {/* Cron Scoring Frequency */}
        <ScoringFrequency />

        {/* Cron Timeout */}
        <ScheduledTaskTimeout />

        {/* Threshold coloring */}
        <CustomizeColors />

        {/* Product Bake-Off Settings */}
        <ProductBakeoff />

        {/* ROI Threshold Settings */}
        <RoiThreshold />

        {/* Threat Preparedness Threshold Settings */}
        <ThreatPreparednessThreshold />

        {/* Framework Scoring Threshold Settings */}
        <FrameworkScoringThresholds />

        <FrameworkScoringWeights />
      </Stack>
    </>
  );
}
