import { Divider, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import InfoIcon from '@mui/icons-material/Info';
import { RecommendationSummary } from './interfaces';
import { NumberBadge } from '../../../reusables/Badges';

const useStyles = makeStyles(() => ({
  card: {
    borderRadius: 6,
    background: '#192637',
    cursor: 'pointer',
  },
  header: {
    padding: '20px 32px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerText: {
    display: 'flex',
    alignItems: 'center',
    gap: 12,
  },
  statsContainer: {
    padding: '19px 32px',
  },
  stats: {
    display: 'flex',
    flexDirection: 'row',
  },
  desc: {
    fontWeight: 400,
  },
  selected: {
    background: '#161926',
    border: '1px solid #21BEE3',
  },
}));

interface SummaryProps {
  selected?: boolean;
  summary: RecommendationSummary;
  onSelect: (type: string) => void;
}

export default function Summary({ selected = false, summary, onSelect = () => {} }: SummaryProps) {
  const classes = useStyles();
  const { type, title, priorities, ignored, resolved, recent } = summary;

  return (
    <div
      className={`${classes.card} ${selected && classes.selected}`}
      data-testid="recommendation-summary"
      onClick={() => onSelect(type)}
    >
      <div className={classes.header}>
        <div className={classes.headerText}>
          {/* hiden for now */}
          {false && <InfoIcon style={{ color: '#303F52' }} />}
          <Typography variant="h5" style={{ textTransform: 'uppercase' }}>
            {title}
          </Typography>
        </div>
        <div className={classes.headerText}>
          {recent > 0 && (
            <NumberBadge tag color="red">
              {recent} New
            </NumberBadge>
          )}
        </div>
      </div>
      <Divider style={{ height: 2 }} />
      <div className={classes.statsContainer}>
        <div className={classes.stats}>
          <div style={{ flex: 1 }}>
            <Typography variant="h2" style={{ fontWeight: 600, color: '#F46043' }}>
              {priorities}
            </Typography>
            <Typography variant="h5" className={classes.desc}>
              Priorities
            </Typography>
          </div>
          <Divider orientation="vertical" flexItem />
          <div style={{ flex: 1, marginLeft: 24 }}>
            <Typography variant="h2" style={{ fontWeight: 600, color: '#FEBA06' }}>
              {ignored}
            </Typography>
            <Typography variant="h5" className={classes.desc}>
              Ignored
            </Typography>
          </div>
          <Divider orientation="vertical" flexItem />
          <div style={{ flex: 1, marginLeft: 24 }}>
            <Typography variant="h2" style={{ fontWeight: 600, color: '#00FFB1' }}>
              {resolved}
            </Typography>
            <Typography variant="h5" className={classes.desc}>
              Resolved
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
}
