import { Dispatch, SetStateAction } from 'react';
import { FrameworkItemWithScores, FrameworkWithScores } from '../../../../../../services/interfaces';
import Column from './column';
import Item from './item';

export default function CapabilityMaturityTable({ framework, setCurrentItem }: CapabilityMaturityTableProps) {
  return (
    <>
      {framework?.groups.map(({ items, name }) => (
        <Column
          key={name}
          items={items as FrameworkItemWithScores[]}
          name={name}
          cellComponent={(cell, key) => (
            <Item
              key={`${key}_${cell.identifier}`}
              item={cell}
              testId={`${key}-${cell.identifier}`}
              setCurrentItem={setCurrentItem}
            />
          )}
        />
      ))}
    </>
  );
}

type CapabilityMaturityTableProps = {
  framework: FrameworkWithScores;
  setCurrentItem: Dispatch<SetStateAction<FrameworkItemWithScores | null>> | ((item: FrameworkItemWithScores) => void);
};
