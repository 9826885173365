import { ChangeEvent, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { round } from '@sightgain/core/calculations';
import { truncate } from '@sightgain/core/strings';
import appStore from '../../../../../AppStore';
import { useLoading } from '../../../../../effects';
import { hasPageAccess } from '../../../../Layout/Navigation/utils';
import { Tabs, Timer } from '../../../../reusables/scl';
import { liveFireTrainingService } from '../../../../services';
import { AnalystInput, Role } from '../../../../services/interfaces';
import useReviewWatcher from '../useReviewWatcher';
import AnalystFeedbackAndInstructorScore from './AnalystFeedbackAndInstructorScore';
import { Review } from './interfaces';
import MatchedCard from './MatchedCard';
import EvaluationStats from './Stats';
import UnmatchedEvents from './UnmatchedEvents';

const resultFields = `{
  id
  status
  started
  ended
  prevented
  detected
  testId
  name
  description
  tags
  events {
    __typename
    ... on HostEvent { id createdAt type eventId recordId name ip provider channel raw }
    ... on IntegrationEvent { id createdAt type eventId srcIp srcPort destIp destPort raw }
  }
}`;

const jobFields = `
{
  id
  status
  evaluationId
  name
  description
  vendorId
  stages {
    id
    testResults ${resultFields}
  }
}
`;

const lftFields = `{
  id 
  status 
  analyst { id name email }
  feedback
  instructorScore
  analystInput { id createdAt event description matchedEvents { 
    __typename
      ... on HostEvent { id }
      ... on IntegrationEvent { id }
   }
  }
  isReleased
  started
  ended
  job ${jobFields}
  score
  eventScore
  precisionScore
  responseScore
  timePassed
}`;

const useStyles = makeStyles(() => ({
  header: {
    marginBottom: 24,
    display: 'flex',
    flexWrap: 'wrap',
    gap: 24,
    justifyContent: 'space-between',
  },
  headerLeft: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
  },
  topRow: {
    marginBottom: 17,
  },
  middleRow: {
    flexShrink: 1,
    display: 'flex',
    marginBottom: 14,
    maxWidth: '100%',
    maxHeight: 475,
  },
  bottomRow: {
    display: 'flex',
    gap: 24,
  },
  bottomRowRight: {
    flex: 2,
    display: 'flex',
    overflowX: 'auto',
    gap: 24,
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: 20,
  },
  buttonBackToEvaluations: {
    padding: 10,
    flexDirection: 'row',
  },
}));

function EvaluationReview() {
  const classes = useStyles();
  const { type, id } = useParams<{ id: string; type: 'lft' | 'job' }>();
  const review = useReviewWatcher({ type, id, fields: type === 'job' ? jobFields : lftFields }) as Review | undefined;
  const [selectedTab, setSelectedTab] = useState(0);
  const [feedback, setFeedback] = useState('');
  const [instructorScore, setInstructorScore] = useState<number | undefined>();

  const [running, setRunning] = useState(true);
  const [disableEnd, setDisableEnd] = useState(false);
  const [discarded, setDiscarded] = useState(false);
  const [feedbackDisabled, setFeedbackDisabled] = useState(false);
  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    if (!review) {
      return;
    }

    setFeedback(review?.feedback ?? '');
    setInstructorScore(review?.instructorScore);
  }, [review?.id]);

  useLoading(!review);

  if (!review) {
    // wait for the data to be loaded
    return <></>;
  }

  const stopScenario = async () => {
    await liveFireTrainingService.end(id, '{ id status }');
    setRefresh(refresh + 1);
  };

  const handleScoreChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    const re = /^\b([0-9]|[1-9][0-9]|100)\b$/;
    if (value === '' || re.test(value)) {
      setInstructorScore(+value);
    }
  };

  const handleNotesChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setFeedback(value);
  };

  const handleShutdownScenario = () => {
    try {
      stopScenario();
      setDisableEnd(true);
      setRunning(false);
    } catch (err) {
      appStore.error(err);
    }
  };

  const handleDiscardScenario = async () => {
    try {
      await liveFireTrainingService.discard(review.id, '{ id status }');
      setDiscarded(true);
    } catch (err) {
      appStore.error(err);
    }
  };

  const handleReleaseScenario = async () => {
    try {
      await liveFireTrainingService.addInstructorFeedback(
        {
          id: review.id,
          feedback,
          score: instructorScore as number,
        },
        '{ id instructorScore feedback }',
      );

      setFeedbackDisabled(true);
    } catch (err) {
      appStore.error(err);
    }
  };

  const timerRunning = () => {
    return review && running && ['stoppage time', 'running', 'sleeping'].includes(review.status);
  };

  if (!review) {
    return <></>;
  }

  const isLFT = type === 'lft';
  const testResults = review.stages.flatMap(({ testResults }) => testResults);
  const statusOrder = ['completed', 'running', 'waiting'];
  const toBeSorted = testResults.filter(({ status }) => statusOrder.includes(status));
  const notToBeSorted = testResults.filter(({ status }) => !statusOrder.includes(status));
  const sortedPart = [...toBeSorted].sort((a, b) => {
    return statusOrder.indexOf(a.status) - statusOrder.indexOf(b.status);
  });
  const sortedTestResults = [...sortedPart, ...notToBeSorted];

  const stats = {
    type,
    name: truncate(review.name, 45),
    description: truncate((review.description ?? '').split('.').shift() ?? '', 85),
    trainingId: id,
    evaluationId: review.evaluationId,
    status: review.isReleased ? 'released' : review.status,
    composite: round((review.score ?? 0) * 100, 0),
    event: round((review.eventScore ?? 0) * 100, 0),
    precision: round((review.precisionScore ?? 0) * 100, 0),
    timeliness: round((review.responseScore ?? 0) * 100, 0),
    analyst: review.analyst,
    jobVendorId: review?.job?.vendorId ?? review?.vendorId ?? '',
  };

  // make sure it isn't an analyst
  const isInstructor = appStore.checkAccess([Role.INSTRUCTOR, Role.ADMIN]);

  const showButtons = !!(
    isLFT &&
    isInstructor &&
    (review.status === 'completed' || review.status === 'ended' || disableEnd) &&
    !feedbackDisabled &&
    !discarded &&
    !review.isReleased
  );

  const showEndButton = !!(
    isLFT &&
    isInstructor &&
    ['running', 'stoppage time', 'sleeping'].indexOf(review.status) >= 0 &&
    !disableEnd
  );

  let backUrl = '/curriculum/evaluations';
  if (!hasPageAccess('curriculum', backUrl)) {
    backUrl = '/curriculum/activity/evaluations';
  }

  return (
    <div>
      <div className={classes.header}>
        <div className={classes.headerLeft}>
          <Button
            component={Link}
            className={classes.buttonBackToEvaluations}
            to={backUrl}
            color="primary"
            startIcon={<FontAwesomeIcon icon={['fal', 'long-arrow-left']} />}
          >
            Back to Evaluations
          </Button>
          {!!review.timePassed && (
            <Timer running={timerRunning()} elapsed={review.timePassed}>
              Run Time:
            </Timer>
          )}
        </div>
        {showEndButton && appStore.checkAccess(Role.ADMIN) && (
          <div className={classes.buttons}>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleShutdownScenario}
              endIcon={<FontAwesomeIcon icon={['fal', 'trash-alt']} />}
            >
              End Evaluation
            </Button>
          </div>
        )}
        {showButtons && appStore.checkAccess(Role.ADMIN) && (
          <div className={classes.buttons}>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleDiscardScenario}
              endIcon={<FontAwesomeIcon icon={['fal', 'trash-alt']} />}
            >
              Discard Evaluation
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleReleaseScenario}
              endIcon={<FontAwesomeIcon icon={['fal', 'file-alt']} />}
            >
              Release to Evaluation Debrief
            </Button>
          </div>
        )}
      </div>
      <div className={classes.topRow}>
        <EvaluationStats {...stats} />
      </div>
      <div className={classes.middleRow}>
        {isLFT && (
          <Tabs
            selected={selectedTab}
            onSelect={setSelectedTab}
            labels={['Matched Analyst Events', 'Unmatched Analyst Events']}
          />
        )}
      </div>
      <div className={classes.bottomRow}>
        {isLFT && (
          <div>
            <AnalystFeedbackAndInstructorScore
              feedback={feedback}
              handleNotesChange={handleNotesChange}
              showButtons={showButtons}
              instructorScore={instructorScore as number}
              handleScoreChange={handleScoreChange}
            />
          </div>
        )}
        <div className={classes.bottomRowRight}>
          {(selectedTab === 0 || !isLFT) &&
            sortedTestResults.map(result => (
              <MatchedCard
                key={result.id}
                type={isLFT ? 'lft' : 'job'}
                testResult={result}
                analystInput={review.analystInput}
                prevented={result.prevented}
                detected={result.detected}
              />
            ))}
          {selectedTab === 1 && isLFT && <UnmatchedEvents analystInput={review.analystInput as AnalystInput[]} />}
        </div>
      </div>
    </div>
  );
}

export default EvaluationReview;
