import { useState } from 'react';
import { FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material';
import { Answer, Question } from '../interface';

interface YesNoQuestionProps {
  answer?: Answer;
  save: (question: Question, value: string) => void;
  question: Question;
  isReadonly?: boolean;
}

export default function YesNoQuestion({ answer, save, question, isReadonly }: YesNoQuestionProps) {
  const [value, setValue] = useState(answer?.value ?? '');

  async function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setValue(event.target.value);
    // attempt to save answer
    save(question, event.target.value);
  }

  return (
    <Grid item>
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <Typography>{question.question}</Typography>
        </Grid>
        <Grid item>
          <Grid container spacing={3}>
            <Grid item>
              <RadioGroup value={value} name={`radio_${question.id}`} row onChange={handleChange}>
                <FormControlLabel disabled={isReadonly} value="yes" control={<Radio />} label="Yes" />
                <FormControlLabel disabled={isReadonly} value="no" control={<Radio />} label="No" />
              </RadioGroup>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
