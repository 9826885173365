import { createContext, useState, useContext, ReactNode } from 'react';
import { Filter, FilterContextProps } from './interfaces';

const FilterContext = createContext<FilterContextProps>({
  filters: [],
  setFilters: () => {},
  sortById: false,
  setSortById: () => {},
  latest: false,
  setLatest: () => {},
});

export function FilterProvider({ children }: { children: ReactNode }) {
  const [filters, setFilters] = useState<Filter[]>([]);
  const [sortById, setSortById] = useState(false);
  const [latest, setLatest] = useState(false);

  return (
    <FilterContext.Provider value={{ filters, setFilters, sortById, setSortById, latest, setLatest }}>
      {children}
    </FilterContext.Provider>
  );
}

export function useFilterContext() {
  return useContext(FilterContext);
}
