import { Typography } from '@mui/material';
import { TemperatureComponent, TemperatureSlider } from '../../../reusables/TemperatureSlider';
import SettingWrapper from './SettingWrapper';

export default function RoiThreshold() {
  return (
    <SettingWrapper title="ROI Threshold Settings" setting="roiThresholds" defaultValue={[20, 60]} json>
      {(roiThresholds, onUpdate) => (
        <>
          <Typography variant="body1">ROI Performance Percentage</Typography>
          <TemperatureSlider
            valueLabelDisplay="on"
            component={TemperatureComponent}
            value={roiThresholds}
            onChange={(_: any, value: number[]) => onUpdate(value)}
          />
        </>
      )}
    </SettingWrapper>
  );
}
