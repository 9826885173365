import { ConfigValidation } from '../interfaces';

export const CONFIG_VALIDATION_COLUMNS: ConfigValidation[] = [
  {
    id: 1,
    name: 'Organizational Context',
    items: [
      {
        id: 1,
        name: 'GV.OC-01',
        status: 'no-data',
        tests: [
          { id: 1, name: 'GV.OC-01', description: 'API_GW_ASSOCIATED_WITH_WAF', status: 'no-data', isActive: true },
          { id: 2, name: 'GV.OC-01', description: 'API_GW_ENDPOINT_TYPE_CHECK', status: 'passed', isActive: false },
          {
            id: 3,
            name: 'GV.OC-01',
            description: 'INTERNET_GATEWAY_AUTHORIZED_VPC_ONLY',
            status: 'passed',
            isActive: true,
          },
          {
            id: 4,
            name: 'GV.OC-01',
            description: 'REDSHIFT_ENHANCED_VPC_ROUTING_ENABLED',
            status: 'failed',
            isActive: false,
          },
          {
            id: 5,
            name: 'GV.OC-01',
            description: 'VPC_PEERING_DNS_RESOLUTION_CHECK',
            status: 'no-data',
            isActive: true,
          },
          { id: 6, name: 'GV.OC-01', description: 'VPC_VPN_2_TUNNELS_UP', status: 'no-data', isActive: false },
        ],
      },
      {
        id: 2,
        name: 'GV.OC-02',
        status: 'passed',
        tests: [
          { id: 1, name: 'GV.OC-02', description: 'API_GW_ASSOCIATED_WITH_WAF', status: 'passed', isActive: true },
          { id: 2, name: 'GV.OC-02', description: 'API_GW_ENDPOINT_TYPE_CHECK', status: 'passed', isActive: false },
          {
            id: 3,
            name: 'GV.OC-02',
            description: 'INTERNET_GATEWAY_AUTHORIZED_VPC_ONLY',
            status: 'failed',
            isActive: true,
          },
          {
            id: 4,
            name: 'GV.OC-02',
            description: 'REDSHIFT_ENHANCED_VPC_ROUTING_ENABLED',
            status: 'failed',
            isActive: false,
          },
          {
            id: 5,
            name: 'GV.OC-02',
            description: 'VPC_PEERING_DNS_RESOLUTION_CHECK',
            status: 'no-data',
            isActive: true,
          },
          { id: 6, name: 'GV.OC-02', description: 'VPC_VPN_2_TUNNELS_UP', status: 'passed', isActive: false },
        ],
      },
      {
        id: 3,
        name: 'GV.OC-03',
        status: 'passed',
        tests: [
          { id: 1, name: 'GV.OC-03', description: 'API_GW_ASSOCIATED_WITH_WAF', status: 'passed', isActive: true },
          { id: 2, name: 'GV.OC-03', description: 'API_GW_ENDPOINT_TYPE_CHECK', status: 'passed', isActive: false },
          {
            id: 3,
            name: 'GV.OC-03',
            description: 'INTERNET_GATEWAY_AUTHORIZED_VPC_ONLY',
            status: 'failed',
            isActive: true,
          },
          {
            id: 4,
            name: 'GV.OC-03',
            description: 'REDSHIFT_ENHANCED_VPC_ROUTING_ENABLED',
            status: 'failed',
            isActive: false,
          },
          {
            id: 5,
            name: 'GV.OC-03',
            description: 'VPC_PEERING_DNS_RESOLUTION_CHECK',
            status: 'no-data',
            isActive: true,
          },
          { id: 6, name: 'GV.OC-03', description: 'VPC_VPN_2_TUNNELS_UP', status: 'passed', isActive: false },
        ],
      },
      {
        id: 4,
        name: 'GV.OC-04',
        status: 'failed',
        tests: [
          { id: 1, name: 'GV.OC-04', description: 'API_GW_ASSOCIATED_WITH_WAF', status: 'failed', isActive: true },
          { id: 2, name: 'GV.OC-04', description: 'API_GW_ENDPOINT_TYPE_CHECK', status: 'failed', isActive: false },
          {
            id: 3,
            name: 'GV.OC-04',
            description: 'INTERNET_GATEWAY_AUTHORIZED_VPC_ONLY',
            status: 'passed',
            isActive: true,
          },
          {
            id: 4,
            name: 'GV.OC-04',
            description: 'REDSHIFT_ENHANCED_VPC_ROUTING_ENABLED',
            status: 'passed',
            isActive: false,
          },
          {
            id: 5,
            name: 'GV.OC-04',
            description: 'VPC_PEERING_DNS_RESOLUTION_CHECK',
            status: 'no-data',
            isActive: true,
          },
          { id: 6, name: 'GV.OC-04', description: 'VPC_VPN_2_TUNNELS_UP', status: 'failed', isActive: false },
        ],
      },
      {
        id: 5,
        name: 'GV.OC-05',
        status: undefined,
        tests: [
          { id: 1, name: 'GV.OC-05', description: 'API_GW_ASSOCIATED_WITH_WAF', status: undefined, isActive: true },
          { id: 2, name: 'GV.OC-05', description: 'API_GW_ENDPOINT_TYPE_CHECK', status: undefined, isActive: false },
          {
            id: 3,
            name: 'GV.OC-05',
            description: 'INTERNET_GATEWAY_AUTHORIZED_VPC_ONLY',
            status: 'passed',
            isActive: true,
          },
          {
            id: 4,
            name: 'GV.OC-05',
            description: 'REDSHIFT_ENHANCED_VPC_ROUTING_ENABLED',
            status: 'passed',
            isActive: false,
          },
          {
            id: 5,
            name: 'GV.OC-05',
            description: 'VPC_PEERING_DNS_RESOLUTION_CHECK',
            status: 'no-data',
            isActive: true,
          },
          { id: 6, name: 'GV.OC-05', description: 'VPC_VPN_2_TUNNELS_UP', status: undefined, isActive: false },
        ],
      },
      {
        id: 6,
        name: 'GV.OC-06',
        status: 'passed',
        tests: [
          { id: 1, name: 'GV.OC-06', description: 'API_GW_ASSOCIATED_WITH_WAF', status: 'passed', isActive: true },
          { id: 2, name: 'GV.OC-06', description: 'API_GW_ENDPOINT_TYPE_CHECK', status: 'passed', isActive: false },
          {
            id: 3,
            name: 'GV.OC-06',
            description: 'INTERNET_GATEWAY_AUTHORIZED_VPC_ONLY',
            status: 'failed',
            isActive: true,
          },
          {
            id: 4,
            name: 'GV.OC-06',
            description: 'REDSHIFT_ENHANCED_VPC_ROUTING_ENABLED',
            status: 'failed',
            isActive: false,
          },
          {
            id: 5,
            name: 'GV.OC-06',
            description: 'VPC_PEERING_DNS_RESOLUTION_CHECK',
            status: 'no-data',
            isActive: true,
          },
          { id: 6, name: 'GV.OC-06', description: 'VPC_VPN_2_TUNNELS_UP', status: 'passed', isActive: false },
        ],
      },
      {
        id: 7,
        name: 'GV.OC-07',
        status: 'no-data',
        tests: [
          { id: 1, name: 'GV.OC-07', description: 'API_GW_ASSOCIATED_WITH_WAF', status: 'no-data', isActive: true },
          { id: 2, name: 'GV.OC-07', description: 'API_GW_ENDPOINT_TYPE_CHECK', status: 'passed', isActive: false },
          {
            id: 3,
            name: 'GV.OC-07',
            description: 'INTERNET_GATEWAY_AUTHORIZED_VPC_ONLY',
            status: 'passed',
            isActive: true,
          },
          {
            id: 4,
            name: 'GV.OC-07',
            description: 'REDSHIFT_ENHANCED_VPC_ROUTING_ENABLED',
            status: 'failed',
            isActive: false,
          },
          {
            id: 5,
            name: 'GV.OC-07',
            description: 'VPC_PEERING_DNS_RESOLUTION_CHECK',
            status: 'no-data',
            isActive: true,
          },
          { id: 6, name: 'GV.OC-07', description: 'VPC_VPN_2_TUNNELS_UP', status: 'no-data', isActive: false },
        ],
      },
      {
        id: 8,
        name: 'GV.OC-08',
        status: undefined,
        tests: [
          { id: 1, name: 'GV.OC-08', description: 'API_GW_ASSOCIATED_WITH_WAF', status: undefined, isActive: true },
          { id: 2, name: 'GV.OC-08', description: 'API_GW_ENDPOINT_TYPE_CHECK', status: undefined, isActive: false },
          {
            id: 3,
            name: 'GV.OC-08',
            description: 'INTERNET_GATEWAY_AUTHORIZED_VPC_ONLY',
            status: 'passed',
            isActive: true,
          },
          {
            id: 4,
            name: 'GV.OC-08',
            description: 'REDSHIFT_ENHANCED_VPC_ROUTING_ENABLED',
            status: 'passed',
            isActive: false,
          },
          {
            id: 5,
            name: 'GV.OC-08',
            description: 'VPC_PEERING_DNS_RESOLUTION_CHECK',
            status: 'no-data',
            isActive: true,
          },
          { id: 6, name: 'GV.OC-08', description: 'VPC_VPN_2_TUNNELS_UP', status: undefined, isActive: false },
        ],
      },
    ],
  },
  {
    id: 2,
    name: 'Risk Managament Strategy',
    items: [
      {
        id: 1,
        name: 'GV.RM-01',
        status: 'no-data',
        tests: [
          { id: 1, name: 'GV.RM-01', description: 'API_GW_ASSOCIATED_WITH_WAF', status: 'no-data', isActive: true },
          { id: 2, name: 'GV.RM-01', description: 'API_GW_ENDPOINT_TYPE_CHECK', status: 'passed', isActive: false },
          {
            id: 3,
            name: 'GV.RM-01',
            description: 'INTERNET_GATEWAY_AUTHORIZED_VPC_ONLY',
            status: 'passed',
            isActive: true,
          },
          {
            id: 4,
            name: 'GV.RM-01',
            description: 'REDSHIFT_ENHANCED_VPC_ROUTING_ENABLED',
            status: 'failed',
            isActive: false,
          },
          {
            id: 5,
            name: 'GV.RM-01',
            description: 'VPC_PEERING_DNS_RESOLUTION_CHECK',
            status: 'no-data',
            isActive: true,
          },
          { id: 6, name: 'GV.RM-01', description: 'VPC_VPN_2_TUNNELS_UP', status: 'no-data', isActive: false },
        ],
      },
      {
        id: 2,
        name: 'GV.RM-02',
        status: 'passed',
        tests: [
          { id: 1, name: 'GV.RM-02', description: 'API_GW_ASSOCIATED_WITH_WAF', status: 'passed', isActive: true },
          { id: 2, name: 'GV.RM-02', description: 'API_GW_ENDPOINT_TYPE_CHECK', status: 'passed', isActive: false },
          {
            id: 3,
            name: 'GV.RM-02',
            description: 'INTERNET_GATEWAY_AUTHORIZED_VPC_ONLY',
            status: 'failed',
            isActive: true,
          },
          {
            id: 4,
            name: 'GV.RM-02',
            description: 'REDSHIFT_ENHANCED_VPC_ROUTING_ENABLED',
            status: 'failed',
            isActive: false,
          },
          {
            id: 5,
            name: 'GV.RM-02',
            description: 'VPC_PEERING_DNS_RESOLUTION_CHECK',
            status: 'no-data',
            isActive: true,
          },
          { id: 6, name: 'GV.RM-02', description: 'VPC_VPN_2_TUNNELS_UP', status: 'passed', isActive: false },
        ],
      },
      {
        id: 3,
        name: 'GV.RM-03',
        status: 'passed',
        tests: [
          { id: 1, name: 'GV.RM-03', description: 'API_GW_ASSOCIATED_WITH_WAF', status: 'passed', isActive: true },
          { id: 2, name: 'GV.RM-03', description: 'API_GW_ENDPOINT_TYPE_CHECK', status: 'passed', isActive: false },
          {
            id: 3,
            name: 'GV.RM-03',
            description: 'INTERNET_GATEWAY_AUTHORIZED_VPC_ONLY',
            status: 'failed',
            isActive: true,
          },
          {
            id: 4,
            name: 'GV.RM-03',
            description: 'REDSHIFT_ENHANCED_VPC_ROUTING_ENABLED',
            status: 'failed',
            isActive: false,
          },
          {
            id: 5,
            name: 'GV.RM-03',
            description: 'VPC_PEERING_DNS_RESOLUTION_CHECK',
            status: 'no-data',
            isActive: true,
          },
          { id: 6, name: 'GV.RM-03', description: 'VPC_VPN_2_TUNNELS_UP', status: 'passed', isActive: false },
        ],
      },
      {
        id: 4,
        name: 'GV.RM-04',
        status: 'failed',
        tests: [
          { id: 1, name: 'GV.RM-04', description: 'API_GW_ASSOCIATED_WITH_WAF', status: 'failed', isActive: true },
          { id: 2, name: 'GV.RM-04', description: 'API_GW_ENDPOINT_TYPE_CHECK', status: 'failed', isActive: false },
          {
            id: 3,
            name: 'GV.RM-04',
            description: 'INTERNET_GATEWAY_AUTHORIZED_VPC_ONLY',
            status: 'passed',
            isActive: true,
          },
          {
            id: 4,
            name: 'GV.RM-04',
            description: 'REDSHIFT_ENHANCED_VPC_ROUTING_ENABLED',
            status: 'passed',
            isActive: false,
          },
          {
            id: 5,
            name: 'GV.RM-04',
            description: 'VPC_PEERING_DNS_RESOLUTION_CHECK',
            status: 'no-data',
            isActive: true,
          },
          { id: 6, name: 'GV.RM-04', description: 'VPC_VPN_2_TUNNELS_UP', status: 'failed', isActive: false },
        ],
      },
      {
        id: 5,
        name: 'GV.RM-05',
        status: undefined,
        tests: [
          { id: 1, name: 'GV.RM-05', description: 'API_GW_ASSOCIATED_WITH_WAF', status: undefined, isActive: true },
          { id: 2, name: 'GV.RM-05', description: 'API_GW_ENDPOINT_TYPE_CHECK', status: undefined, isActive: false },
          {
            id: 3,
            name: 'GV.RM-05',
            description: 'INTERNET_GATEWAY_AUTHORIZED_VPC_ONLY',
            status: 'passed',
            isActive: true,
          },
          {
            id: 4,
            name: 'GV.RM-05',
            description: 'REDSHIFT_ENHANCED_VPC_ROUTING_ENABLED',
            status: 'passed',
            isActive: false,
          },
          {
            id: 5,
            name: 'GV.RM-05',
            description: 'VPC_PEERING_DNS_RESOLUTION_CHECK',
            status: 'no-data',
            isActive: true,
          },
          { id: 6, name: 'GV.RM-05', description: 'VPC_VPN_2_TUNNELS_UP', status: undefined, isActive: false },
        ],
      },
      {
        id: 6,
        name: 'GV.RM-06',
        status: 'passed',
        tests: [
          { id: 1, name: 'GV.RM-06', description: 'API_GW_ASSOCIATED_WITH_WAF', status: 'passed', isActive: true },
          { id: 2, name: 'GV.RM-06', description: 'API_GW_ENDPOINT_TYPE_CHECK', status: 'passed', isActive: false },
          {
            id: 3,
            name: 'GV.RM-06',
            description: 'INTERNET_GATEWAY_AUTHORIZED_VPC_ONLY',
            status: 'failed',
            isActive: true,
          },
          {
            id: 4,
            name: 'GV.RM-06',
            description: 'REDSHIFT_ENHANCED_VPC_ROUTING_ENABLED',
            status: 'failed',
            isActive: false,
          },
          {
            id: 5,
            name: 'GV.RM-06',
            description: 'VPC_PEERING_DNS_RESOLUTION_CHECK',
            status: 'no-data',
            isActive: true,
          },
          { id: 6, name: 'GV.RM-06', description: 'VPC_VPN_2_TUNNELS_UP', status: 'passed', isActive: false },
        ],
      },
      {
        id: 7,
        name: 'GV.RM-07',
        status: 'no-data',
        tests: [
          { id: 1, name: 'GV.RM-07', description: 'API_GW_ASSOCIATED_WITH_WAF', status: 'no-data', isActive: true },
          { id: 2, name: 'GV.RM-07', description: 'API_GW_ENDPOINT_TYPE_CHECK', status: 'passed', isActive: false },
          {
            id: 3,
            name: 'GV.RM-07',
            description: 'INTERNET_GATEWAY_AUTHORIZED_VPC_ONLY',
            status: 'passed',
            isActive: true,
          },
          {
            id: 4,
            name: 'GV.RM-07',
            description: 'REDSHIFT_ENHANCED_VPC_ROUTING_ENABLED',
            status: 'failed',
            isActive: false,
          },
          {
            id: 5,
            name: 'GV.RM-07',
            description: 'VPC_PEERING_DNS_RESOLUTION_CHECK',
            status: 'no-data',
            isActive: true,
          },
          { id: 6, name: 'GV.RM-07', description: 'VPC_VPN_2_TUNNELS_UP', status: 'no-data', isActive: false },
        ],
      },
      {
        id: 8,
        name: 'GV.RM-08',
        status: undefined,
        tests: [
          { id: 1, name: 'GV.RM-08', description: 'API_GW_ASSOCIATED_WITH_WAF', status: undefined, isActive: true },
          { id: 2, name: 'GV.RM-08', description: 'API_GW_ENDPOINT_TYPE_CHECK', status: undefined, isActive: false },
          {
            id: 3,
            name: 'GV.RM-08',
            description: 'INTERNET_GATEWAY_AUTHORIZED_VPC_ONLY',
            status: 'passed',
            isActive: true,
          },
          {
            id: 4,
            name: 'GV.RM-08',
            description: 'REDSHIFT_ENHANCED_VPC_ROUTING_ENABLED',
            status: 'passed',
            isActive: false,
          },
          {
            id: 5,
            name: 'GV.RM-08',
            description: 'VPC_PEERING_DNS_RESOLUTION_CHECK',
            status: 'no-data',
            isActive: true,
          },
          { id: 6, name: 'GV.RM-08', description: 'VPC_VPN_2_TUNNELS_UP', status: undefined, isActive: false },
        ],
      },
    ],
  },
  {
    id: 3,
    name: 'Roles, Reponsibilities, and Authorities',
    items: [
      {
        id: 1,
        name: 'GV.RR-01',
        status: 'no-data',
        tests: [
          { id: 1, name: 'GV.RR-01', description: 'API_GW_ASSOCIATED_WITH_WAF', status: 'no-data', isActive: true },
          { id: 2, name: 'GV.RR-01', description: 'API_GW_ENDPOINT_TYPE_CHECK', status: 'passed', isActive: false },
          {
            id: 3,
            name: 'GV.RR-01',
            description: 'INTERNET_GATEWAY_AUTHORIZED_VPC_ONLY',
            status: 'passed',
            isActive: true,
          },
          {
            id: 4,
            name: 'GV.RR-01',
            description: 'REDSHIFT_ENHANCED_VPC_ROUTING_ENABLED',
            status: 'failed',
            isActive: false,
          },
          {
            id: 5,
            name: 'GV.RR-01',
            description: 'VPC_PEERING_DNS_RESOLUTION_CHECK',
            status: 'no-data',
            isActive: true,
          },
          { id: 6, name: 'GV.RR-01', description: 'VPC_VPN_2_TUNNELS_UP', status: 'no-data', isActive: false },
        ],
      },
      {
        id: 2,
        name: 'GV.RR-02',
        status: 'passed',
        tests: [
          { id: 1, name: 'GV.RR-02', description: 'API_GW_ASSOCIATED_WITH_WAF', status: 'passed', isActive: true },
          { id: 2, name: 'GV.RR-02', description: 'API_GW_ENDPOINT_TYPE_CHECK', status: 'passed', isActive: false },
          {
            id: 3,
            name: 'GV.RR-02',
            description: 'INTERNET_GATEWAY_AUTHORIZED_VPC_ONLY',
            status: 'failed',
            isActive: true,
          },
          {
            id: 4,
            name: 'GV.RR-02',
            description: 'REDSHIFT_ENHANCED_VPC_ROUTING_ENABLED',
            status: 'failed',
            isActive: false,
          },
          {
            id: 5,
            name: 'GV.RR-02',
            description: 'VPC_PEERING_DNS_RESOLUTION_CHECK',
            status: 'no-data',
            isActive: true,
          },
          { id: 6, name: 'GV.RR-02', description: 'VPC_VPN_2_TUNNELS_UP', status: 'passed', isActive: false },
        ],
      },
      {
        id: 3,
        name: 'GV.RR-03',
        status: 'passed',
        tests: [
          { id: 1, name: 'GV.RR-03', description: 'API_GW_ASSOCIATED_WITH_WAF', status: 'passed', isActive: true },
          { id: 2, name: 'GV.RR-03', description: 'API_GW_ENDPOINT_TYPE_CHECK', status: 'passed', isActive: false },
          {
            id: 3,
            name: 'GV.RR-03',
            description: 'INTERNET_GATEWAY_AUTHORIZED_VPC_ONLY',
            status: 'failed',
            isActive: true,
          },
          {
            id: 4,
            name: 'GV.RR-03',
            description: 'REDSHIFT_ENHANCED_VPC_ROUTING_ENABLED',
            status: 'failed',
            isActive: false,
          },
          {
            id: 5,
            name: 'GV.RR-03',
            description: 'VPC_PEERING_DNS_RESOLUTION_CHECK',
            status: 'no-data',
            isActive: true,
          },
          { id: 6, name: 'GV.RR-03', description: 'VPC_VPN_2_TUNNELS_UP', status: 'passed', isActive: false },
        ],
      },
      {
        id: 4,
        name: 'GV.RR-04',
        status: 'failed',
        tests: [
          { id: 1, name: 'GV.RR-04', description: 'API_GW_ASSOCIATED_WITH_WAF', status: 'failed', isActive: true },
          { id: 2, name: 'GV.RR-04', description: 'API_GW_ENDPOINT_TYPE_CHECK', status: 'failed', isActive: false },
          {
            id: 3,
            name: 'GV.RR-04',
            description: 'INTERNET_GATEWAY_AUTHORIZED_VPC_ONLY',
            status: 'passed',
            isActive: true,
          },
          {
            id: 4,
            name: 'GV.RR-04',
            description: 'REDSHIFT_ENHANCED_VPC_ROUTING_ENABLED',
            status: 'passed',
            isActive: false,
          },
          {
            id: 5,
            name: 'GV.RR-04',
            description: 'VPC_PEERING_DNS_RESOLUTION_CHECK',
            status: 'no-data',
            isActive: true,
          },
          { id: 6, name: 'GV.RR-04', description: 'VPC_VPN_2_TUNNELS_UP', status: 'failed', isActive: false },
        ],
      },
      {
        id: 5,
        name: 'GV.RR-05',
        status: undefined,
        tests: [
          { id: 1, name: 'GV.RR-05', description: 'API_GW_ASSOCIATED_WITH_WAF', status: undefined, isActive: true },
          { id: 2, name: 'GV.RR-05', description: 'API_GW_ENDPOINT_TYPE_CHECK', status: undefined, isActive: false },
          {
            id: 3,
            name: 'GV.RR-05',
            description: 'INTERNET_GATEWAY_AUTHORIZED_VPC_ONLY',
            status: 'passed',
            isActive: true,
          },
          {
            id: 4,
            name: 'GV.RR-05',
            description: 'REDSHIFT_ENHANCED_VPC_ROUTING_ENABLED',
            status: 'passed',
            isActive: false,
          },
          {
            id: 5,
            name: 'GV.RR-05',
            description: 'VPC_PEERING_DNS_RESOLUTION_CHECK',
            status: 'no-data',
            isActive: true,
          },
          { id: 6, name: 'GV.RR-05', description: 'VPC_VPN_2_TUNNELS_UP', status: undefined, isActive: false },
        ],
      },
      {
        id: 6,
        name: 'GV.RR-06',
        status: 'passed',
        tests: [
          { id: 1, name: 'GV.RR-06', description: 'API_GW_ASSOCIATED_WITH_WAF', status: 'passed', isActive: true },
          { id: 2, name: 'GV.RR-06', description: 'API_GW_ENDPOINT_TYPE_CHECK', status: 'passed', isActive: false },
          {
            id: 3,
            name: 'GV.RR-06',
            description: 'INTERNET_GATEWAY_AUTHORIZED_VPC_ONLY',
            status: 'failed',
            isActive: true,
          },
          {
            id: 4,
            name: 'GV.RR-06',
            description: 'REDSHIFT_ENHANCED_VPC_ROUTING_ENABLED',
            status: 'failed',
            isActive: false,
          },
          {
            id: 5,
            name: 'GV.RR-06',
            description: 'VPC_PEERING_DNS_RESOLUTION_CHECK',
            status: 'no-data',
            isActive: true,
          },
          { id: 6, name: 'GV.RR-06', description: 'VPC_VPN_2_TUNNELS_UP', status: 'passed', isActive: false },
        ],
      },
      {
        id: 7,
        name: 'GV.RR-07',
        status: 'no-data',
        tests: [
          { id: 1, name: 'GV.RR-07', description: 'API_GW_ASSOCIATED_WITH_WAF', status: 'no-data', isActive: true },
          { id: 2, name: 'GV.RR-07', description: 'API_GW_ENDPOINT_TYPE_CHECK', status: 'passed', isActive: false },
          {
            id: 3,
            name: 'GV.RR-07',
            description: 'INTERNET_GATEWAY_AUTHORIZED_VPC_ONLY',
            status: 'passed',
            isActive: true,
          },
          {
            id: 4,
            name: 'GV.RR-07',
            description: 'REDSHIFT_ENHANCED_VPC_ROUTING_ENABLED',
            status: 'failed',
            isActive: false,
          },
          {
            id: 5,
            name: 'GV.RR-07',
            description: 'VPC_PEERING_DNS_RESOLUTION_CHECK',
            status: 'no-data',
            isActive: true,
          },
          { id: 6, name: 'GV.RR-07', description: 'VPC_VPN_2_TUNNELS_UP', status: 'no-data', isActive: false },
        ],
      },
      {
        id: 8,
        name: 'GV.RR-08',
        status: undefined,
        tests: [
          { id: 1, name: 'GV.RR-08', description: 'API_GW_ASSOCIATED_WITH_WAF', status: undefined, isActive: true },
          { id: 2, name: 'GV.RR-08', description: 'API_GW_ENDPOINT_TYPE_CHECK', status: undefined, isActive: false },
          {
            id: 3,
            name: 'GV.RR-08',
            description: 'INTERNET_GATEWAY_AUTHORIZED_VPC_ONLY',
            status: 'passed',
            isActive: true,
          },
          {
            id: 4,
            name: 'GV.RR-08',
            description: 'REDSHIFT_ENHANCED_VPC_ROUTING_ENABLED',
            status: 'passed',
            isActive: false,
          },
          {
            id: 5,
            name: 'GV.RR-08',
            description: 'VPC_PEERING_DNS_RESOLUTION_CHECK',
            status: 'no-data',
            isActive: true,
          },
          { id: 6, name: 'GV.RR-08', description: 'VPC_VPN_2_TUNNELS_UP', status: undefined, isActive: false },
        ],
      },
    ],
  },
  {
    id: 4,
    name: 'Policy',
    items: [
      {
        id: 1,
        name: 'GV-PO-01',
        status: 'no-data',
        tests: [
          { id: 1, name: 'GV-PO-01', description: 'API_GW_ASSOCIATED_WITH_WAF', status: 'no-data', isActive: true },
          { id: 2, name: 'GV-PO-01', description: 'API_GW_ENDPOINT_TYPE_CHECK', status: 'passed', isActive: false },
          {
            id: 3,
            name: 'GV-PO-01',
            description: 'INTERNET_GATEWAY_AUTHORIZED_VPC_ONLY',
            status: 'passed',
            isActive: true,
          },
          {
            id: 4,
            name: 'GV-PO-01',
            description: 'REDSHIFT_ENHANCED_VPC_ROUTING_ENABLED',
            status: 'failed',
            isActive: false,
          },
          {
            id: 5,
            name: 'GV-PO-01',
            description: 'VPC_PEERING_DNS_RESOLUTION_CHECK',
            status: 'no-data',
            isActive: true,
          },
          { id: 6, name: 'GV-PO-01', description: 'VPC_VPN_2_TUNNELS_UP', status: 'no-data', isActive: false },
        ],
      },
      {
        id: 2,
        name: 'GV-PO-02',
        status: 'passed',
        tests: [
          { id: 1, name: 'GV-PO-02', description: 'API_GW_ASSOCIATED_WITH_WAF', status: 'passed', isActive: true },
          { id: 2, name: 'GV-PO-02', description: 'API_GW_ENDPOINT_TYPE_CHECK', status: 'passed', isActive: false },
          {
            id: 3,
            name: 'GV-PO-02',
            description: 'INTERNET_GATEWAY_AUTHORIZED_VPC_ONLY',
            status: 'failed',
            isActive: true,
          },
          {
            id: 4,
            name: 'GV-PO-02',
            description: 'REDSHIFT_ENHANCED_VPC_ROUTING_ENABLED',
            status: 'failed',
            isActive: false,
          },
          {
            id: 5,
            name: 'GV-PO-02',
            description: 'VPC_PEERING_DNS_RESOLUTION_CHECK',
            status: 'no-data',
            isActive: true,
          },
          { id: 6, name: 'GV-PO-02', description: 'VPC_VPN_2_TUNNELS_UP', status: 'passed', isActive: false },
        ],
      },
      {
        id: 3,
        name: 'GV-PO-03',
        status: 'passed',
        tests: [
          { id: 1, name: 'GV-PO-03', description: 'API_GW_ASSOCIATED_WITH_WAF', status: 'passed', isActive: true },
          { id: 2, name: 'GV-PO-03', description: 'API_GW_ENDPOINT_TYPE_CHECK', status: 'passed', isActive: false },
          {
            id: 3,
            name: 'GV-PO-03',
            description: 'INTERNET_GATEWAY_AUTHORIZED_VPC_ONLY',
            status: 'failed',
            isActive: true,
          },
          {
            id: 4,
            name: 'GV-PO-03',
            description: 'REDSHIFT_ENHANCED_VPC_ROUTING_ENABLED',
            status: 'failed',
            isActive: false,
          },
          {
            id: 5,
            name: 'GV-PO-03',
            description: 'VPC_PEERING_DNS_RESOLUTION_CHECK',
            status: 'no-data',
            isActive: true,
          },
          { id: 6, name: 'GV-PO-03', description: 'VPC_VPN_2_TUNNELS_UP', status: 'passed', isActive: false },
        ],
      },
      {
        id: 4,
        name: 'GV-PO-04',
        status: 'failed',
        tests: [
          { id: 1, name: 'GV-PO-04', description: 'API_GW_ASSOCIATED_WITH_WAF', status: 'failed', isActive: true },
          { id: 2, name: 'GV-PO-04', description: 'API_GW_ENDPOINT_TYPE_CHECK', status: 'failed', isActive: false },
          {
            id: 3,
            name: 'GV-PO-04',
            description: 'INTERNET_GATEWAY_AUTHORIZED_VPC_ONLY',
            status: 'passed',
            isActive: true,
          },
          {
            id: 4,
            name: 'GV-PO-04',
            description: 'REDSHIFT_ENHANCED_VPC_ROUTING_ENABLED',
            status: 'passed',
            isActive: false,
          },
          {
            id: 5,
            name: 'GV-PO-04',
            description: 'VPC_PEERING_DNS_RESOLUTION_CHECK',
            status: 'no-data',
            isActive: true,
          },
          { id: 6, name: 'GV-PO-04', description: 'VPC_VPN_2_TUNNELS_UP', status: 'failed', isActive: false },
        ],
      },
      {
        id: 5,
        name: 'GV-PO-05',
        status: undefined,
        tests: [
          { id: 1, name: 'GV-PO-05', description: 'API_GW_ASSOCIATED_WITH_WAF', status: undefined, isActive: true },
          { id: 2, name: 'GV-PO-05', description: 'API_GW_ENDPOINT_TYPE_CHECK', status: undefined, isActive: false },
          {
            id: 3,
            name: 'GV-PO-05',
            description: 'INTERNET_GATEWAY_AUTHORIZED_VPC_ONLY',
            status: 'passed',
            isActive: true,
          },
          {
            id: 4,
            name: 'GV-PO-05',
            description: 'REDSHIFT_ENHANCED_VPC_ROUTING_ENABLED',
            status: 'passed',
            isActive: false,
          },
          {
            id: 5,
            name: 'GV-PO-05',
            description: 'VPC_PEERING_DNS_RESOLUTION_CHECK',
            status: 'no-data',
            isActive: true,
          },
          { id: 6, name: 'GV-PO-05', description: 'VPC_VPN_2_TUNNELS_UP', status: undefined, isActive: false },
        ],
      },
      {
        id: 6,
        name: 'GV-PO-06',
        status: 'passed',
        tests: [
          { id: 1, name: 'GV-PO-06', description: 'API_GW_ASSOCIATED_WITH_WAF', status: 'passed', isActive: true },
          { id: 2, name: 'GV-PO-06', description: 'API_GW_ENDPOINT_TYPE_CHECK', status: 'passed', isActive: false },
          {
            id: 3,
            name: 'GV-PO-06',
            description: 'INTERNET_GATEWAY_AUTHORIZED_VPC_ONLY',
            status: 'failed',
            isActive: true,
          },
          {
            id: 4,
            name: 'GV-PO-06',
            description: 'REDSHIFT_ENHANCED_VPC_ROUTING_ENABLED',
            status: 'failed',
            isActive: false,
          },
          {
            id: 5,
            name: 'GV-PO-06',
            description: 'VPC_PEERING_DNS_RESOLUTION_CHECK',
            status: 'no-data',
            isActive: true,
          },
          { id: 6, name: 'GV-PO-06', description: 'VPC_VPN_2_TUNNELS_UP', status: 'passed', isActive: false },
        ],
      },
      {
        id: 7,
        name: 'GV-PO-07',
        status: 'no-data',
        tests: [
          { id: 1, name: 'GV-PO-07', description: 'API_GW_ASSOCIATED_WITH_WAF', status: 'no-data', isActive: true },
          { id: 2, name: 'GV-PO-07', description: 'API_GW_ENDPOINT_TYPE_CHECK', status: 'passed', isActive: false },
          {
            id: 3,
            name: 'GV-PO-07',
            description: 'INTERNET_GATEWAY_AUTHORIZED_VPC_ONLY',
            status: 'passed',
            isActive: true,
          },
          {
            id: 4,
            name: 'GV-PO-07',
            description: 'REDSHIFT_ENHANCED_VPC_ROUTING_ENABLED',
            status: 'failed',
            isActive: false,
          },
          {
            id: 5,
            name: 'GV-PO-07',
            description: 'VPC_PEERING_DNS_RESOLUTION_CHECK',
            status: 'no-data',
            isActive: true,
          },
          { id: 6, name: 'GV-PO-07', description: 'VPC_VPN_2_TUNNELS_UP', status: 'no-data', isActive: false },
        ],
      },
      {
        id: 8,
        name: 'GV-PO-08',
        status: undefined,
        tests: [
          { id: 1, name: 'GV-PO-08', description: 'API_GW_ASSOCIATED_WITH_WAF', status: undefined, isActive: true },
          { id: 2, name: 'GV-PO-08', description: 'API_GW_ENDPOINT_TYPE_CHECK', status: undefined, isActive: false },
          {
            id: 3,
            name: 'GV-PO-08',
            description: 'INTERNET_GATEWAY_AUTHORIZED_VPC_ONLY',
            status: 'passed',
            isActive: true,
          },
          {
            id: 4,
            name: 'GV-PO-08',
            description: 'REDSHIFT_ENHANCED_VPC_ROUTING_ENABLED',
            status: 'passed',
            isActive: false,
          },
          {
            id: 5,
            name: 'GV-PO-08',
            description: 'VPC_PEERING_DNS_RESOLUTION_CHECK',
            status: 'no-data',
            isActive: true,
          },
          { id: 6, name: 'GV-PO-08', description: 'VPC_VPN_2_TUNNELS_UP', status: undefined, isActive: false },
        ],
      },
    ],
  },
  {
    id: 5,
    name: 'Cybersecurity Supply Chain Risk Management',
    items: [
      {
        id: 1,
        name: 'GV-SC-01',
        status: 'no-data',
        tests: [
          { id: 1, name: 'GV-SC-01', description: 'API_GW_ASSOCIATED_WITH_WAF', status: 'no-data', isActive: true },
          { id: 2, name: 'GV-SC-01', description: 'API_GW_ENDPOINT_TYPE_CHECK', status: 'passed', isActive: false },
          {
            id: 3,
            name: 'GV-SC-01',
            description: 'INTERNET_GATEWAY_AUTHORIZED_VPC_ONLY',
            status: 'passed',
            isActive: true,
          },
          {
            id: 4,
            name: 'GV-SC-01',
            description: 'REDSHIFT_ENHANCED_VPC_ROUTING_ENABLED',
            status: 'failed',
            isActive: false,
          },
          {
            id: 5,
            name: 'GV-SC-01',
            description: 'VPC_PEERING_DNS_RESOLUTION_CHECK',
            status: 'no-data',
            isActive: true,
          },
          { id: 6, name: 'GV-SC-01', description: 'VPC_VPN_2_TUNNELS_UP', status: 'no-data', isActive: false },
        ],
      },
      {
        id: 2,
        name: 'GV-SC-02',
        status: 'passed',
        tests: [
          { id: 1, name: 'GV-SC-02', description: 'API_GW_ASSOCIATED_WITH_WAF', status: 'passed', isActive: true },
          { id: 2, name: 'GV-SC-02', description: 'API_GW_ENDPOINT_TYPE_CHECK', status: 'passed', isActive: false },
          {
            id: 3,
            name: 'GV-SC-02',
            description: 'INTERNET_GATEWAY_AUTHORIZED_VPC_ONLY',
            status: 'failed',
            isActive: true,
          },
          {
            id: 4,
            name: 'GV-SC-02',
            description: 'REDSHIFT_ENHANCED_VPC_ROUTING_ENABLED',
            status: 'failed',
            isActive: false,
          },
          {
            id: 5,
            name: 'GV-SC-02',
            description: 'VPC_PEERING_DNS_RESOLUTION_CHECK',
            status: 'no-data',
            isActive: true,
          },
          { id: 6, name: 'GV-SC-02', description: 'VPC_VPN_2_TUNNELS_UP', status: 'passed', isActive: false },
        ],
      },
      {
        id: 3,
        name: 'GV-SC-03',
        status: 'passed',
        tests: [
          { id: 1, name: 'GV-SC-03', description: 'API_GW_ASSOCIATED_WITH_WAF', status: 'passed', isActive: true },
          { id: 2, name: 'GV-SC-03', description: 'API_GW_ENDPOINT_TYPE_CHECK', status: 'passed', isActive: false },
          {
            id: 3,
            name: 'GV-SC-03',
            description: 'INTERNET_GATEWAY_AUTHORIZED_VPC_ONLY',
            status: 'failed',
            isActive: true,
          },
          {
            id: 4,
            name: 'GV-SC-03',
            description: 'REDSHIFT_ENHANCED_VPC_ROUTING_ENABLED',
            status: 'failed',
            isActive: false,
          },
          {
            id: 5,
            name: 'GV-SC-03',
            description: 'VPC_PEERING_DNS_RESOLUTION_CHECK',
            status: 'no-data',
            isActive: true,
          },
          { id: 6, name: 'GV-SC-03', description: 'VPC_VPN_2_TUNNELS_UP', status: 'passed', isActive: false },
        ],
      },
      {
        id: 4,
        name: 'GV-SC-04',
        status: 'failed',
        tests: [
          { id: 1, name: 'GV-SC-04', description: 'API_GW_ASSOCIATED_WITH_WAF', status: 'failed', isActive: true },
          { id: 2, name: 'GV-SC-04', description: 'API_GW_ENDPOINT_TYPE_CHECK', status: 'failed', isActive: false },
          {
            id: 3,
            name: 'GV-SC-04',
            description: 'INTERNET_GATEWAY_AUTHORIZED_VPC_ONLY',
            status: 'passed',
            isActive: true,
          },
          {
            id: 4,
            name: 'GV-SC-04',
            description: 'REDSHIFT_ENHANCED_VPC_ROUTING_ENABLED',
            status: 'passed',
            isActive: false,
          },
          {
            id: 5,
            name: 'GV-SC-04',
            description: 'VPC_PEERING_DNS_RESOLUTION_CHECK',
            status: 'no-data',
            isActive: true,
          },
          { id: 6, name: 'GV-SC-04', description: 'VPC_VPN_2_TUNNELS_UP', status: 'failed', isActive: false },
        ],
      },
      {
        id: 5,
        name: 'GV-SC-05',
        status: undefined,
        tests: [
          { id: 1, name: 'GV-SC-05', description: 'API_GW_ASSOCIATED_WITH_WAF', status: undefined, isActive: true },
          { id: 2, name: 'GV-SC-05', description: 'API_GW_ENDPOINT_TYPE_CHECK', status: undefined, isActive: false },
          {
            id: 3,
            name: 'GV-SC-05',
            description: 'INTERNET_GATEWAY_AUTHORIZED_VPC_ONLY',
            status: 'passed',
            isActive: true,
          },
          {
            id: 4,
            name: 'GV-SC-05',
            description: 'REDSHIFT_ENHANCED_VPC_ROUTING_ENABLED',
            status: 'passed',
            isActive: false,
          },
          {
            id: 5,
            name: 'GV-SC-05',
            description: 'VPC_PEERING_DNS_RESOLUTION_CHECK',
            status: 'no-data',
            isActive: true,
          },
          { id: 6, name: 'GV-SC-05', description: 'VPC_VPN_2_TUNNELS_UP', status: undefined, isActive: false },
        ],
      },
      {
        id: 6,
        name: 'GV-SC-06',
        status: 'passed',
        tests: [
          { id: 1, name: 'GV-SC-06', description: 'API_GW_ASSOCIATED_WITH_WAF', status: 'passed', isActive: true },
          { id: 2, name: 'GV-SC-06', description: 'API_GW_ENDPOINT_TYPE_CHECK', status: 'passed', isActive: false },
          {
            id: 3,
            name: 'GV-SC-06',
            description: 'INTERNET_GATEWAY_AUTHORIZED_VPC_ONLY',
            status: 'failed',
            isActive: true,
          },
          {
            id: 4,
            name: 'GV-SC-06',
            description: 'REDSHIFT_ENHANCED_VPC_ROUTING_ENABLED',
            status: 'failed',
            isActive: false,
          },
          {
            id: 5,
            name: 'GV-SC-06',
            description: 'VPC_PEERING_DNS_RESOLUTION_CHECK',
            status: 'no-data',
            isActive: true,
          },
          { id: 6, name: 'GV-SC-06', description: 'VPC_VPN_2_TUNNELS_UP', status: 'passed', isActive: false },
        ],
      },
      {
        id: 7,
        name: 'GV-SC-07',
        status: 'no-data',
        tests: [
          { id: 1, name: 'GV-SC-07', description: 'API_GW_ASSOCIATED_WITH_WAF', status: 'no-data', isActive: true },
          { id: 2, name: 'GV-SC-07', description: 'API_GW_ENDPOINT_TYPE_CHECK', status: 'passed', isActive: false },
          {
            id: 3,
            name: 'GV-SC-07',
            description: 'INTERNET_GATEWAY_AUTHORIZED_VPC_ONLY',
            status: 'passed',
            isActive: true,
          },
          {
            id: 4,
            name: 'GV-SC-07',
            description: 'REDSHIFT_ENHANCED_VPC_ROUTING_ENABLED',
            status: 'failed',
            isActive: false,
          },
          {
            id: 5,
            name: 'GV-SC-07',
            description: 'VPC_PEERING_DNS_RESOLUTION_CHECK',
            status: 'no-data',
            isActive: true,
          },
          { id: 6, name: 'GV-SC-07', description: 'VPC_VPN_2_TUNNELS_UP', status: 'no-data', isActive: false },
        ],
      },
      {
        id: 8,
        name: 'GV-SC-08',
        status: undefined,
        tests: [
          { id: 1, name: 'GV-SC-08', description: 'API_GW_ASSOCIATED_WITH_WAF', status: undefined, isActive: true },
          { id: 2, name: 'GV-SC-08', description: 'API_GW_ENDPOINT_TYPE_CHECK', status: undefined, isActive: false },
          {
            id: 3,
            name: 'GV-SC-08',
            description: 'INTERNET_GATEWAY_AUTHORIZED_VPC_ONLY',
            status: 'passed',
            isActive: true,
          },
          {
            id: 4,
            name: 'GV-SC-08',
            description: 'REDSHIFT_ENHANCED_VPC_ROUTING_ENABLED',
            status: 'passed',
            isActive: false,
          },
          {
            id: 5,
            name: 'GV-SC-08',
            description: 'VPC_PEERING_DNS_RESOLUTION_CHECK',
            status: 'no-data',
            isActive: true,
          },
          { id: 6, name: 'GV-SC-08', description: 'VPC_VPN_2_TUNNELS_UP', status: undefined, isActive: false },
        ],
      },
    ],
  },
  {
    id: 6,
    name: 'Asset Management (ID.AM): Assets',
    items: [
      {
        id: 1,
        name: 'ID.RA-01',
        status: 'no-data',
        tests: [
          { id: 1, name: 'ID.RA-01', description: 'API_GW_ASSOCIATED_WITH_WAF', status: 'no-data', isActive: true },
          { id: 2, name: 'ID.RA-01', description: 'API_GW_ENDPOINT_TYPE_CHECK', status: 'passed', isActive: false },
          {
            id: 3,
            name: 'ID.RA-01',
            description: 'INTERNET_GATEWAY_AUTHORIZED_VPC_ONLY',
            status: 'passed',
            isActive: true,
          },
          {
            id: 4,
            name: 'ID.RA-01',
            description: 'REDSHIFT_ENHANCED_VPC_ROUTING_ENABLED',
            status: 'failed',
            isActive: false,
          },
          {
            id: 5,
            name: 'ID.RA-01',
            description: 'VPC_PEERING_DNS_RESOLUTION_CHECK',
            status: 'no-data',
            isActive: true,
          },
          { id: 6, name: 'ID.RA-01', description: 'VPC_VPN_2_TUNNELS_UP', status: 'no-data', isActive: false },
        ],
      },
      {
        id: 2,
        name: 'ID.RA-02',
        status: 'passed',
        tests: [
          { id: 1, name: 'ID.RA-02', description: 'API_GW_ASSOCIATED_WITH_WAF', status: 'passed', isActive: true },
          { id: 2, name: 'ID.RA-02', description: 'API_GW_ENDPOINT_TYPE_CHECK', status: 'passed', isActive: false },
          {
            id: 3,
            name: 'ID.RA-02',
            description: 'INTERNET_GATEWAY_AUTHORIZED_VPC_ONLY',
            status: 'failed',
            isActive: true,
          },
          {
            id: 4,
            name: 'ID.RA-02',
            description: 'REDSHIFT_ENHANCED_VPC_ROUTING_ENABLED',
            status: 'failed',
            isActive: false,
          },
          {
            id: 5,
            name: 'ID.RA-02',
            description: 'VPC_PEERING_DNS_RESOLUTION_CHECK',
            status: 'no-data',
            isActive: true,
          },
          { id: 6, name: 'ID.RA-02', description: 'VPC_VPN_2_TUNNELS_UP', status: 'passed', isActive: false },
        ],
      },
      {
        id: 3,
        name: 'ID.RA-03',
        status: 'passed',
        tests: [
          { id: 1, name: 'ID.RA-03', description: 'API_GW_ASSOCIATED_WITH_WAF', status: 'passed', isActive: true },
          { id: 2, name: 'ID.RA-03', description: 'API_GW_ENDPOINT_TYPE_CHECK', status: 'passed', isActive: false },
          {
            id: 3,
            name: 'ID.RA-03',
            description: 'INTERNET_GATEWAY_AUTHORIZED_VPC_ONLY',
            status: 'failed',
            isActive: true,
          },
          {
            id: 4,
            name: 'ID.RA-03',
            description: 'REDSHIFT_ENHANCED_VPC_ROUTING_ENABLED',
            status: 'failed',
            isActive: false,
          },
          {
            id: 5,
            name: 'ID.RA-03',
            description: 'VPC_PEERING_DNS_RESOLUTION_CHECK',
            status: 'no-data',
            isActive: true,
          },
          { id: 6, name: 'ID.RA-03', description: 'VPC_VPN_2_TUNNELS_UP', status: 'passed', isActive: false },
        ],
      },
      {
        id: 4,
        name: 'ID.RA-04',
        status: 'failed',
        tests: [
          { id: 1, name: 'ID.RA-04', description: 'API_GW_ASSOCIATED_WITH_WAF', status: 'failed', isActive: true },
          { id: 2, name: 'ID.RA-04', description: 'API_GW_ENDPOINT_TYPE_CHECK', status: 'failed', isActive: false },
          {
            id: 3,
            name: 'ID.RA-04',
            description: 'INTERNET_GATEWAY_AUTHORIZED_VPC_ONLY',
            status: 'passed',
            isActive: true,
          },
          {
            id: 4,
            name: 'ID.RA-04',
            description: 'REDSHIFT_ENHANCED_VPC_ROUTING_ENABLED',
            status: 'passed',
            isActive: false,
          },
          {
            id: 5,
            name: 'ID.RA-04',
            description: 'VPC_PEERING_DNS_RESOLUTION_CHECK',
            status: 'no-data',
            isActive: true,
          },
          { id: 6, name: 'ID.RA-04', description: 'VPC_VPN_2_TUNNELS_UP', status: 'failed', isActive: false },
        ],
      },
      {
        id: 5,
        name: 'ID.RA-05',
        status: undefined,
        tests: [
          { id: 1, name: 'ID.RA-05', description: 'API_GW_ASSOCIATED_WITH_WAF', status: undefined, isActive: true },
          { id: 2, name: 'ID.RA-05', description: 'API_GW_ENDPOINT_TYPE_CHECK', status: undefined, isActive: false },
          {
            id: 3,
            name: 'ID.RA-05',
            description: 'INTERNET_GATEWAY_AUTHORIZED_VPC_ONLY',
            status: 'passed',
            isActive: true,
          },
          {
            id: 4,
            name: 'ID.RA-05',
            description: 'REDSHIFT_ENHANCED_VPC_ROUTING_ENABLED',
            status: 'passed',
            isActive: false,
          },
          {
            id: 5,
            name: 'ID.RA-05',
            description: 'VPC_PEERING_DNS_RESOLUTION_CHECK',
            status: 'no-data',
            isActive: true,
          },
          { id: 6, name: 'ID.RA-05', description: 'VPC_VPN_2_TUNNELS_UP', status: undefined, isActive: false },
        ],
      },
      {
        id: 6,
        name: 'ID.RA-06',
        status: 'passed',
        tests: [
          { id: 1, name: 'ID.RA-06', description: 'API_GW_ASSOCIATED_WITH_WAF', status: 'passed', isActive: true },
          { id: 2, name: 'ID.RA-06', description: 'API_GW_ENDPOINT_TYPE_CHECK', status: 'passed', isActive: false },
          {
            id: 3,
            name: 'ID.RA-06',
            description: 'INTERNET_GATEWAY_AUTHORIZED_VPC_ONLY',
            status: 'failed',
            isActive: true,
          },
          {
            id: 4,
            name: 'ID.RA-06',
            description: 'REDSHIFT_ENHANCED_VPC_ROUTING_ENABLED',
            status: 'failed',
            isActive: false,
          },
          {
            id: 5,
            name: 'ID.RA-06',
            description: 'VPC_PEERING_DNS_RESOLUTION_CHECK',
            status: 'no-data',
            isActive: true,
          },
          { id: 6, name: 'ID.RA-06', description: 'VPC_VPN_2_TUNNELS_UP', status: 'passed', isActive: false },
        ],
      },
      {
        id: 7,
        name: 'ID.RA-07',
        status: 'no-data',
        tests: [
          { id: 1, name: 'ID.RA-07', description: 'API_GW_ASSOCIATED_WITH_WAF', status: 'no-data', isActive: true },
          { id: 2, name: 'ID.RA-07', description: 'API_GW_ENDPOINT_TYPE_CHECK', status: 'passed', isActive: false },
          {
            id: 3,
            name: 'ID.RA-07',
            description: 'INTERNET_GATEWAY_AUTHORIZED_VPC_ONLY',
            status: 'passed',
            isActive: true,
          },
          {
            id: 4,
            name: 'ID.RA-07',
            description: 'REDSHIFT_ENHANCED_VPC_ROUTING_ENABLED',
            status: 'failed',
            isActive: false,
          },
          {
            id: 5,
            name: 'ID.RA-07',
            description: 'VPC_PEERING_DNS_RESOLUTION_CHECK',
            status: 'no-data',
            isActive: true,
          },
          { id: 6, name: 'ID.RA-07', description: 'VPC_VPN_2_TUNNELS_UP', status: 'no-data', isActive: false },
        ],
      },
      {
        id: 8,
        name: 'ID.RA-08',
        status: undefined,
        tests: [
          { id: 1, name: 'ID.RA-08', description: 'API_GW_ASSOCIATED_WITH_WAF', status: undefined, isActive: true },
          { id: 2, name: 'ID.RA-08', description: 'API_GW_ENDPOINT_TYPE_CHECK', status: undefined, isActive: false },
          {
            id: 3,
            name: 'ID.RA-08',
            description: 'INTERNET_GATEWAY_AUTHORIZED_VPC_ONLY',
            status: 'passed',
            isActive: true,
          },
          {
            id: 4,
            name: 'ID.RA-08',
            description: 'REDSHIFT_ENHANCED_VPC_ROUTING_ENABLED',
            status: 'passed',
            isActive: false,
          },
          {
            id: 5,
            name: 'ID.RA-08',
            description: 'VPC_PEERING_DNS_RESOLUTION_CHECK',
            status: 'no-data',
            isActive: true,
          },
          { id: 6, name: 'ID.RA-08', description: 'VPC_VPN_2_TUNNELS_UP', status: undefined, isActive: false },
        ],
      },
    ],
  },
];
