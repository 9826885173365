import {
  ApolloClient,
  ApolloLink,
  fallbackHttpConfig,
  fromError,
  InMemoryCache,
  Observable,
  parseAndCheckHttpResponse,
  selectHttpOptionsAndBody,
  serializeFetchParameter,
} from '@apollo/client';
import { Body } from '@apollo/client/link/http/selectHttpOptionsAndBody';
// import { RetryLink } from '@apollo/client/link/retry'; // See TODO below
import appStore from '../../AppStore';

const { protocol, host } = window.location;
const customUri = `${protocol}//${host}/api/graphql`;

interface CollectFilesResp {
  files: File[];
  variables: any;
  paths: string[];
}

/**
 * recursive function to find all the files in the variables
 */
function collectFiles(variables: any, path: string[] = ['variables']): CollectFilesResp {
  // initialize the response
  const result: CollectFilesResp = {
    files: [],
    variables: { ...variables },
    paths: [],
  };

  // iterate over the variable properties
  for (const prop in variables) {
    // get the value
    const value = variables[prop];

    // easy checks to skip
    if (!value || typeof value !== 'object' || value.getTime) {
      continue;
    }

    // easy check to process
    if (value instanceof File) {
      // add the file
      result.files.push(value);
      // add the file path
      result.paths.push([...path, prop].join('.'));
      // remove the value
      result.variables[prop] = null;
      continue;
    }

    // handle arrays
    if (Array.isArray(value)) {
      // handle array of files
      if (value.length && value[0] instanceof File) {
        // push the files into the array
        result.files.push(...value);
        // create a path for each file
        result.paths.push(...value.map((f, i) => [...path, prop, i].join('.')));
        // null the value
        result.variables[prop] = [null];
      }

      continue;
    }

    // check child objects for files
    const subResult = collectFiles(value, [...path, prop]);
    if (subResult.files.length) {
      // add the files
      result.files.push(...subResult.files);
      // add the paths
      result.paths.push(...subResult.paths);
      // update the variables
      result.variables[prop] = subResult.variables;
    }
  }

  return result;
}

/**
 * Creates a FormData object if there are files
 */
function extractFiles(body: Body): FormData | undefined {
  // get files
  const collection = collectFiles(body.variables);
  // leave if there are none
  if (!collection.files.length) {
    return;
  }

  // create the formdata
  const result = new FormData();
  // append the GQL operations
  result.append(
    'operations',
    serializeFetchParameter(
      {
        ...body,
        variables: collection.variables,
      },
      'Payload',
    ),
  );

  // append the file map
  result.append(
    'map',
    JSON.stringify(
      collection.paths.reduce((a, b, i) => {
        a[`${i + 1}`] = [b];
        return a;
      }, {} as any),
    ),
  );

  // add each file
  collection.files.forEach((f, i) => {
    'name' in f ? result.append(`${i + 1}`, f, f.name) : result.append(`${i + 1}`, f);
  });

  return result;
}

/**
 * Create the terminating link
 */
const link = new ApolloLink(operation => {
  // get the client id and the access token from the appStore
  const { clientId, access } = appStore;

  // get the GQL context
  const context = operation.getContext();

  // add the client id and authorization to the headers
  const headers: any = {
    'client-id': clientId,
    authorization: access ? `Bearer ${access}` : '',
  };

  // if clientAwareness is set, add these headers
  if (context.clientAwareness) {
    const { name, version } = context.clientAwareness;
    if (name) {
      headers['apollographql-client-name'] = name;
    }

    if (version) {
      headers['apollographql-client-version'] = version;
    }
  }

  // create the context configuration needed for parsing
  const contextConfig = {
    http: context.http,
    options: context.fetchOptions,
    credentials: context.credentials,
    headers: headers,
  };

  // parse the operation to options and body
  const { options, body } = selectHttpOptionsAndBody(operation, fallbackHttpConfig, contextConfig);

  try {
    // serialize the body
    options.body = serializeFetchParameter(body, 'Payload');
  } catch (err) {
    return fromError(err);
  }

  // check for files
  const formData = extractFiles(body);
  if (formData) {
    // set the form data to the body
    options.body = formData;
    // add the preflight header for graphql-upload on the backend
    (options.headers as any)['Apollo-Require-Preflight'] = 'true';
    // must remove the content type from apollo so fetch can set it properly
    delete (options.headers as any)['content-type'];
  }

  // return the observable object
  return new Observable(observer => {
    // fetch from the backend
    fetch(customUri, options)
      .then(response => {
        // handle the response
        operation.setContext({ response });
        return response;
      })
      .then(parseAndCheckHttpResponse(operation))
      .then(result => {
        // pass the result
        observer.next(result);
        // complete the observation
        observer.complete();
        return result;
      })
      .catch(err => {
        // ignore abort
        if (err.name === 'AbortError') {
          return;
        }

        // send the error to next
        if (err.result && err.result.errors && err.result.data) {
          observer.next(err.result);
        }

        // handle the error
        observer.error(err);
      });
  });
});

const singletons = [
  'Company',
  'ScoredFramework',
  'ScoredFrameworkGroup',
  'ScoredFrameworkItem',
  'TacticScore',
  'RoiResult',
  'RoiRiskReduction',
  'RoiPerformanceToROI',
  'RoiCostBenefit',
  'RoiRiskReductionSeries',
  'ThreatPreparednessGroup',
  'ThreatPreparednessStage',
].reduce((a, b) => ({ ...a, [b]: { keyFields: [] } }), {});

const cache = new InMemoryCache({
  typePolicies: {
    JobAction: {
      keyFields: ['id', 'sid'],
    },
    UserPreference: {
      keyFields: ['userId', 'name'],
    },
    SettingData: {
      keyFields: ['name'],
    },
    Investment: {
      keyFields: ['name'],
    },
    JobSummary: {
      keyFields: ['name', 'evaluationId'],
    },
    ...singletons,
  },
});

// TODO: see if this can be used only for queries
// link: authLink.concat(retryLink).concat(httpLink)
/*
const retryLink = new RetryLink({
  delay: {
    initial: 118,
    max: 30000,
    jitter: true,
  },
  attempts: {
    max: 8,
    retryIf: error => !!error,
  },
});
*/

const apolloClient = new ApolloClient({
  link,
  cache,
});

export default apolloClient;
