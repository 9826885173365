import { useCallback } from 'react';
import { TextField as Search } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { debounce } from '@sightgain/core';

const useStyles = makeStyles(() => ({
  searchBar: {
    width: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.11)',
    borderRadius: 3,
    fontSize: 14,
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      border: 0,
    },
    '& > div': {
      '&:before': {
        border: 0,
      },
      '&:after': {
        border: 0,
      },
      '& input': {
        padding: '11px 18px',
        fontFamily: 'Lexend !important',
      },
    },
  },
  inputPlaceholder: {
    '&::-webkit-input-placeholder': {
      color: '#ffffff',
      opacity: 1,
    },
  },
}));

interface SearchBarProps {
  placeholder: string;
  onChange?: (v: string) => unknown;
  timeout?: number;
  defaultValue?: string;
}

export default function SearchBar({ placeholder = 'Search', onChange, timeout = 100, defaultValue }: SearchBarProps) {
  const classes = useStyles();

  const handleChangeDebounce = useCallback(
    (e: string) => debounce((v: string) => onChange?.(v), timeout)(e),
    [onChange],
  );

  return (
    <Search
      variant="standard"
      className={classes.searchBar}
      type="search"
      placeholder={placeholder}
      inputProps={{ className: classes.inputPlaceholder, 'data-testid': 'search-bar' }}
      onChange={e => handleChangeDebounce(e.target.value)}
      defaultValue={defaultValue}
    />
  );
}
