import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Grid, Link, TextField } from '@mui/material';
import appStore from '../../../../../../AppStore';
import { assessmentsService } from '../../../../../services';
import { useAssessmentContext } from '../../../components/AssessmentContext';
import { Objective } from '../interface';
import Note from './Note';

interface NotesProps {
  objective: Objective;
  isReadonly?: boolean;
}

export default function Notes({ objective, isReadonly = false }: NotesProps) {
  const [note, setNote] = useState('');
  const { assessment } = useAssessmentContext();

  const handleOnClick = async () => {
    if (!note.trim()) {
      return;
    }

    appStore.beginLoading();
    try {
      await assessmentsService.addNoteArtifact(assessment.id, objective.id, note);
      objective.notes.unshift({ id: '', note, createdAt: new Date() });
      setNote('');
    } catch (err) {
      appStore.error(err);
    }
    appStore.endLoading();
  };

  return (
    <Grid container direction="column">
      <Grid item container justifyContent="end">
        <Button
          disabled={!note || isReadonly}
          component={Link}
          color="primary"
          startIcon={<FontAwesomeIcon icon={['fal', 'save']} />}
          onClick={handleOnClick}
        >
          Save Note
        </Button>
      </Grid>
      {!isReadonly && (
        <Grid item marginTop={3} marginBottom={1}>
          <TextField label="Add a note" multiline fullWidth value={note} onChange={e => setNote(e.target.value)} />
        </Grid>
      )}
      <Grid item container direction="column" gap={1} padding={1} flexWrap="nowrap">
        {objective.notes.map(n => (
          <Note key={`assessor-note-${n.id}`} note={n} />
        ))}
      </Grid>
    </Grid>
  );
}
