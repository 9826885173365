import { useMemo, useState } from 'react';
import { makeStyles } from '@mui/styles';
import appStore from '../../../../../../AppStore';
import Modal from '../../../../../reusables/Modal';
import { jobsService, settingsService } from '../../../../../services';
import EvaluationWindow from '../../../../Curriculum/Evaluations/Manager/EvaluationWindow';
import { useFilterContext } from '../../../../../reusables/Filter';
import { FilterSource } from '../../../../../reusables/Filter/interfaces';
import TileDetailControls from './TileDetailControls';
import TileEvaluation from './TileEvaluation';

const useStyles = makeStyles(() => ({
  cards: {
    marginTop: 24,
    gap: 12,
    height: 'calc(100vh - 420px)',
    overflowY: 'scroll',
  },
}));

export default function Evaluations({ data, ignoredEvaluations }: { data: any; ignoredEvaluations: string[] }) {
  const classes = useStyles();
  const [runEvaluationId, setRunEvaluationId] = useState<string>();
  const { filters, sortById } = useFilterContext();

  const handleIgnore = async (vendorId: string) => {
    const newIgnoredEvaluations = [...ignoredEvaluations, vendorId];
    try {
      // add them to the list of ignored evaluations
      await settingsService.save({ name: 'ignoredEvaluations', value: JSON.stringify(newIgnoredEvaluations) });
      // exclude the related jobs from scoring
      await jobsService.excludeJobs(vendorId, true);
    } catch {
      appStore.error('Error ignoring evaluation');
    }
  };

  const handleInclude = async (vendorId: string) => {
    const newIgnoredEvaluations = ignoredEvaluations.filter(x => x !== vendorId);
    try {
      // remove them from the list of ignored evaluations
      await settingsService.save({ name: 'ignoredEvaluations', value: JSON.stringify(newIgnoredEvaluations) });
      // include the jobs in scoring
      await jobsService.excludeJobs(vendorId, false);
    } catch {
      appStore.error('Error including evaluation');
    }
  };

  const sources: FilterSource[] = [
    { name: 'vendorId', title: 'Evaluation ID', filterType: 'string' },
    { name: 'name', title: 'Evaluation Name', filterType: 'string' },
    { name: 'runCount', title: '# Runs', filterType: 'numeric' },
  ];

  const filteredData = useMemo(() => data.filter((x: any) => !filters.some((y: any) => !y.filter(x))), [data, filters]);
  const ignoredData = useMemo(
    () => filteredData.filter((x: any) => ignoredEvaluations.includes(x.vendorId)),
    [ignoredEvaluations, filteredData],
  );
  const notIgnoredData = useMemo(
    () => filteredData.filter((x: any) => !ignoredEvaluations.includes(x.vendorId)),
    [ignoredEvaluations, filteredData],
  );

  const sortFn = sortById
    ? (a: any, b: any) => a?.vendorId?.localeCompare(b?.vendorId, 'en-US', { numeric: true })
    : () => 0;

  return (
    <>
      <TileDetailControls sources={sources} itemCount={filteredData.length} hideLatest />
      <div className={classes.cards}>
        {notIgnoredData.sort(sortFn).map((item: any) => {
          return (
            <TileEvaluation
              evaluation={item}
              key={item.id}
              onIgnore={handleIgnore}
              onInclude={handleInclude}
              onRun={setRunEvaluationId}
            />
          );
        })}
        {ignoredData.sort(sortFn).map((item: any) => {
          return (
            <TileEvaluation
              evaluation={item}
              key={item.id}
              onIgnore={handleIgnore}
              onInclude={handleInclude}
              onRun={setRunEvaluationId}
              ignored
            />
          );
        })}
      </div>
      {!!runEvaluationId && (
        <Modal
          title="Run Evaluation"
          open
          key={`run-evaluation-${runEvaluationId}`}
          onClose={() => setRunEvaluationId(undefined)}
          fullWidth
        >
          <EvaluationWindow activeId={runEvaluationId} />
        </Modal>
      )}
    </>
  );
}
