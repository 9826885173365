import { FormEvent, memo } from 'react';
import { Button, Collapse, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useScoreClass } from '../../../../../effects';
import { FrameworkItem } from '../../../../services/interfaces';
import { DataRow } from './utilities';

interface RecentlyRunDetailsProps {
  row: DataRow;
  expanded?: boolean;
  handleOpen: (e: FormEvent, id: string, controls: FrameworkItem[]) => unknown;
}

const useStyles = makeStyles(theme => ({
  row: {
    backgroundColor: theme.palette.grey1.main,
    padding: theme.spacing(2),
  },
  detailBox: {
    background: theme.palette.darkBlue3.main,
    padding: '30px 20px',
    // flex: 1,
  },
  controlBox: {
    background: theme.palette.darkBlue3.main,
    padding: '12px 12px',
    textAlign: 'center',
  },
}));

function RecentlyRunDetails({ expanded, row, handleOpen }: RecentlyRunDetailsProps) {
  const classes = useStyles();
  const scoreClassName = useScoreClass();

  return (
    <Collapse in={expanded}>
      <Grid container gap={2} className={classes.row}>
        <Grid item className={classes.detailBox}>
          <Typography display="block" variant="textStyle3">
            Composite Score:
          </Typography>
          <Typography display="block" textAlign="center" variant="textStyle10" className={scoreClassName(row.score)}>
            {row.score !== undefined ? row.score : '--'}
          </Typography>
        </Grid>
        <Grid item className={classes.detailBox}>
          <Typography display="block" variant="textStyle3">
            Instructor Score:
          </Typography>
          <Typography
            display="block"
            textAlign="center"
            variant="textStyle10"
            className={scoreClassName(row.instructorScore)}
          >
            {row.instructorScore !== undefined ? `${row.instructorScore}` : '--'}
          </Typography>
        </Grid>
        <Grid item className={classes.controlBox}>
          <Typography variant="textStyle3">Capability Controls:</Typography>
          <Typography>
            {row.frameworkControls?.length || 'All'} control{row.frameworkControls?.length === 1 ? '' : 's'} will be
            scored
          </Typography>
          <Button
            size="small"
            onClick={(e: FormEvent) => handleOpen(e, row.id, row?.frameworkControls ?? [])}
            variant="contained"
          >
            Customize
          </Button>
        </Grid>
      </Grid>
    </Collapse>
  );
}

export default memo(RecentlyRunDetails);
