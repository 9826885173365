import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { AssessmentView, AssessmentViewProps } from './interfaces';
import { useAssessmentContext } from '../AssessmentContext';
import assessmentsService from '../../../../services/AssessmentsService';

export default function Archive({ view, onExit }: AssessmentViewProps) {
  const { editAssessment: assessment } = useAssessmentContext();

  const handleArchive = async () => {
    const archivedAssessment: typeof assessment = { ...assessment, isArchived: true };
    await assessmentsService.update({
      id: archivedAssessment.id,
      name: archivedAssessment.name,
      isArchived: archivedAssessment.isArchived,
    });
    onExit();
  };

  return (
    <Dialog
      open={view === AssessmentView.ARCHIVE}
      onClose={() => onExit()}
      aria-labelledby="archive-dialog-title"
      aria-describedby="archive-dialog-description"
    >
      <DialogTitle sx={{ fontSize: '14px' }} id="archive-dialog-title">
        Are you sure you want to archive this assessment?
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="archive-dialog-description">{assessment.name}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onExit()} color="primary">
          Cancel
        </Button>
        <Button onClick={handleArchive} color="primary">
          Archive
        </Button>
      </DialogActions>
    </Dialog>
  );
}
