import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Dialog, DialogActions, DialogContent, Grid, Tab, Tabs, Typography } from '@mui/material';
import { useModal } from '../../../../reusables/BetterModal';
import Evidences from './components/Evidences';
import Notes from './components/Notes';
import ObjectiveQuestions from './components/ObjectiveQuestions';
import { Objective } from './interface';

interface ManualAssessmentFormProps {
  objective: Objective;
  isReadonly?: boolean;
}

export default function ManualAssessmentForm({ objective, isReadonly }: ManualAssessmentFormProps) {
  const { closeModal } = useModal('itemManualAssessmentModal');
  const [activeTab, setActiveTab] = useState(0);
  const [isDirty, setIsDirty] = useState(false);
  const [showCloseDialog, setShowCloseDialog] = useState(false);

  const handleModalClose = () => {
    if (isDirty) {
      setShowCloseDialog(true);
    } else {
      closeModal();
    }
  };

  const confirmClose = () => {
    setShowCloseDialog(false);
    closeModal();
  };

  const cancelClose = () => {
    setShowCloseDialog(false);
  };

  const displayTab = (tab: number) => (activeTab === tab ? 'flex' : 'none');

  return (
    <Box
      bgcolor="darkBlue1.main"
      padding={4}
      border="solid 1px #5d6e80"
      height="80vh"
      display="flex"
      flexDirection="column"
    >
      <Grid container spacing={2} direction="column">
        <Grid item>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography component="h3" style={{ fontWeight: 600, fontSize: '20px', lineHeight: '25px' }}>
              {objective.identifier} {objective.name}
            </Typography>
            <Button onClick={handleModalClose}>
              <FontAwesomeIcon icon={['far', 'times-circle']} size="lg" />
            </Button>
          </Box>
        </Grid>
        <Grid item>
          <Typography style={{ fontWeight: 400, fontSize: '16px', lineHeight: '20px', color: '#8797AB' }}>
            {objective.description}
          </Typography>
        </Grid>
      </Grid>

      <Box sx={{ borderBottom: 1, borderColor: 'divider' }} marginTop={2}>
        <Tabs value={activeTab} onChange={(e, t) => setActiveTab(t)}>
          <Tab label="Questions" />
          <Tab label="Notes" />
          <Tab label="Evidences" />
        </Tabs>
      </Box>

      <div style={{ display: displayTab(0), flexGrow: 1, flexDirection: 'column', overflowY: 'auto', marginTop: 12 }}>
        <ObjectiveQuestions objective={objective} isReadonly={isReadonly} isDirty={isDirty} setIsDirty={setIsDirty} />
      </div>
      <div style={{ display: displayTab(1), flexGrow: 1, flexDirection: 'column', overflowY: 'auto', marginTop: 12 }}>
        <Notes objective={objective} isReadonly={isReadonly} />
      </div>
      <div style={{ display: displayTab(2), flexGrow: 1, flexDirection: 'column', overflowY: 'auto', marginTop: 12 }}>
        <Evidences objective={objective} isReadonly={isReadonly} />
      </div>

      <Dialog open={showCloseDialog}>
        <DialogContent>
          <p>You have unsaved changes. Are you sure you want to leave?</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={confirmClose}>Yes, leave</Button>
          <Button onClick={cancelClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
