import { Box } from '@mui/material';
import { ConfigValidation, ConfigValidationItem } from '../../interfaces';
import { makeStyles } from '@mui/styles';
import Heading from './Heading';

const useStyles = makeStyles(() => ({
  table: {
    display: 'grid',
    gridTemplateColumns: 'repeat(6, 1fr)',
  },
}));

interface TableProps {
  data: ConfigValidation[];
  onClick: (control: ConfigValidationItem, columnName?: string) => void;
}

export default function Table({ data, onClick }: TableProps) {
  const classes = useStyles();

  return (
    <Box className={classes.table}>
      {data.map(config => (
        <Heading key={config.id} config={config} onClick={onClick} />
      ))}
    </Box>
  );
}
