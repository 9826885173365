import { Typography } from '@mui/material';
import { TemperatureComponent, TemperatureSlider } from '../../../reusables/TemperatureSlider';
import SettingWrapper from './SettingWrapper';

export default function ThreatPreparednessThreshold() {
  return (
    <SettingWrapper
      title="Threat Preparedness Threshold Settings"
      setting="threatPreparednessThresholds"
      defaultValue={[20, 60]}
      json
    >
      {(threatPreparednessThresholds, onUpdate) => (
        <>
          <Typography variant="body1">Threat Preparedness Levels</Typography>
          <TemperatureSlider
            valueLabelDisplay="on"
            component={TemperatureComponent}
            value={threatPreparednessThresholds}
            onChange={(_: any, value: number[]) => onUpdate(value)}
            reversed
          />
        </>
      )}
    </SettingWrapper>
  );
}
