import { ReactNode } from 'react';
import { Button } from '@mui/material';

interface FilterControlsProps {
  children: ReactNode;
  onCancel: () => void;
  onApply: () => void;
}

export default function FilterControls({ children, onCancel, onApply }: FilterControlsProps) {
  return (
    <div>
      {children}
      <div style={{ display: 'flex', justifyContent: 'space-between', gap: 18, marginTop: 12 }}>
        <Button variant="contained" color="primary" onClick={onApply}>
          Apply Filter
        </Button>
        <Button variant="outlined" color="secondary" onClick={onCancel}>
          Cancel
        </Button>
      </div>
    </div>
  );
}
