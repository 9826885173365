import { Box, Chip, Button, Grid, Link, Typography, IconButton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Objective } from '../interface';
import { useModal } from '../../../../../reusables/BetterModal';
import { makeStyles } from '@mui/styles';
import CsvDownload from '../../../../../reusables/CsvDownload';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  outline: {
    border: `2px solid ${theme.palette.orange1.main}`,
  },
  default: {
    border: `2px solid ${theme.palette.darkBlue4.main}`,
  },
}));

type ObjectiveBoxProps = {
  objective: Objective;
  onObjective: (objective: Objective) => void;
  onCSV: (objective: Objective) => any;
};

export default function ObjectiveBox({ objective, onObjective, onCSV }: ObjectiveBoxProps) {
  const classes = useStyles();
  const { openModal: openItemThresholdModal } = useModal<Objective>('itemThresholdModal');
  const { openModal: openItemManualAssessmentModal } = useModal<Objective>('itemManualAssessmentModal');

  const hasAutomatedScore = objective.scores.subItemAutomatedMaturityScore?.score >= 0;
  const hasInferredScore = !!objective.scores.subItemInferredMaturityScore?.inferredBy;
  const hasManualScore = !!objective.questions.length;

  const isCompleted = objective.questions.length === objective.answers.length;

  return (
    <Box
      component="div"
      padding={2}
      bgcolor="darkBlue3.main"
      height="100%"
      className={clsx(classes.outline, isCompleted && classes.default)}
    >
      <Grid container gap={2} direction="column" justifyContent="space-between" style={{ height: '100%' }}>
        <Grid item>
          <Grid container spacing={2} direction="column">
            <Grid item>
              <Typography variant="textStyle3">
                {objective.identifier} {objective.name}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="textStyle1" color="grey2.main">
                {objective.description}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container spacing={2} direction="column">
            {hasAutomatedScore && (
              <Grid item>
                <Box component="div" bgcolor="darkBlue4.main" padding={2}>
                  <Grid container direction="row" spacing={1} justifyContent="space-between" alignItems="center">
                    <Grid item>
                      <Grid container direction="column" spacing={2}>
                        <Grid item>
                          <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                            <Typography variant="textStyle3">Automated Result</Typography>
                            <CsvDownload
                              filename={`${objective.identifier}_automated_results.csv`}
                              datas={() => onCSV(objective)}
                            />
                          </div>
                        </Grid>
                        <Grid item>
                          {
                            <Typography variant="textStyle10">
                              {objective.scores.valueFor(objective.scores.subItemAutomatedMaturityScore, 0)}%
                            </Typography>
                          }
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container direction="column" spacing={2} justifyContent="center" alignItems="end">
                        <Grid item>
                          <Chip
                            label={objective.scores.labelFor(
                              objective.scores.subItemAutomatedMaturityScore,
                              'Not Achieved',
                            )}
                            variant="filled"
                            color={objective.scores.subItemAutomatedMaturityScore?.passed ? 'green2' : 'orange1'}
                          />
                        </Grid>
                        <Grid item>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Typography variant="textStyle1" color="grey2.main">
                              Min. {objective.scores.subItemAutomatedMaturityScore.threshold || 0}% required
                            </Typography>
                            <IconButton onClick={() => openItemThresholdModal(objective)} size="small">
                              <FontAwesomeIcon icon={['fal', 'pencil-alt']} color="#2ecff5" />
                            </IconButton>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            )}
            {hasInferredScore && (
              <Grid item>
                <Box component="div" bgcolor="darkBlue4.main" padding={2}>
                  <Grid container direction="row" spacing={1} justifyContent="space-between" alignItems="center">
                    <Grid item>
                      <Grid container direction="column" spacing={2}>
                        <Grid item>
                          <Typography variant="textStyle3">
                            Inferred Result from: {objective.scores.subItemInferredMaturityScore?.inferredBy}
                          </Typography>
                        </Grid>
                        <Grid item>
                          {
                            <Typography variant="textStyle10">
                              {objective.scores.valueFor(objective.scores.subItemInferredMaturityScore, 0)}%
                            </Typography>
                          }
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container direction="column" spacing={2} justifyContent="center" alignItems="end">
                        <Grid item>
                          <Chip
                            label={objective.scores.labelFor(
                              objective.scores.subItemInferredMaturityScore,
                              'Not Achieved',
                            )}
                            variant="filled"
                            color={objective.scores.subItemInferredMaturityScore?.passed ? 'green2' : 'orange1'}
                          />
                        </Grid>
                        <Grid item>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Typography variant="textStyle1" color="grey2.main">
                              Min. {objective.scores.subItemInferredMaturityScore?.threshold || 0}% required
                            </Typography>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            )}
            {hasManualScore && (
              <Grid item>
                <Box component="div" bgcolor="darkBlue4.main" padding={2}>
                  <Grid container direction="row" spacing={1} justifyContent="space-between" alignItems="center">
                    <Grid item>
                      <Grid container direction="column" spacing={2}>
                        <Grid item>
                          <Typography variant="textStyle3">Manual Assessment</Typography>
                        </Grid>
                        <Grid item>
                          {
                            <Typography variant="textStyle10">
                              {objective.scores.valueFor(objective.scores.subItemSelfScoreMaturityScore, 0)}%
                            </Typography>
                          }
                        </Grid>
                        <Grid item>
                          <Button
                            component={Link}
                            onClick={() => {
                              openItemManualAssessmentModal(objective);
                              onObjective(objective);
                            }}
                            color="primary"
                            endIcon={<FontAwesomeIcon icon={['fal', 'long-arrow-right']} />}
                          >
                            Details
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container direction="column" spacing={2} justifyContent="center" alignItems="end">
                        <Grid item>
                          <Chip
                            label={objective.scores.labelFor(
                              objective.scores.subItemSelfScoreMaturityScore,
                              'Not Achieved',
                            )}
                            variant="filled"
                            color={objective.scores.subItemSelfScoreMaturityScore?.passed ? 'green2' : 'orange1'}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
