import { useCallback, useEffect, useState } from 'react';
import { FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material';
import { FilterTypeProps } from '../interfaces';

export default function NumericFilter({ onChange, source }: FilterTypeProps) {
  const { name, title } = source;
  const [value, setValue] = useState<string>('');
  const [op, setOp] = useState<string>('equals');

  const filterFunction = useCallback(
    item => {
      if (!item || item[name] === undefined) {
        return false;
      }
      if (op === 'equals') {
        return +value === item[name];
      } else if (op === 'less') {
        return item[name] < +value;
      } else {
        return item[name] > +value;
      }
    },
    [value, op, name],
  );

  useEffect(() => {
    onChange(filterFunction, `${op} ${value}`, name, title);
  }, [filterFunction, onChange, value, op, name, title]);

  return (
    <div>
      <RadioGroup defaultValue="equals" name="numeric-filter" value={op} onChange={v => setOp(v.target.value)}>
        <FormControlLabel value="equals" control={<Radio />} label="equals" />
        <FormControlLabel value="less" control={<Radio />} label="less than" />
        <FormControlLabel value="more" control={<Radio />} label="more than" />
      </RadioGroup>
      <TextField
        fullWidth
        variant="standard"
        InputProps={{ style: { borderRadius: 3, border: 'solid 1px #61788e' } }}
        value={value}
        onChange={e => setValue(e.target.value)}
      />
    </div>
  );
}
