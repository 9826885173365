import { Grid, Typography } from '@mui/material';
import { prettyDate } from '@sightgain/core/strings';
import { AssessorNote } from '../../../../../services/interfaces';

interface NoteProps {
  note: AssessorNote;
}

export default function Note({ note }: NoteProps) {
  return (
    <Grid item sx={{ borderBottom: 1, borderColor: 'divider' }} padding={1}>
      <Typography fontWeight={600} fontSize={10}>
        {prettyDate(note.createdAt)}
      </Typography>
      <Typography paddingLeft={2}>{note.note}</Typography>
    </Grid>
  );
}
